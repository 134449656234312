import _ from 'lodash'
import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import browserHistory from 'shared/browserHistory'
import { loadCampaignId } from 'shared/campaign'
import { saveError } from 'shared/sentry'
import cognito from 'shared/cognito'
import correlation from 'shared/correlation'
import resolvers, { defaults } from './resolvers'
import { AUTHENTICATION_QUERY } from 'authentication/queries'
import authService from 'authentication/service'
import Observable from 'zen-observable'
import Branding from 'shared/branding'
import { GLOBAL_PATHS, ACCOUNT_PATHS, ENROLLMENT_PATHS } from 'constants/paths'
import { COGNITO_SOURCE } from 'constants/values'

const UNAUTHENTICATED_MSG = 'cng:unauthenticated'

const saveErrorToLog = (graphQLErrors, networkError) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      return saveError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }

  if (networkError) {
    saveError(`[Network error]: ${JSON.stringify(networkError)}`)
  }
}

const makeErrorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (
      networkError &&
      (networkError.statusCode === 504 || networkError.statusCode === 502)
    ) {
      window.setTimeout(() => {
        window.location.href = GLOBAL_PATHS.ROOT
      }, 15000)
    } else if (
      (networkError && networkError.statusCode === 403) ||
      (graphQLErrors &&
        graphQLErrors.find(({ message }) => message === UNAUTHENTICATED_MSG))
    ) {
      if (
        !browserHistory.location ||
        browserHistory.location.pathname !== GLOBAL_PATHS.LOGIN
      ) {
        browserHistory.push(GLOBAL_PATHS.LOGIN, {
          timeout: !!cognito.currentUser,
        })
      }
    } else if (graphQLErrors || networkError) {
      if (window.location.pathname.startsWith(ACCOUNT_PATHS.ACCOUNT)) {
        browserHistory.push(ACCOUNT_PATHS.ERROR)
      } else {
        authService.getAuthAttributes().then(currentAuth => {
          saveErrorToLog(graphQLErrors, networkError)
          if (
            _.get(currentAuth, 'customSource') === COGNITO_SOURCE.ENROLLMENT
          ) {
            browserHistory.push(ENROLLMENT_PATHS.ERROR)
          } else {
            browserHistory.push(GLOBAL_PATHS.ERROR)
          }
        })
      }
    }
  })

const makeAuthLink = () =>
  new ApolloLink((op, fwd) => {
    const authorization = cognito.idToken || null

    if (authorization === null) {
      return new Observable(observer => {
        observer.next({ data: {}, errors: [{ message: UNAUTHENTICATED_MSG }] })
        observer.complete()
      })
    }

    const headers = { authorization }

    const campaignId = loadCampaignId()
    if (campaignId) {
      headers['X-cng-campaign-id'] = campaignId
    }

    op.setContext({
      headers,
    })

    return fwd(op)
  })

const makeCorrelationLink = () =>
  new ApolloLink((op, fwd) => {
    const context = op.getContext() || {}
    context.headers = context.headers || {}
    context.headers['X-session-id'] = correlation.getSessionId()
    context.headers['X-correlation-id'] = correlation.getCorrelationId()

    op.setContext(context)

    return fwd(op)
  })

const createClient = () => {
  const cache = new InMemoryCache()

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    headers: {
      'X-brand-key': Branding.current.key,
    },
  })

  const authLink = makeAuthLink()
  const correlationLink = makeCorrelationLink()
  const errorLink = makeErrorLink()

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, correlationLink, httpLink]),
    cache,
    resolvers,
  })

  cache.writeQuery({
    query: AUTHENTICATION_QUERY,
    data: { ...defaults },
  })

  client.onResetStore(() => {
    cache.writeQuery({
      query: AUTHENTICATION_QUERY,
      data: { ...defaults },
    })
  })

  return client
}

export default createClient()
