import gql from 'graphql-tag'

const RememberDeviceMutationGql = gql`
  mutation rememberDevice($applicationId: ID, $blackBox: String!, $mfaId: ID) {
    rememberDevice(
      applicationId: $applicationId
      blackBox: $blackBox
      mfaId: $mfaId
    ) {
      success
    }
  }
`

export default {
  RememberDeviceMutationGql,
}
export { RememberDeviceMutationGql }
