import React, { useState } from 'react'
import {
  Navbar,
  Button,
  NavbarBrand,
  Collapse,
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Badge,
} from 'reactstrap'
import { LoginLogoutButton } from 'authentication/components'
import BrandLogo from '../BrandLogo'
import Branding from 'shared/branding'
import { BRANDS } from 'constants/values'
import PopupMenu from './PopupMenu'
import ChevronNavLink, { Directions } from './ChevronNavLink'
import icon_secure_aca from 'shared/images/icon_secure_aca.png'
import icon_secure_cng from 'shared/images/icon_secure_cng.png'
import icon_secure_xact from 'shared/images/icon_secure_xact.png'
import RemoteSessionPopup, { RemoteSessionNavLink } from './RemoteSessionPopUp'
import { useFlags } from 'launchdarkly-react-client-sdk'

const secureIcons = {
  [BRANDS.ACA]: icon_secure_aca,
  [BRANDS.CNG]: icon_secure_cng,
  [BRANDS.CCB]: icon_secure_xact,
}

const Header = ({
  isHidden,
  showCollapse,
  modalOpen,
  setModalOpen,
  showLogInText,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPopOverOpen, setIsPopOverOpen] = useState(false)
  const [displayRemoteSession, setDisplayRemoteSession] = useState(false)
  const [showMobileRemoteSession, setShowMobileRemoteSession] = useState(false)

  const { remoteSessionOption } = useFlags()

  const toggle = () => {
    setIsOpen(window.innerWidth > 700 ? false : !isOpen)
    setIsPopOverOpen(window.innerWidth < 700 ? false : !isPopOverOpen)
  }

  const remoteSessionToggle = () => {
    setDisplayRemoteSession(
      window.innerWidth < 700 ? false : !displayRemoteSession
    )
    setShowMobileRemoteSession(
      window.innerWidth > 700 ? false : !showMobileRemoteSession
    )
    if (isPopOverOpen && !(window.innerWidth < 700)) {
      toggle()
    }
  }

  const toggleAllPopups = () => {
    if (displayRemoteSession) {
      remoteSessionToggle()
    } else if (showMobileRemoteSession) {
      remoteSessionToggle()
      toggle()
    } else {
      toggle()
    }
  }

  const backAction = () => {
    setDisplayRemoteSession(false)
    setShowMobileRemoteSession(false)
    if (!isOpen) {
      toggle()
    }
  }

  const branding = Branding.current

  const navLinkComponent = (
    brandLink,
    badgeText,
    linkTest,
    text,
    badgeClass
  ) => (
    <NavItem className="d-flex align-items-center">
      <NavLink
        target="_blank"
        href={branding[brandLink]}
        data-test={badgeText}
        className="text-nowrap pr-0"
      >
        <Badge color={branding.headerColor} pill className="circle">
          <i className={badgeClass} alt="store" data-test="images.store-icon" />
        </Badge>
      </NavLink>
      <NavLink
        target="_blank"
        href={branding[brandLink]}
        data-test={linkTest}
        className="text-nowrap"
      >
        {text}
      </NavLink>
    </NavItem>
  )

  const isP360 = branding.key === BRANDS.P360

  const secureIcon = isP360 ? '' : secureIcons[branding.key]

  const navLinkPhone = (
    <NavItem className="d-flex align-items-center justify-content-between">
      <div id="mobile div" className="d-flex flex-row">
        <NavLink
          target="_blank"
          href={`tel:${branding.phoneNumber}`}
          data-test="text.call-company-phone"
          className="text-nowrap pr-0"
        >
          <Badge
            color={branding.headerColor}
            pill
            className="circle"
            style={{ padding: '.3rem', fontSize: '45%' }}
          >
            <i
              className="fas fa-mobile-alt fa-fw fa-2x"
              alt="phone"
              data-test="images.phone-icon"
            />
          </Badge>
        </NavLink>
        <NavLink
          target="_blank"
          href={`tel:${branding.phoneNumber}`}
          data-test="text.company-phone"
          className="text-nowrap"
        >
          {branding.phoneNumber}
        </NavLink>
      </div>
      {remoteSessionOption && (
        <ChevronNavLink
          direction={Directions.RIGHT}
          onClick={remoteSessionToggle}
        />
      )}
    </NavItem>
  )

  const hamburgerButton = (
    <Button
      id="toggler"
      data-test="toggler"
      color="secondary"
      className={showCollapse ? 'toggleOverride' : 'd-lg-none'}
      onClick={toggleAllPopups}
    >
      <i color="currentColor" className="fas fa-bars" />
    </Button>
  )

  return (
    <Navbar expand={false} light hidden={isHidden}>
      <NavbarBrand className="mr-0" href={branding.imagePath}>
        <BrandLogo />
      </NavbarBrand>
      {!showCollapse ? (
        <div className="d-flex">
          <div className="d-none d-sm-inline">{navLinkPhone}</div>
          <LoginLogoutButton
            className="mr-2 d-none d-sm-inline"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            showLogInText={showLogInText}
          />
          {branding.key === BRANDS.P360 && (
            <NavbarToggler
              className="d-lg-none"
              onClick={toggle}
              data-test="toggler"
            />
          )}
          {remoteSessionOption && (
            <RemoteSessionPopup
              backAction={backAction}
              displayRemoteSession={displayRemoteSession}
              showCollapse={showCollapse}
              togglePopUp={remoteSessionToggle}
              isP360={isP360}
            />
          )}
          <div></div>
        </div>
      ) : (
        <div className="d-flex flex-row align-items-center justify-content-end nav-action-container">
          <div className="secure-icon-container d-sm-none">
            <img src={secureIcon} className="secure-icon" alt="Secure Icon" />
            <p className="mb-0"> Fast & Secure</p>
          </div>
          <LoginLogoutButton
            className="mr-2 d-none d-sm-inline"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            showLogInText={showLogInText}
          />
          {hamburgerButton}

          <PopupMenu
            remoteSessionToggle={remoteSessionToggle}
            isOpen={isPopOverOpen}
            toggle={toggle}
            remoteSessionOption={remoteSessionOption}
          />

          {remoteSessionOption && (
            <RemoteSessionPopup
              backAction={backAction}
              displayRemoteSession={displayRemoteSession}
              showCollapse={showCollapse}
              togglePopUp={remoteSessionToggle}
            />
          )}
        </div>
      )}
      <Collapse
        className="order-3 d-lg-none"
        navbar
        isOpen={isOpen}
        data-test="collapse"
      >
        <Nav className="ml-auto" navbar>
          {!showMobileRemoteSession && (
            <React.Fragment key={branding.key}>
              {(branding.key === BRANDS.ACA || branding.key === BRANDS.CNG) && (
                <>
                  {navLinkComponent(
                    'findAStore',
                    'text.findAStoreBadge',
                    'text.findAStoreLink',
                    'Find a Store',
                    'fas fa-map-marker-alt fa-fw fa-2x'
                  )}
                </>
              )}
              {branding.key === BRANDS.CCB &&
                navLinkComponent(
                  'howItWorksLink',
                  'text.howItWorksBadge',
                  'text.howItWorksLink',
                  'How it works',
                  'fas fa-cog fa-fw fa-2x'
                )}
              {branding.key !== BRANDS.P360 &&
                navLinkComponent(
                  'getAnswersLink',
                  'text.getAnswersBadge',
                  'text.getAnswersLink',
                  'Get Answers',
                  'fas fa-question fa-fw fa-2x'
                )}
              {navLinkPhone}
            </React.Fragment>
          )}

          {showMobileRemoteSession && (
            <RemoteSessionNavLink
              backAction={backAction}
              displayRemoteSession={showMobileRemoteSession}
              showCollapse={showCollapse}
              togglePopUp={remoteSessionToggle}
              isP360={isP360}
            />
          )}

          <LoginLogoutButton
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            showLogInText={showLogInText}
          />
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Header
