import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Button } from 'reactstrap'
import { InputField, RememberDeviceCheckbox } from 'shared/components'
import { useFlags } from 'launchdarkly-react-client-sdk'

let timeout = null

const EmailVerificationForm = ({
  handleSubmit,
  isSubmitting,
  resendCode,
  isResendingCode,
  isMfa,
  willRememberDevice,
  toggleRememberDevice,
}) => {
  const {
    timeouts: {
      verification: { otp_email: disabledResendTimeout },
    },
  } = useFlags()

  const [isResendDisabled, setIsResendDisabled] = useState(true)

  const clearTimeout = () => {
    if (timeout) {
      window.clearTimeout(timeout)
      timeout = null
    }
  }

  const setTimeout = () => {
    timeout = window.setTimeout(() => {
      setIsResendDisabled(false)
    }, disabledResendTimeout * 1000)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setTimeout()
    return () => {
      clearTimeout()
    }
  }, [])

  const handleResendCode = async () => {
    setIsResendDisabled(true)
    await resendCode()
    setTimeout()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <InputField
            title=""
            hideLabel
            name="verificationCode"
            placeholder="Enter code"
            data-test={`fields.verificationCode`}
            maxLength={10}
            type='number'
            disabled={isSubmitting || isResendingCode}
          />
        </Col>
      </Row>

      {!isResendDisabled && (
        <Row>
          <Col>
            <p>
              Didn't receive a code?{' '}
              <span
                id="email-verification--button-resend"
                className="pseudolink"
                data-test="resend-code"
                onClick={handleResendCode}
              >
                Click here to resend.
              </span>
            </p>
          </Col>
        </Row>
      )}

      {isMfa && (
        <RememberDeviceCheckbox
          checked={willRememberDevice}
          onChange={toggleRememberDevice}
          disabled={isSubmitting}
        />
      )}

      <Row>
        <Col lg={12}>
          <Button
            block
            className="my-3"
            id="email-verification--sendCode"
            data-test="buttons.submit"
            type="submit"
            color="primary"
            disabled={isSubmitting || isResendingCode}
          >
            Verify Code
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

EmailVerificationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isResendingCode: PropTypes.bool,
  isMfa: PropTypes.bool,
  rememberDevice: PropTypes.bool,
  toggleRememberDevice: PropTypes.func,
}

export default EmailVerificationForm
