import React from 'react'
import { Alert } from 'reactstrap'

import PropTypes from 'prop-types'

const FormAlert = props => {
  const {
    children,
    color = 'danger',
    isValid = false,
    isSubmitted = true,
  } = props
  const message = children || (
    <p
      data-test="defAlertMsg"
      style={{
        marginBottom: 0,
      }}
    >
      Some important information is missing or incorrect. Please fill in all
      required fields.
    </p>
  )

  return !isValid && isSubmitted ? (
    <Alert data-test="errorAlert" color={color} className="text-center">
      {message}
    </Alert>
  ) : null
}

FormAlert.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  isValid: PropTypes.bool,
  isSubmitted: PropTypes.bool,
}

export default FormAlert
