import React from 'react'
import PropTypes from 'prop-types'
import { currency } from 'shared/utils'

const RefinanceBasicSummary = props => {
  const {
    refinanceLoanDetail,
    refinanceOptionId,
    refinanceValue,
    daysUntilDue,
  } = props

  return (
    <React.Fragment>
      <p className="mb-0">
        <strong>
          Amount due on {refinanceLoanDetail.loanDueDate}{' '}
          <span className="text-nowrap">({daysUntilDue} days)</span>
        </strong>
      </p>

      <div className="d-flex">
        <span>Fees due on Current Loan</span>
        <span className="ml-auto">
          <strong>{currency(refinanceLoanDetail.loanFeeAmount, 2)}</strong>
        </span>
      </div>

      {refinanceOptionId === 2 && (
        <React.Fragment>
          <div className="d-flex">
            <span>Additional amount paid back</span>
            <span className="ml-auto">
              <strong>+{currency(refinanceValue || 0, 2)}</strong>
            </span>
          </div>

          <hr />

          <div className="d-flex">
            <span>Total amount due</span>
            <span className="ml-auto">
              <strong>
                {currency(
                  refinanceLoanDetail.loanFeeAmount + (refinanceValue || 0),
                  2
                )}
              </strong>
            </span>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RefinanceBasicSummary.propTypes = {
  refinanceLoanDetail: PropTypes.object,
  refinanceOptionId: PropTypes.number,
  refinanceValue: PropTypes.number,
  daysUntilDue: PropTypes.number,
}

export default RefinanceBasicSummary
