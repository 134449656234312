import React from 'react'
import { AppIdleTimer } from 'shared/components'
import branding from 'shared/branding'
import { useFlags } from 'launchdarkly-react-client-sdk'

const MfaIdleTimer = () => {
  const {
    timeouts: {
      authentication_steps: { enabled, timeout, promptTimeout },
    },
  } = useFlags()

  return (
    <React.Fragment>
      {enabled && (
        <AppIdleTimer
          isEnrollment={false}
          isApplicationFlow={false}
          promptTimeout={promptTimeout}
          expirationTimeout={timeout}
          expirationUrl={branding.getBrand(branding.current.key).loginUrl}
        />
      )}
    </React.Fragment>
  )
}

export default MfaIdleTimer
