import gql from 'graphql-tag'

const timeoutExpireAction = gql`
  mutation timeoutExpireAction(
    $isEnrollment: Boolean!
    $isManualTrigger: Boolean!
  ) {
    timeoutExpireAction(
      isEnrollment: $isEnrollment
      isManualTrigger: $isManualTrigger
    ) {
      success
    }
  }
`

export { timeoutExpireAction }
