import React from 'react'

function TrustPilotWidget(props) {
  const { businessUnitID } = props

  return (
    <div
      className="trustpilot-widget pl-3"
      style={{ pointerEvents: 'none', cursor: 'default' }}
      data-locale="en-US"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id={businessUnitID}
      data-style-height="120px"
      data-style-width="100%"
      data-theme="light"
      data-tags="SelectedReview"
    >
      <p>Trustpilot</p>
    </div>
  )
}

export default TrustPilotWidget
