import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Query } from 'react-apollo'
import { emailVerificationQuery } from './graphql/queries'

const EmailVerificationQuery = ({
  children,
  enrollmentId,
  mfaId,
  ...props
}) => (
  <Query query={emailVerificationQuery} variables={{ enrollmentId, mfaId }}>
    {({ data, loading, error }) => {
      const emailVerificationId = _.get(
        data,
        'searchEmailVerification.emailVerificationId',
        ''
      )
      const sessionId = _.get(data, 'searchEmailVerification.sessionId')
      return children({
        emailVerificationId,
        sessionId,
        loading,
        error,
        ...props,
      })
    }}
  </Query>
)

EmailVerificationQuery.propTypes = {
  children: PropTypes.func.isRequired,
  enrollmentId: PropTypes.string,
  mfaId: PropTypes.string,
}

export default EmailVerificationQuery
