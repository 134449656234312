import React from 'react'

import Branding from 'shared/branding'

const BrandName = () => {
  const { name } = Branding.current

  return <React.Fragment>{name}</React.Fragment>
}

export default BrandName
