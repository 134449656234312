import React, { useEffect, useState, useRef } from 'react'
import PropType from 'prop-types'
import IdleTimer from 'react-idle-timer'
import authService from 'authentication/service'
import { Button, Modal, ModalFooter, ModalBody } from 'reactstrap'
import { getCookie, setCookie, setCookieToExpire } from 'shared/cookie'
import PageView from 'shared/PageView'
import AppIdleTimeoutExpireAction from './AppIdleTimeoutExpireActionComponent'
import { GLOBAL_PATHS, APPLICATION_PATHS } from 'constants/paths'
import { useFlags } from 'launchdarkly-react-client-sdk'

const idleTimerCookieName = 'extended_timeout'
let idleTimerExtendedTimeout

const setIdleTimeCookie = () => {
  setCookie(document, '', idleTimerCookieName, 1, 900)
}

const clearIdleTimeCookie = () => {
  setCookieToExpire(idleTimerCookieName)
}

const PauseAppIdleTimer = () => {
  setIdleTimeCookie()
  return null
}

const ClearPauseAppIdleTimer = () => {
  clearIdleTimeCookie()
  return null
}

const AppIdleTimer = ({
  isEnrollment,
  promptTimeout,
  expirationTimeout,
  expirationUrl,
  expirationAltUrl,
  extendedTimeout,
  isApplicationFlow,
}) => {
  idleTimerExtendedTimeout = extendedTimeout * 1000

  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [countdown, setCountdown] = useState(expirationTimeout)

  let timeout
  let expireAction
  let interval = useRef()

  const { redirectToP360 } = useFlags()

  const logOut = async (isManualTrigger = false) => {
    setIsLoading(true)
    let redirectUrl = expirationUrl
    const currentUser = await authService.getAuthAttributes()
    if (currentUser.email) {
      authService.signOut(false)
      if (!isApplicationFlow) {
        await expireAction({
          variables: { isEnrollment, isManualTrigger },
        })
      }
    }
    const storeNumber = !isApplicationFlow
      ? getCookie(document, 'store_number')
      : undefined
    storeNumber
      ? PageView.firePageView('/timeout-log-out-store')
      : PageView.firePageView('/timeout-log-out')

    redirectUrl = redirectToP360
      ? process.env.REACT_APP_P360_LOGIN
      : storeNumber
      ? expirationAltUrl
      : redirectUrl
    if (window.location.pathname.startsWith(APPLICATION_PATHS.ACCOUNT)) {
      redirectUrl = GLOBAL_PATHS.ROOT
    }
    window.location.href = redirectUrl
  }

  useEffect(() => {
    if (isOpen && !isLoading) {
      clearInterval(interval.current)
      interval.current = setInterval(function() {
        if (countdown > 0) setCountdown(countdown - 1)
      }, 1000)
    } else {
      clearInterval(interval.current)
    }

    if (!isOpen) {
      setCountdown(expirationTimeout)
    }
  }, [isLoading, isOpen, countdown, expirationTimeout])

  useEffect(() => {
    if (countdown === 0 && !isLoading) {
      logOut()
    }
  })

  const handleContinue = () => {
    PageView.firePageView('/timeout-manual-resume')
    clearInterval(interval.current)
    setIsOpen(false)
  }

  const handleLogOut = () => {
    PageView.firePageView('/timeout-manual-log-out')
    logOut(true)
  }

  const handleOnIdle = () => {
    if (getCookie(document, idleTimerCookieName) && idleTimerExtendedTimeout) {
      timeout = window.setTimeout(() => {
        openModal()
      }, idleTimerExtendedTimeout)
    } else {
      openModal()
    }
  }

  const handleOnAction = () => {
    if (timeout) {
      window.clearTimeout(timeout)
    }
  }

  const openModal = () => {
    PageView.firePageView('/timeout-prompt')
    setIsOpen(true)
  }

  const getMaxZIndex = () =>
    Array.from(document.querySelectorAll('body *'))
      .map(a => parseFloat(window.getComputedStyle(a).zIndex))
      .filter(a => !isNaN(a))
      .sort()
      .pop()

  return (
    <React.Fragment>
      <AppIdleTimeoutExpireAction>
        {({ timeoutExpireAction }) => {
          expireAction = timeoutExpireAction
          return null
        }}
      </AppIdleTimeoutExpireAction>

      <IdleTimer
        element={document}
        onIdle={() => handleOnIdle()}
        onAction={() => handleOnAction()}
        timeout={1000 * promptTimeout}
      />

      <Modal isOpen={isOpen} size="md" zIndex={getMaxZIndex() + 1}>
        <ModalBody className="scroll">
          <p>
            <strong>Session Timeout</strong>
          </p>
          <p>
            Your session will expire in:{' '}
            <strong>
              {countdown} {countdown === 1 ? 'second' : 'seconds'}
            </strong>
          </p>
          <p>Would you like to continue?</p>
        </ModalBody>
        <ModalFooter className="mobile-stacked">
          <Button
            id="app_idle_timer--button-Log-out"
            outline
            color="primary"
            onClick={() => handleLogOut()}
            data-test="timeout-log-out"
            disabled={isLoading}
          >
            NO, END SESSION
          </Button>
          <Button
            color="primary"
            id="app_idle_timer--button-continue"
            onClick={() => handleContinue()}
            data-test="timeout-continue"
            disabled={isLoading}
          >
            YES, CONTINUE SESSION
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

AppIdleTimer.propTypes = {
  isEnrollment: PropType.bool.isRequired,
  isApplicationFlow: PropType.bool,
  promptTimeout: PropType.number.isRequired,
  expirationTimeout: PropType.number.isRequired,
  expirationUrl: PropType.string.isRequired,
  expirationAltUrl: PropType.string,
  extendedTimeout: PropType.number,
}

export { PauseAppIdleTimer, AppIdleTimer, ClearPauseAppIdleTimer }
