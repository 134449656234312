import gql from 'graphql-tag'
import apolloClient from 'shared/apolloClient'
import { LOG_LEVELS } from 'constants/values'

const LOG_MESSAGE = gql`
  mutation logMessage($request: LogMessageType!) {
    logMessage(request: $request)
  }
`

const LOG_DETAILS = gql`
  mutation logDetails($request: LogDetailInput!) {
    logDetails(request: $request)
  }
`

export const log = (message, logLevel = LOG_LEVELS.LOG) => {
  apolloClient.mutate({
    mutation: LOG_MESSAGE,
    variables: { request: { message, logLevel } },
  })
}

export const logDetails = (details, logLevel = LOG_LEVELS.LOG) => {
  const { message, data } = details
  apolloClient.mutate({
    mutation: LOG_DETAILS,
    variables: {
      request: { details: { message, data: JSON.stringify(data) }, logLevel },
    },
  })
}
