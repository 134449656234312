import React from 'react'
import { Col, Row } from 'reactstrap'
import ola from 'shared/images/ola_seal.png'

const FooterLinks = ({ footerLinks, CCBFooter = false }) => {
  if (!footerLinks) return null

  const footerColSize = CCBFooter ? 11 : 8
  const imgColSize = CCBFooter ? 1 : 4
  return (
    <React.Fragment>
      <div id="links-copyright">
        <Row>
          <Col lg={footerColSize} className="mb-3">
            {footerLinks.map((document, index) => (
              <React.Fragment key={document.idTag}>
                <a
                  id={document.idTag}
                  data-test={`links.footer.${document.idTag}`}
                  href={document.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {document.title}
                </a>

                {index < footerLinks.length - 1 && (
                  <span className="spacer">
                    {'  '}|{'  '}
                  </span>
                )}
              </React.Fragment>
            ))}
          </Col>
          <Col lg={imgColSize} className="text-right mb-3">
            <img
              data-test="images.ola"
              id="ola_seal"
              src={ola}
              alt="OLA Seal"
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default FooterLinks
