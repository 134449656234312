import React from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Redirect } from 'react-router-dom'
import { GLOBAL_PATHS } from 'constants/paths'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import { PartnerSiteRedirectWrapper } from 'shared/components'
import Branding from 'shared/branding'

const UnifiedHomepagesRedirect = ({ render, to = GLOBAL_PATHS.MFA, authProps, location }) => {
  const { unifiedHomepages, redirectToP360 } = useFlags()
  const { key } = Branding.current

  if (redirectToP360 && key !== ONLINE_STORE_BRAND_NAME.P360) {
    return <PartnerSiteRedirectWrapper
      brand={key}
      authProps={authProps}
    />
  } else if (unifiedHomepages) {
    return <Redirect to={to} />
  } else {
    return render()
  }
}

UnifiedHomepagesRedirect.propTypes = {
  render: PropTypes.func.isRequired,
  location: PropTypes.object,
  authProps: PropTypes.object
}

export default UnifiedHomepagesRedirect
