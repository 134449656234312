import { useEffect, useRef } from 'react'

const usePreviousProps = props => {
  const prevProps = useRef()
  useEffect(() => {
    prevProps.current = props
  }, [props])

  return prevProps.current
}

export default usePreviousProps
