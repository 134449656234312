import React from 'react'
import PropTypes from 'prop-types'
import PageView from 'shared/PageView'

export default class extends React.PureComponent {
  componentDidMount = () => {
    const { pageUrl, pageTitle } = this.props

    PageView.firePageView(pageUrl, pageTitle)
  }

  render = () => false

  static propTypes = {
    pageUrl: PropTypes.string,
    pageTitle: PropTypes.string,
  }

  static displayName = 'PageView'
}
