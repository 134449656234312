import React from 'react'
import PropTypes from 'prop-types'

const FormikRadio = ({ field, ...props }) => (
  <input type="radio" {...field} {...props} />
)

FormikRadio.propTypes = {
  field: PropTypes.object,
}

export default FormikRadio
