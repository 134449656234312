import React from 'react'
import PropTypes from 'prop-types'
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'

const propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

class PopoverComponent extends React.Component {
  state = {
    popoverOpen: false,
  }

  toggle = () => {
    this.setState(prev => ({
      popoverOpen: !prev.popoverOpen,
    }))
  }

  render() {
    const { popoverOpen } = this.state
    const { name, title, tooltip } = this.props

    return (
      <React.Fragment>
        <button
          href="#"
          data-test={`tooltip.${name}`}
          id={`targetHelpIcon-${name}`}
          className="popover-primary"
          onClick={this.toggle}
          type="button"
          tabIndex="-1"
        >
          <i className="fa fa-question-circle" aria-hidden="true" />
        </button>
        <Popover
          placement="auto-start"
          target={`targetHelpIcon-${name}`}
          isOpen={popoverOpen}
          toggle={this.toggle}
        >
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>{tooltip}</PopoverBody>
        </Popover>
      </React.Fragment>
    )
  }
}

PopoverComponent.propTypes = propTypes

export default PopoverComponent
