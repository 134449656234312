import gql from 'graphql-tag'

const ConversionPreliminaryUnderwriting = gql`
  mutation preliminaryUnderwriting($customerCode: String!) {
    preliminaryUnderwriting(customerCode: $customerCode) {
      status
    }
  }
`

export default {
  ConversionPreliminaryUnderwriting,
}
export { ConversionPreliminaryUnderwriting }
