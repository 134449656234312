import Feature from 'features'

if (Feature.DEV) {
  const authentication = require('authentication/service').default
  const cognito = require('shared/cognito').default

  window.authentication = authentication
  window.cognito = cognito
}

const loggingEnabled = Feature.enabled('CONSOLE_LOGGING')

/* eslint-disable no-console */
export const Console = {
  log: (...params) => {
    loggingEnabled && console.log(...params)
  },
  info: (...params) => {
    loggingEnabled && console.info(...params)
  },
  error: (...params) => {
    loggingEnabled && console.error(...params)
  },
  debug: (...params) => {
    loggingEnabled && console.debug(...params)
  },
  warn: (...params) => {
    loggingEnabled && console.warn(...params)
  },
}
