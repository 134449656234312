import isObject from 'lodash/isObject'
import * as Sentry from '@sentry/browser'
import cognito from 'shared/cognito'
import correlation from 'shared/correlation'
import { release } from '../../package.json'

const REACT_APP_SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY

const saveUserContext = applicationId => {
  const jwt = cognito.idToken

  Sentry.getCurrentScope().setUser({
    email: cognito.currentUser ? cognito.currentUser.username : null,
    applicationId: applicationId ? applicationId : 'no ApplicationId',
    token: jwt ? jwt.substring(jwt.length - 5) : 'no JWT Token',
    sessionId: correlation.getSessionId(),
    correlationId: correlation.getCorrelationId(),
  })
}

export const config = () => {
  if (!Sentry.getClient()) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_KEY,
      release,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
    })
  }
}

export const setUserContext = applicationId => {
  const user = Sentry.getCurrentScope().getUser()
  if (user) {
    if (user.applicationId !== applicationId)
      saveUserContext(applicationId)
  } else {
    saveUserContext(applicationId)
  }
}

export const saveError = error => {
  config()
  const user = Sentry.getCurrentScope().getUser()
  if (!user) {
    setUserContext()
  }
  Sentry.captureMessage(isObject(error) ? JSON.stringify(error) : error)
}
