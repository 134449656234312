import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'

const LoadingComponentScreen = ({ name }) => {
  return (
    <Container className={`${name}-general flex-icon gray-block`}>
      <i
        className="fas fa-spinner fa-spin fa-4x"
        data-test={`${name}-loading-spinner`}
      />
    </Container>
  )
}

LoadingComponentScreen.propTypes = {
  name: PropTypes.string.isRequired,
}

export default LoadingComponentScreen
