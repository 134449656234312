import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'formik'
import { Input, PopoverComponent } from 'shared/components'
import { FormGroup, FormFeedback, Label, FormText } from 'reactstrap'

class InputField extends React.Component {
  render() {
    const {
      title,
      required = false,
      optional = false,
      subtitle,
      titleClass,
      name,
      helptext,
      hideLabel,
      className,
      tooltip,
      willUseReset,
      ...rest
    } = this.props
    const labelClass = classNames(
      titleClass ? titleClass : '',
      hideLabel ? 'sr-only' : ''
    )

    return (
      <Field
        name={name}
        render={({ field, form }) => {
          const { touched, errors } = form
          return (
            <FormGroup className={className} data-test={name}>
              <Label
                htmlFor={name}
                data-test={`labels.${name}`}
                className={labelClass}
              >
                {title}
                {required && <span className="text-danger">*</span>}
                {subtitle && <small className="text-muted"> {subtitle}</small>}
                {optional && <small className="text-muted"> (Optional)</small>}
              </Label>
              {tooltip && (
                <PopoverComponent name={name} title={title} tooltip={tooltip} />
              )}

              <Input
                {...rest}
                field={field}
                form={form}
                willUseReset={willUseReset}
                data-test={`fields.${name}`}
              />

              {helptext && (
                <FormText data-test={`helptext.${name}`}>{helptext}</FormText>
              )}

              {touched[name] && errors[name] && (
                <FormFeedback data-test={`errors.${name}`}>
                  {errors[name]}
                </FormFeedback>
              )}
            </FormGroup>
          )
        }}
      />
    )
  }
}

InputField.contextTypes = {
  formik: PropTypes.object,
}

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  subtitle: PropTypes.string,
  titleClass: PropTypes.string,
  className: PropTypes.string,
  helptext: PropTypes.string,
  hideLabel: PropTypes.bool,
  willUseReset: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  children: PropTypes.node,
  tooltip: PropTypes.string,
}

export default InputField
