const pushEvent = (eventName, eventObject) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventObject,
    })
  }
}

const pushApplicationId = ({ id }) => {
  const eventName = 'applicationIdcreated'
  const index =
    (window.dataLayer &&
      window.dataLayer.findIndex(dl => dl.event === eventName)) ||
    0

  if (index > 0) {
    window.dataLayer[index].applicationId = id
  } else {
    pushEvent(eventName, { applicationId: id })
  }
}

const pushVirtualPageView = (
  virtualPageURL,
  virtualPageTitle,
  details = {}
) => {
  pushEvent('VirtualPageview', {
    virtualPageURL,
    virtualPageTitle,
    ...details,
  })
}

export default {
  pushApplicationId,
  pushVirtualPageView,
}
