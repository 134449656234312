import { ConversionOffers } from 'loan/graphql/queries'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

const ConversionOfferData = ({ customerCode, children }) => {
  return (
    <Query query={ConversionOffers} variables={{ customerCode }}>
      {({ data, loading, error }) => {
        const offers = data.conversionOffers
        return children({ offers, loading, error })
      }}
    </Query>
  )
}

ConversionOfferData.propTypes = {
  children: PropTypes.func.isRequired,
  customerCode: PropTypes.string.isRequired,
}

export default ConversionOfferData
