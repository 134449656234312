import React, { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import cognito from 'shared/cognito'
import {
  ORIGINATED,
  DENIED,
  ESIGN_ERROR,
  ESIGN_RECALCULATED,
} from '../../../constants/values'

const { REACT_APP_WEBSOCKET_ENV } = process.env

export function useIsMounted() {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  return isMounted
}

const closeSocket = (code, reason, webSocketRef) => {
  if (
    webSocketRef.current &&
    webSocketRef.current.readyState === WebSocket.OPEN
  ) {
    webSocketRef.current.close(code, reason)
  }
}

export const EsignWebSocketUtil = ({ applicationId, setAppState }) => {
  const webSocketRef = useRef(WebSocket)
  const isMounted = useIsMounted()
  const [isConnectionOpened, setIsConnectionOpened] = useState(false)
  const serverURL = `${REACT_APP_WEBSOCKET_ENV}?Auth=${cognito.idToken}`

  const registerApplicationId = useCallback((applicationId, webSocketRef) => {
    if (webSocketRef.current) {
      webSocketRef.current.send(
        JSON.stringify({
          action: 'registerApplicationListener',
          applicationId: applicationId,
        })
      )
    }
  }, [])

  useEffect(() => {
    const connectOrReconnect = () => {
      if (
        !webSocketRef.current ||
        webSocketRef.current.CLOSED === 3 ||
        webSocketRef.current.readyState === WebSocket.CLOSED
      ) {
        const webSocket = new WebSocket(serverURL)
        webSocketRef.current = webSocket
      }
    }

    if (!isConnectionOpened) {
      connectOrReconnect()
    }

    if (webSocketRef.current) {
      webSocketRef.current.onopen = () => {
        // ensure it is ready and the component is mounted to prevent memory leak
        if (webSocketRef.current.readyState === 1 && isMounted.current) {
          setIsConnectionOpened(true)
          registerApplicationId(applicationId, webSocketRef)
        }
      }

      webSocketRef.current.onerror = error => {
        setIsConnectionOpened(false)
        setAppState({ error: error })
      }

      webSocketRef.current.onclose = event => {
        if (event.code !== 1000) {
          connectOrReconnect()
        } else {
          setIsConnectionOpened(false)
        }
      }

      webSocketRef.current.onmessage = msg => {
        const fullMessage = JSON.parse(msg.data)
        const messageData = get(fullMessage, 'data.message.body')

        if (messageData) {
          const appState = get(messageData, 'applicationState')
          const action = get(fullMessage, 'action')
          const isError = appState === ESIGN_ERROR
          if (
            appState === ORIGINATED ||
            appState === DENIED ||
            action === ESIGN_RECALCULATED ||
            isError
          ) {
            closeSocket(
              1000,
              'Disconnecting websocket, result found',
              webSocketRef
            )
            setAppState(appState, action)
          }
        }
      }
      // Must return a function - linter
      return () => false
    }

    // prevents memory leak when the page is refreshed during a connection
    return () => {
      closeSocket(1000, 'no longer needed', webSocketRef)
      isMounted.current = false
    }

    // solves lint issue
  }, [
    applicationId,
    isConnectionOpened,
    registerApplicationId,
    serverURL,
    setAppState,
    isMounted,
  ])

  return <React.Fragment />
}
