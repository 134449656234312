import { ConvertLoanDetails } from 'loan/graphql/queries'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

const ConvertLoanDetailData = ({ customerCode, children }) => {
  return (
    <Query query={ConvertLoanDetails} variables={{ customerCode }}>
      {({ data, loading, error }) => {
        const loan = data.convertLoanDetails
        return children({ loan, loading, error })
      }}
    </Query>
  )
}

ConvertLoanDetailData.propTypes = {
  children: PropTypes.func.isRequired,
  customerCode: PropTypes.string.isRequired,
}

export default ConvertLoanDetailData
