import React from 'react'
import PropTypes from 'prop-types'
import { currency, calculateMidpoint } from 'shared/utils'

const calculatePayment = (conversionOptions, selectedAmount) => {
  return currency(
    (selectedAmount * (1 + conversionOptions.apr)) /
      conversionOptions.numberOfPayments,
    2
  )
}

const formatPercentage = percent => {
  return percent * 100
}

const LoanAmountSelection = props => {
  const { conversionOptions, selectedAmount, onAmountChange } = props

  if (!selectedAmount) {
    onAmountChange(
      calculateMidpoint(
        conversionOptions.minimum,
        conversionOptions.maximum,
        conversionOptions.interval
      )
    )
  }

  return (
    <React.Fragment>
      <div className="justify-content-md-center">
        <h2 className="money-text" data-test="text.selected">
          <strong>{currency(selectedAmount, 2)}</strong>
        </h2>
        <input
          type="range"
          data-test="input.amount-range"
          value={selectedAmount}
          onChange={onAmountChange}
          min={conversionOptions.minimum}
          max={conversionOptions.maximum}
          step={conversionOptions.interval}
        />
        <div className="d-flex mb-3">
          <div data-test="text.minimum">
            {currency(conversionOptions.minimum, 2)}
          </div>

          <div data-test="text.maximum" className="ml-auto">
            {currency(conversionOptions.maximum, 2)}
          </div>
        </div>

        <h3 className="mb-0">
          Bi-weekly payment:{' '}
          <strong>{calculatePayment(conversionOptions, selectedAmount)}</strong>
        </h3>

        <p>
          Number of payments:{' '}
          <strong>{conversionOptions.numberOfPayments}</strong>
          <br />
          APR: <strong>{formatPercentage(conversionOptions.apr)} % </strong>
        </p>
      </div>
    </React.Fragment>
  )
}

LoanAmountSelection.propTypes = {
  conversionOptions: PropTypes.shape({
    minimum: PropTypes.number.isRequired,
    maximum: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
    numberOfPayments: PropTypes.number.isRequired,
    apr: PropTypes.number.isRequired,
  }).isRequired,
  selectedAmount: PropTypes.number,
  onAmountChange: PropTypes.func.isRequired,
}

export default LoanAmountSelection
