import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js'

import { CognitoAuth } from 'amazon-cognito-auth-js'
import Branding from 'shared/branding'
import { COGNITO_GROUPS, COGNITO_SOURCE } from 'constants/values'
import { getCookie, setCookieToExpire } from 'shared/cookie'
import { ADMIN_PATHS } from 'constants/paths'

import { CognitoIdentityServiceProvider } from 'aws-sdk'

const cognito = new CognitoIdentityServiceProvider({ region: 'us-east-1' })

class Cognito {
  userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_POOL,
    ClientId: process.env.REACT_APP_AWS_CLIENTID,
  })

  get currentUser () {
    return this.userPool.getCurrentUser()
  }

  get userGroups () {
    const user = this.currentUser

    return (
      user &&
      user.getSession((err, session) => {
        if (err) {
          return null
        }

        return session.idToken.payload['cognito:groups']
      })
    )
  }

  get isAdmin () {
    return Boolean(
      this.userGroups && this.userGroups.includes(COGNITO_GROUPS.CSR)
    )
  }

  get isEnrollment () {
    return Boolean(
      this.userGroups && this.userGroups.includes(COGNITO_GROUPS.ENROLLMENT)
    )
  }

  get isSessionValid () {
    const user = this.currentUser

    return (
      user &&
      !!user.getSession((err, session) => {
        if (err) {
          return null
        }

        return session.isValid()
      })
    )
  }

  get idToken () {
    const user = this.currentUser
    return (
      user &&
      user.getSession((err, session) => {
        if (err) {
          return null
        }

        return session.idToken.jwtToken
      })
    )
  }

  get accessToken () {
    const user = this.currentUser
    return (
      user &&
      user.getSession((err, session) => {
        if (err) {
          return null
        }

        return session.accessToken.jwtToken
      })
    )
  }

  get refreshToken () {
    const user = this.currentUser
    return (
      user &&
      user.getSession((err, session) => {
        if (err) {
          return null
        }

        return session.refreshToken.token
      })
    )
  }

  burnAfterReading = () => {
    if (!this.isEnrollment)
      Object.getOwnPropertyNames(window.localStorage)
        .filter(name => name.endsWith('refreshToken'))
        .forEach(name => window.localStorage.removeItem(name))
  }

  cognitoUser = email =>
    new CognitoUser({
      Username: email.toLowerCase(),
      Pool: this.userPool,
    })

  signIn = credentials => {
    const cognitoUser = this.cognitoUser(credentials.email)

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: credentials.email.toLowerCase(),
          Password: credentials.password,
        }),
        {
          onSuccess: () => {
            this.burnAfterReading()
            resolve()
          },
          onFailure: reject,
        }
      )
    })
  }

  signOut = () => {
    const user = this.currentUser

    if (user !== undefined && user !== null) {
      user.signOut()
    }
  }

  getAttributes = () => {
    const attrUser = this.currentUser

    return new Promise((resolve, reject) => {
      if (attrUser) {
        attrUser.getSession((err, session) => {
          if (err) {
            reject(err)
            return
          }

          if (!session.isValid()) {
            reject()
            return
          }

          this.burnAfterReading()
          attrUser.getUserAttributes((err2, result) =>
            err2 ? reject(err2) : resolve(result)
          )
        })
      } else {
        resolve()
      }
    })
  }

  forgotPassword = email => {
    const cognitoUser = this.cognitoUser(email)

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: resolve,
        onFailure: reject,
      })
    })
  }

  changePassword = (email, verificationCode, newPassword) => {
    const cognitoUser = this.cognitoUser(email)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: resolve,
        onFailure: reject,
      })
    })
  }

  signUp = (credentials, isEnrollment = false) => {
    const { cognitoSource } = Branding.current

    const customSource = isEnrollment
      ? COGNITO_SOURCE.ENROLLMENT
      : cognitoSource

    const cognitoAttributes = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: credentials.email.toLowerCase(),
      }),
      new CognitoUserAttribute({
        Name: 'custom:source',
        Value: customSource,
      }),
      new CognitoUserAttribute({
        Name: 'custom:brand',
        Value: cognitoSource,
      }),
      new CognitoUserAttribute({
        Name: 'name',
        Value: credentials.firstName,
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: credentials.lastName,
      }),
      new CognitoUserAttribute({
        Name: 'custom:isPasswordless',
        Value: credentials.isPasswordless
      })
    ]

    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        credentials.email.toLowerCase(),
        credentials.password,
        cognitoAttributes,
        undefined,
        (err, result) => {
          if (err) {
            let resError = {
              stage: 'SIGNUP',
              userExists: false,
              source: '',
              message: '',
            }
            if (err.code === 'UsernameExistsException') {
              resError.userExists = true
              resError.message = err.message
              resError.source = 'PARTIAL'
            } else if (err.code === 'UserLambdaValidationException') {
              if (err.message.indexOf('PreSignUp') >= 0) {
                resError = this.handlePreSignUpException(err, resError)
              } else if (err.message.indexOf('PostConfirmation') >= 0) {
                resError.userExists = true
                resError.message = err.message
                resError.source = err.code
              }
            } else {
              resError.userExists = false
              resError.message = err.message
              resError.source = err.code
            }
            reject(resError)
          }
          resolve(result)
        }
      )
    })
  }

  handlePreSignUpException = (err, resError) => {
    let resErrObj = {}
    const msgArr = err.message.split(' with error ')
    if (msgArr.length > 1) {
      const jsonString = msgArr[1].trim()
      const jsonErr = JSON.parse(
        jsonString.substr(
          jsonString.indexOf('{'),
          jsonString.lastIndexOf('}') + 1
        )
      )
      if (jsonErr.customerExists) {
        resErrObj = {
          userExists: true,
          message: msgArr[0],
          source: 'LEGACY',
        }
      } else if (jsonErr.isPasswordless) {
        resErrObj = {
          userExists: false,
          message: msgArr[1],
          isPasswordless: true
        }
      } else if (
        jsonErr.customerCheckError ||
        jsonErr.customerConfirmationError
      ) {
        resErrObj = {
          userExists: false,
          message: msgArr[0],
          source: 'LEGACY',
        }
      }
    } else {
      resErrObj = {
        userExists: false,
        message: err.message,
        source: 'LEGACY',
      }
    }
    return { ...resError, ...resErrObj }
  }

  static authData = {
    ClientId: process.env.REACT_APP_AWS_CLIENTID,
    AppWebDomain: process.env.REACT_APP_AWS_DOMAIN,
    TokenScopesArray: ['phone', 'openid', 'aws.cognito.signin.user.admin'],
    RedirectUriSignIn: ADMIN_PATHS.LANDING,
    RedirectUriSignOut: '/',
    IdentityProvider: 'AzureAD',
    UserPoolId: process.env.REACT_APP_AWS_POOL,
    AdvancedSecurityDataCollectionFlag: false,
  }

  singleSignOn = ({ protocol, host, hash }) => {
    const id_token = getCookie(document, 'id_token')
    const access_token = getCookie(document, 'access_token')

    setCookieToExpire('id_token')
    setCookieToExpire('access_token')

    const url =
      id_token && access_token
        ? `${protocol}//${host}#id_token=${id_token}&access_token=${access_token}`
        : `${protocol}//${host}${hash}`

    return new Promise((resolve, reject) => {
      const auth = new CognitoAuth(Cognito.authData)
      auth.userhandler = {
        onSuccess: resolve,
        onFailure: reject,
      }

      auth.parseCognitoWebResponse(url)
    })
  }

  handlePasswordLess = email => {
    const { REACT_APP_AWS_PASSWORDLESS_CLIENTID } = process.env
    return cognito.initiateAuth({
      AuthFlow: 'CUSTOM_AUTH',
      ClientId: REACT_APP_AWS_PASSWORDLESS_CLIENTID,
      AuthParameters: {
        USERNAME: email
      }
    }).promise()
  }

  handleRespondToAuthChallenge = (sessionId, userName, code) => {
    const { REACT_APP_AWS_PASSWORDLESS_CLIENTID } = process.env
    return cognito.respondToAuthChallenge({
      ChallengeName: 'CUSTOM_CHALLENGE',
      ClientId: REACT_APP_AWS_PASSWORDLESS_CLIENTID,
      Session: sessionId,
      ChallengeResponses: {
        USERNAME: userName,
        ANSWER: code
      }
    }).promise()
  }

  refreshTokens = refreshToken => {
    return new Promise((resolve, reject) => {
      this.currentUser.refreshSession(
        new CognitoRefreshToken({
          RefreshToken: refreshToken,
        }),
        (err, result) => {
          if (err) {
            return reject(err)
          } else {
            this.burnAfterReading()
            return resolve(result)
          }
        }
      )
    })
  }
}

export default new Cognito()
