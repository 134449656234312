import { useFlags } from 'launchdarkly-react-client-sdk'
import branding from 'shared/branding'

const BrandingCheck = () => {
  const { p360BrandOverride } = useFlags()
  branding.setLaunchDarklyOverrides(p360BrandOverride)
  branding.addCssLink()

  return null
}

export default BrandingCheck
