import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { WithdrawOpenApplicationMutation } from 'application/graphql/mutations'

const WithdrawApplication = ({ children, ...rest }) => {
  return (
    <Mutation mutation={WithdrawOpenApplicationMutation}>
      {(withdrawApplication, { _data, _error, _loading }) =>
        children({ withdrawApplication, ...rest })
      }
    </Mutation>
  )
}

WithdrawApplication.propTypes = {
  children: PropTypes.func.isRequired,
}

export default WithdrawApplication
