import Branding from 'shared/branding'
import { getCookie } from 'shared/cookie'
import cognito from 'shared/cognito'
import correlation from 'shared/correlation'

const joinParams = (array, symbol) =>
  array.length ? `${symbol}${array.join('&')}` : ''

export const buildUrlWithParams = (
  baseUrl,
  params,
  tokens /* authProps */,
  // options = {}
) => {
  const { search = [], hash = [] } = params

  const searchParams = [].concat(search)
  const hashParams = [].concat(hash)

  const { cookie } = Branding.current
  const campaignCookie =
    getCookie(document, cookie.name) !== undefined
      ? getCookie(document, cookie.name)
      : '1'

  if (!!campaignCookie) {
    searchParams.push(`${cookie.qsKey}=${campaignCookie}`)
  }

  if (tokens && tokens.idToken && tokens.accessToken) {
    hashParams.push(`id_token=${tokens.idToken}`)
    hashParams.push(`access_token=${tokens.accessToken}`)
    hashParams.push(`session_id=${correlation.getSessionId()}`)
  } else {
    if (cognito.accessToken && cognito.idToken) {
      hashParams.push(`id_token=${cognito.idToken}`)
      hashParams.push(`access_token=${cognito.accessToken}`)
      hashParams.push(`session_id=${correlation.getSessionId()}`)
    } else {
      searchParams.push(`session_id=${correlation.getSessionId()}`)
    }
  }

  const searchString = joinParams(searchParams, '?')
  const hashString = joinParams(hashParams, '#')

  return `${baseUrl}${searchString}${hashString}`
}

export const getParamsFromString = paramString =>
  paramString.length ? paramString.substring(1).split('&') : []


export const checkCognito = (redirectUrl, authProps) => {
  const { cookie } = Branding.current
  const campaignCookie = getCookie(document, cookie.name) !== undefined ? getCookie(document, cookie.name) : '1'

  let symbol = '?'

  if (redirectUrl.indexOf('?') > 0) {
    symbol = '&'
  }

  if (!!campaignCookie) {
    redirectUrl = `${redirectUrl}${symbol}${cookie.qsKey}=${campaignCookie}`
  }

  if (authProps && authProps.idToken && authProps.accessToken) {
    return `${redirectUrl}#id_token=${authProps.idToken}&access_token=${authProps.accessToken}&session_id=${correlation.getSessionId()}`
  }

  if (cognito.accessToken && cognito.idToken) {
    redirectUrl = `${redirectUrl}#id_token=${cognito.idToken}&access_token=${cognito.accessToken}&session_id=${correlation.getSessionId()}`
  } else {
    redirectUrl = `${redirectUrl}${symbol}session_id=${correlation.getSessionId()}`
  }
  return redirectUrl
}

