import React from 'react'
import { saveError, setUserContext } from 'shared/sentry'
import PropTypes from 'prop-types'

class Sentry extends React.Component {
  UNSAFE_componentWillUpdate(nextProps) {
    const { applicationId, error } = nextProps
    if (applicationId) {
      setUserContext(applicationId)
    }

    if (error) {
      saveError(error)
    }
  }

  componentDidMount() {
    const { error } = this.props

    if (error) {
      saveError(error)
    }
  }

  render() {
    return null
  }
}

Sentry.propTypes = {
  applicationId: PropTypes.string,
  error: PropTypes.string,
}

export default Sentry
