import React from 'react'
import PropTypes from 'prop-types'
import { PopoverComponent } from 'shared/components'
import { Row, Col, Input } from 'reactstrap'

const RememberDeviceTooltip = () => {
  return (
    <React.Fragment>
      <p>
        Choosing "Remember My Device" will save you time the next time you
        login.
      </p>
      <p>
        For your proctection, choose this option only on your personal devices
        that you trust are secure.
      </p>
    </React.Fragment>
  )
}

const RememberDeviceCheckbox = ({ checked, onChange, disabled }) => (
  <React.Fragment>
    <Row>
      <Col>
        <h4 className="mb-1">
          Remember My Device
          <PopoverComponent
            name="remember-my-device"
            title="Remember My Device"
            tooltip={<RememberDeviceTooltip />}
          />
        </h4>
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="ml-4 mb-4 mt-1">
          <Input
            data-test={`check.remember-device`}
            type="checkbox"
            name="rememberDevice"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          Remember this device to help make signing in faster.
        </div>
      </Col>
    </Row>
  </React.Fragment>
)

RememberDeviceCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default RememberDeviceCheckbox
