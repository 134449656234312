import React, { useEffect, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Container,
} from 'reactstrap'
import { LoadingScreen } from 'shared/components'
import Branding from 'shared/branding'
import Base64 from 'base64-js'

const currentBrand = Branding.current
const pdfViewerBase = process.env.REACT_APP_PDF_VIEWER_BASE

export const trimBaseUrl = url => {
  const baseUrl = `https://www.${currentBrand.baseDomain
    .replace('www.', '')
    .replace('checkngo.com/xact', 'xact.com')}/`
  if (url) {
    if (url.startsWith('https://www.checkngo.com/')) {
      return url.substring(24)
    } else if (url.startsWith(baseUrl)) {
      return url.substring(baseUrl.length - 1)
    }
  }
  return url
}

const getFullUrl = url => {
  if (url && url.length > 0) {
    const urlBase = trimBaseUrl(url)
    return `${pdfViewerBase}${urlBase}`
  }

  return pdfViewerBase
}

const PdfViewer = ({
  isOpen,
  title,
  url,
  pdfData,
  closeOnly,
  onAgree,
  onDisagree,
  onClose,
  loading,
  useFluidHeight,
}) => {
  const iframeRef = useRef()
  // Hooks version of "componentDidUpdate"
  const didUpdateRef = useRef(false)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (useFluidHeight) {
      const setIframeHeight = () => {
        if (iframeRef.current) {
          iframeRef.current.style.height = `${window.innerHeight - 200}px`
        }
      }

      setIframeHeight()
      window.addEventListener('resize', setIframeHeight)

      return () => {
        window.removeEventListener('resize', setIframeHeight)
      }
    }
  }, [])

  useEffect(() => {
    // When a signature is not needed, display without a prior update to a parent component
    if (didUpdateRef.current || (!onAgree && closeOnly)) {
      displayRawPdfData()
    } else {
      didUpdateRef.current = true
    }
  })

  const displayRawPdfData = () => {
    const iframe = iframeRef.current

    if (pdfData && iframe) {
      const pdf = Base64.toByteArray(pdfData)
      const handler = () => {
        iframe.contentWindow.PDFViewerApplication.open(pdf)
        iframe.removeEventListener(handler)
      }
      iframe.addEventListener('load', handler, true)
    }
  }

  const cleanupPDFViewer = action => () => {
    const iframe = iframeRef.current
    if (iframe && iframe.contentWindow.PDFViewerApplication) {
      iframe.contentWindow.PDFViewerApplication.cleanup()
      iframe.contentWindow.PDFViewerApplication.close()
    }

    if (action) action()
  }

  return (
    <Modal isOpen={isOpen} toggle={cleanupPDFViewer(onClose)} size="lg">
      <ModalHeader toggle={cleanupPDFViewer(onClose)}>{title}</ModalHeader>
      <ModalBody className="pt-0">
        {loading && <LoadingScreen inline> Loading document </LoadingScreen>}
        {!loading && (
          <>
            {!closeOnly && (
              <Row>
                <Col className="pleaseRead text-center">
                  Please read this document completely to agree and sign
                  {!url && (
                    <React.Fragment>
                      <br />
                      <p>
                        Attention: If the entire Loan Agreement is not viewable,
                        please close and reopen the PDF viewer. The entire Loan
                        Agreement should be viewable before clicking “Agree”
                        below. If assistance is needed, please contact Customer
                        Service at{' '}
                        <a href={`tel:${currentBrand.phoneNumber}`}>
                          {currentBrand.phoneNumber}
                        </a>
                        .
                      </p>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <iframe
                  ref={iframeRef}
                  title="pdfviewer"
                  src={getFullUrl(url)}
                  height="500"
                  width="100%"
                />
              </Col>
            </Row>
            {url && (
              <Row>
                <Col>
                  <p className="m-0 mt-0 viewerProblems">
                    Having trouble viewing the document?{' '}
                    <a
                      href={trimBaseUrl(url)}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      Click here
                    </a>{' '}
                    to open it in a new window.
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}
      </ModalBody>
      {closeOnly ? (
        <ModalFooter>
          <Button
            id="pdf_viewer--button-close"
            color="primary"
            onClick={cleanupPDFViewer(onClose)}
            data-test="close-button"
          >
            Close
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Container className="pdfViewer-footer-container">
            <Row>
              <Col xs="6">
                <Button
                  id="pdf_viewer--button-disagree"
                  className="btn-block"
                  color="primary"
                  outline
                  onClick={cleanupPDFViewer(onDisagree)}
                  data-test="disagree-button"
                >
                  Disagree
                </Button>
              </Col>
              <Col xs="6">
                <Button
                  id="pdf_viewer--button-agree"
                  className="btn-block"
                  color="primary"
                  onClick={cleanupPDFViewer(onAgree)}
                  data-test="agree-button"
                >
                  Agree
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFooter>
      )}
    </Modal>
  )
}

PdfViewer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
  loading: PropTypes.bool,
  pdfData: PropTypes.string,
  closeOnly: PropTypes.bool.isRequired,
  onAgree: PropTypes.func,
  onDisagree: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  useFluidHeight: PropTypes.bool,
}

// hooks version of pure component
export default memo(PdfViewer)
