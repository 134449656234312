import { RefinanceDetails } from 'loan/graphql/queries'
import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

const RefinanceDetailData = ({ customerCode, children }) => {
  return (
    <Query query={RefinanceDetails} variables={{ customerCode }}>
      {({ data, loading, error }) => {
        const refinance = data.refinanceDetails
        return children({ refinance, loading, error })
      }}
    </Query>
  )
}

RefinanceDetailData.propTypes = {
  children: PropTypes.func.isRequired,
  customerCode: PropTypes.string.isRequired,
}

export default RefinanceDetailData
