import React from 'react'
import PropTypes from 'prop-types'
import Feature from 'features'

const DefaultMessage = () => <span>Processing, please wait...</span>

const LoadingScreen = ({ children, inline, ...rest }) => {
  let message = children
  if (typeof children === 'function') {
    message = children({ ...rest })
  }

  if (typeof message === 'string' && Feature.enabled('DETAILED_MESSAGES')) {
    message = <span>{message}</span>
  } else if (
    typeof message === 'undefined' ||
    !Feature.enabled('DETAILED_MESSAGES')
  ) {
    message = <DefaultMessage />
  }

  let classname = 'LoadingScreen'
  if (inline) {
    classname += ' LoadingScreen-sm'
  }

  let iconClass = 'fas fa-spinner fa-spin'
  if (!inline) {
    iconClass += ' fa-3x'
  }

  return (
    <div className={classname}>
      <h2 className="text-center">
        {message}
        <i className={iconClass} data-test="loading-spinner" />
      </h2>
    </div>
  )
}

LoadingScreen.propTypes = {
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
}

export default LoadingScreen
