import { LOAN_PATHS } from 'constants/paths'

const sections = [
  {
    id: '1',
    name: 'Customize',
    description: 'Customize',
    routes: [{ path: LOAN_PATHS.CONVERSION }],
  },
  { id: '2', name: 'ESign', description: 'eSign Documents', routes: [] },
  { id: '3', name: 'Confirmation', description: 'Confirmation', routes: [] },
]

export default { sections }
export { sections }
