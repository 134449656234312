import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import moment from 'moment'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import Branding from 'shared/branding'

const { key } = Branding.current
const { REACT_APP_LOCATION_URI } = process.env

const storeTime = time => (time ? moment(time, 'HH:mm:ss').format('LT') : time)

const mapHour = ({ openTime, closeTime, closed }) => ({
  openTime: closed ? null : storeTime(openTime),
  closeTime: closed ? null : storeTime(closeTime),
})

const phoneNumber = rawPhone => rawPhone.replace(/[()-\s]/g, '')
const trimZip = zip => (zip.trim().endsWith('-') ? zip.substring(0, 5) : zip)
const trimStoreName = storeName => {
  const firstPara = storeName.indexOf('(')
  if (firstPara > -1) {
    return storeName.substring(0, firstPara)
  }

  return storeName
}

const mapStoreToProps = ({
  storeNumber,
  storeName = '',
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode,
  longitude,
  latitude,
  phone,
  hours,
  brand,
}) => ({
  storeNumber,
  storeName: trimStoreName(storeName),
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode: trimZip(postalCode),
  longitude,
  latitude,
  brand,
  phone: phoneNumber(phone),
  hours: hours ? hours.map(mapHour) : [],
  dayOfWeek: moment().format('dddd'),
})

class StoreLocatorComponent extends React.PureComponent {
  static SEARCH_LIMIT = 9
  static SEARCH_STEP = 3
  static MAX_DISTANCE = 160940 * 5 // 500 miles

  state = {
    postalCode: '',
    storeSearchResults: [],
    currentSearchLimit: 0,
    loadingSearchResults: false,
    selectedStore: null,
    error: null,
  }

  componentDidMount = () => {
    this.initSearch()
  }

  componentDidUpdate = prevProps => {
    const { address, storeNumber } = this.props
    if (
      (storeNumber && !prevProps.storeNumber) ||
      (address && !prevProps.address)
    ) {
      this.initSearch()
    }
  }

  initSearch = () => {
    const { address, storeNumber, ignoreBranding = false } = this.props

    if (storeNumber && storeNumber !== '2997') {
      this.doLocationSearch(
        {
          type: 'Store',
          storeNumber,
        },
        ignoreBranding
      ).then(results => {
        if (results && results.length) {
          this.setState({
            storeSearchResults: [],
            selectedStore: results[0],
            isClosestStore: false,
          })
        }
      })
    } else if (address) {
      this.getInitialStoreResultsByAddress(address).then(results => {
        if (results && results.length) {
          this.setState({
            storeSearchResults: [],
            selectedStore: results[0],
            isClosestStore: true,
          })
        } else {
        }
      })
    }
  }

  getInitialStoreResultsByAddress = async address => {
    const {
      zipCode: postalCode,
      stateCode,
      addressLine1,
      city,
      state,
    } = address

    let results = await this.doLocationSearch({
      addressLine1,
      city,
      state,
      postalCode,
      type: 'Store',
      params: {
        limit: 1,
        stateRestriction: [stateCode],
        maxDistance: StoreLocatorComponent.MAX_DISTANCE,
      },
    })

    if (!results || !results.length) {
      results = await this.doLocationSearch({
        postalCode,
        type: 'Store',
        params: {
          limit: 1,
          stateRestriction: [stateCode],
          maxDistance: StoreLocatorComponent.MAX_DISTANCE,
        },
      })
    }

    return results
  }

  getMoreResults = () => {
    const { postalCode, storeSearchResults, currentSearchLimit } = this.state
    const newSearchLimit =
      currentSearchLimit + StoreLocatorComponent.SEARCH_STEP
    if (newSearchLimit > storeSearchResults.length) {
      const { onlySearchInState, address = {} } = this.props
      const { stateCode } = address
      const params = {
        skip: storeSearchResults.length,
        limit: StoreLocatorComponent.SEARCH_LIMIT,
        maxDistance: StoreLocatorComponent.MAX_DISTANCE,
      }
      if (onlySearchInState && stateCode) {
        params.stateRestriction = [stateCode]
      }
      this.doLocationSearch({
        postalCode,
        type: 'Store',
        params,
      })
    } else {
      this.setState({ currentSearchLimit: newSearchLimit })
    }
  }

  fetchLocationDataByPostalCode = postalCode => {
    this.setState(
      {
        currentSearchLimit: 0,
        storeSearchResults: [],
      },
      () => this.fetchLocationAfterLimitReset(postalCode)
    )
  }

  fetchLocationAfterLimitReset = postalCode => {
    const { onlySearchInState, address = {} } = this.props
    const { stateCode } = address

    const params = {
      limit: StoreLocatorComponent.SEARCH_LIMIT,
      maxDistance: StoreLocatorComponent.MAX_DISTANCE,
    }

    if (onlySearchInState && stateCode) {
      params.stateRestriction = [stateCode]
    }

    this.doLocationSearch({
      postalCode,
      type: 'Store',
      params,
    })
  }

  doLocationSearch = (params, ignoreBranding = false) => {
    this.setState({
      loadingSearchResults: true,
      postalCode: params.postalCode,
    })
    const { activeStoresOnly = false } = this.props
    params.activeOnly = activeStoresOnly

    if (!ignoreBranding) {
      const { key, partnerBrand } = Branding.current
      params.brand = key === 'CCB' ? partnerBrand : key
    }
    return axios
      .post(`${REACT_APP_LOCATION_URI}/search`, params)
      .then(this.processSearchResponse)
      .catch(error => {
        this.setState({ error, loadingSearchResults: false })
      })
  }

  processSearchResponse = ({ data: { results } }) => {
    const { storeSearchResults, currentSearchLimit } = this.state
    const newSearchResults = results ? results.map(mapStoreToProps) : []

    const allResults = [...storeSearchResults, ...newSearchResults]

    let newSearchLimit = currentSearchLimit
    if (allResults.length >= currentSearchLimit) {
      newSearchLimit += StoreLocatorComponent.SEARCH_STEP
    }

    this.setState({
      storeSearchResults: allResults,
      loadingSearchResults: false,
      currentSearchLimit: newSearchLimit,
    })
    return allResults
  }

  selectStore = storeNumber => {
    const { storeSearchResults } = this.state
    const store = storeSearchResults.find(
      ({ storeNumber: checkNum }) => checkNum === storeNumber
    )
    this.setState({
      selectedStore: store,
      isClosestStore: false,
    })
  }

  addressString = store => {
    const addrString = `Check+\`n+Go+${
      store.addressLine1
    } ${store.addressLine2 || ''} ${store.city}, ${store.state} ${
      store.postalCode
    }`
    return encodeURI(addrString.replace(/\s/g, '+'))
  }

  googleDirectionsUri = () => {
    const { selectedStore: store } = this.state

    if (store) {
      const addrString = this.addressString(store)
      return `https://www.google.com/maps/dir/?api=1&destination=${addrString}`
    }

    return ''
  }

  googleMapsUri = () => {
    const { selectedStore: store } = this.state

    if (store) {
      const addrString = this.addressString(store)
      return `https://maps.google.com/maps/search/?api=1&query=${addrString}`
    }

    return ''
  }

  render = () => {
    const {
      storeSearchResults,
      loadingSearchResults,
      selectedStore,
      isClosestStore,
      error,
      currentSearchLimit,
    } = this.state

    const { render, showPartner, hideIfNoData } = this.props
    const allowSearch = key !== ONLINE_STORE_BRAND_NAME.CCB

    const childProps = {
      isClosestStore,
      storeSearchResults: storeSearchResults.slice(0, currentSearchLimit),
      loadingSearchResults,
      searchResultsError: error,
      selectedStore,
      selectStore: this.selectStore,
      googleMapsUri: this.googleMapsUri(),
      googleDirectionsUri: this.googleDirectionsUri(),
      searchByZipcode: this.fetchLocationDataByPostalCode,
      allowSearch,
      getMoreResults: this.getMoreResults,
      showPartner,
    }

    if (hideIfNoData && !selectedStore) return null

    return render(childProps)
  }

  static propTypes = {
    address: PropTypes.shape({
      addressLine1: PropTypes.string.isRequired,
      addressLine2: PropTypes.string,
      cityName: PropTypes.string.isRequired,
      stateCode: PropTypes.string.isRequired,
      zipCode: PropTypes.string.isRequired,
    }),
    storeNumber: PropTypes.string,
    showPartner: PropTypes.bool,
    onlySearchInState: PropTypes.bool,
    hideIfNoData: PropTypes.bool,
    render: PropTypes.func.isRequired,
    activeStoresOnly: PropTypes.bool,
  }
}

export default StoreLocatorComponent
