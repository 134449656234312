import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, Label, Input, Button } from 'reactstrap'

const ConsentCheck = ({
  contextId,
  className,
  checked,
  onCheckboxClick,
  disabled,
  onButtonClick,
  dataTest,
  children,
}) => (
  <FormGroup check>
    <Input
      type="checkbox"
      className={className}
      checked={checked}
      onClick={onCheckboxClick}
      data-test={`${dataTest}-checkbox`}
      readOnly
    />
    <Button
      id={`consent_check--button-${contextId}_read_and_agree`}
      outline
      color="primary"
      size="sm"
      disabled={disabled || false}
      data-test={dataTest}
      onClick={onButtonClick}
    >
      Read &amp; Agree
    </Button>
    &nbsp;
    <Label check>
      I have read, understood and accepted the terms of the {children}.
    </Label>
  </FormGroup>
)

ConsentCheck.propTypes = {
  contextId: PropTypes.string.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  dataTest: PropTypes.string.isRequired,
  children: PropTypes.string,
}

export default ConsentCheck
