import React from 'react'
import PropTypes from 'prop-types'
import { timeoutExpireAction } from './mutations'
import { Mutation } from 'react-apollo'

const AppIdleTimeoutExpireAction = ({ children, ...rest }) => {
  return (
    <Mutation mutation={timeoutExpireAction}>
      {(timeoutExpireAction, { data, error, loading }) =>
        children({ timeoutExpireAction, ...rest })
      }
    </Mutation>
  )
}

AppIdleTimeoutExpireAction.propTypes = {
  children: PropTypes.func.isRequired,
}

export default AppIdleTimeoutExpireAction
