import { graphql, compose } from 'react-apollo'
import { withAuth } from 'authentication/components/WithAuth'
import { OpenApplicationQuery } from './queries'
import { getGPCStatus } from 'shared/utils'

const extractProps = ({ authProps }) => {
  const { data = {} } = authProps
  const { authentication = {} } = data
  const { isAuthenticated = false, email } = authentication
  return { isAuthenticated, email }
}

const withOpenApplication = component => {
  return graphql(OpenApplicationQuery, {
    options: props => {
      const { email, } = extractProps(props)

      return {
        variables: { email, isGPCEnabled: getGPCStatus() },
      }
    },
    skip: props => {
      const { isAuthenticated, email } = extractProps(props)
      return !isAuthenticated || !email || email === ''
    },
    props: queryProps => ({ queryProps }),
  })(component)
}

export const withAuthPendingApp = component => {
  return compose(
    withAuth,
    withOpenApplication,
  )(component)
}
