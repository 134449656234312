import React from 'react'
import PropTypes from 'prop-types'
import { currency } from 'shared/utils'

const RefinanceBorrowSummary = props => {
  const { refinanceLoanDetail, refinanceValue } = props
  return (
    <div className="mb-3">
      <p className="mb-0">
        <strong>Cash back to you</strong>
      </p>

      <div className="d-flex">
        <span>Additional amount requested</span>
        <span className="ml-auto">
          <strong>{currency(refinanceValue || 0, 2)}</strong>
        </span>
      </div>
      <div className="d-flex">
        <span className="pr-3">
          Less fees due on {refinanceLoanDetail.loanDueDate}
        </span>
        <span className="ml-auto">
          <strong>-{currency(refinanceLoanDetail.loanFeeAmount, 2)}</strong>
        </span>
      </div>

      <hr />

      <div className="d-flex">
        <span>Total amount you will receive</span>
        <span className="ml-auto">
          <strong>
            {currency(
              (refinanceValue || 0) - refinanceLoanDetail.loanFeeAmount,
              2
            )}
          </strong>
        </span>
      </div>
      <small className="text-muted form-text">
        <em>
          Need more money than this? You will need to request a larger
          additional loan amount
        </em>
      </small>
    </div>
  )
}

RefinanceBorrowSummary.propTypes = {
  refinanceLoanDetail: PropTypes.shape({
    loanAmount: PropTypes.number.isRequired,
    loanFeeAmount: PropTypes.number.isRequired,
    loanDueDate: PropTypes.string.isRequired,
    newDueDate: PropTypes.string.isRequired,
    financeCharge: PropTypes.number.isRequired,
  }).isRequired,
  refinanceValue: PropTypes.number,
}

export default RefinanceBorrowSummary
