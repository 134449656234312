import React from 'react'
import { Alert, Container } from 'reactstrap'
import Branding from 'shared/branding'
import { BrandingCheck, Header, Helmet } from 'shared/components'

const ErrorBody = () => {
  return (
    <React.Fragment>
      <Helmet brandName={Branding.current.name} pageName="Error" />
      <BrandingCheck />
      <Header />
      <Container className="my-5">
        <Alert color="danger" className="my-5 text-body">
          <p>
            <i className="fas fa-exclamation-circle mr-2 text-danger fa-2x" />
            Hmmm... something&apos;s not working at the moment. Please try again
            later.
          </p>
        </Alert>
      </Container>
    </React.Fragment>
  )
}

export default ErrorBody
