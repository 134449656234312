import React from 'react'
import PropTypes from 'prop-types'

const splitPhone = phoneNumber => ({
  areaCode: phoneNumber.substring(0, 3),
  exchange: phoneNumber.substring(3, 6),
  number: phoneNumber.substring(6, 10),
})

const StorePhone = ({ phoneNumber, showDetails }) => {
  const { areaCode, exchange, number } = splitPhone(phoneNumber)
  const href = `tel:${phoneNumber}`

  return (
    <div className="mb-2" data-test="phone">
      {showDetails && <i className="fas fa-phone fa-flip-horizontal mr-2" />}
      <a href={href}>
        ({areaCode}) {exchange}-{number}
      </a>
    </div>
  )
}

StorePhone.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  showDetails: PropTypes.bool,
}

export default StorePhone
