import React from 'react'
import { Helmet } from 'shared/components'
import PropTypes from 'prop-types'
import { Container, FormGroup } from 'reactstrap'

import RefinanceOption from './RefinanceOption'
import RefinanceBreakdown from './RefinanceBreakdown'

const refinanceOptions = [
  {
    refinanceOptionId: 1,
    name: 'repayOriginal',
    optionLabel: 'Get more time to repay original loan',
    title: 'More time to repay',
    description:
      'You can refinance your current loan and get more time to repay. You will need to pay the fees due on your original loan by &lt;DUE_DATE&gt;',
  },
  {
    refinanceOptionId: 2,
    name: 'repayAndReduce',
    optionLabel: 'Get more time to repay AND reduce fees',
    title: 'Reduce your fees',
    description:
      "You can refinance your current loan and get more time to repay. And, you can reduce the fees that will be due on &lt;DUE_DATE&gt; by paying back some of the money you've already borrowed. The more you pay back towards the original loan, the lower your fees will be on your next payment. In addition to this required amount, you will still need to pay the fees due on your original loan by &lt;NEW_DUE_DATE&gt;",
    refinanceInput: {
      title: "I'd like to pay back",
    },
  },

  {
    refinanceOptionId: 3,
    name: 'repayAndBorrow',
    optionLabel: 'Get more time to repay AND borrow more',
    title: 'Borrow more money',
    description:
      'If you need more cash right now, you can borrow additional funds. You will still need to pay the fees due on your original loan by &lt;DUE_DATE&gt;. The fees currently due will be subtracted from the additional amount you request.',
    refinanceInput: {
      title: 'Borrow an additional',
      link: {
        title: 'When will I receive my money?',
        header: 'When will I receive my money?',
        body:
          'Great question! Make sure you do everything required to refinance, so you won’t experience any delays. \n' +
          'If your refinance is completed on Monday through Friday by 8:00 pm EST, your money will be deposited into your bank account on the following business day. \n' +
          'If your refinance is completed Monday-Friday after 8:00 pm EST, on a holiday or on the weekend, the money will be deposited into your bank account in two business days.',
      },
    },
  },
]

class RefinanceContainer extends React.Component {
  state = {
    selectedOption: refinanceOptions.find(o => o.refinanceOptionId === 1),
    userInputValue: null,
    submitted: false,
  }

  handlePaymentOptionChange = e => {
    const option = refinanceOptions.find(
      o => o.refinanceOptionId === parseInt(e.target.value, 0)
    )
    this.setState({
      userInputValue: null,
      selectedOption: option,
    })
  }

  handleUserInput = e => {
    const value = e.target.value ? parseFloat(e.target.value, 0) : 0
    this.setState({ userInputValue: value })
  }

  handleSubmit = () => {
    this.setState({ submitted: true })
  }

  render() {
    const { loanDetail } = this.props
    const { userInputValue, selectedOption } = this.state
    return (
      <React.Fragment>
        <div>
          <Helmet brandName="" pageName="Refinance your loan Loan" />
          <Container>
            <h1 data-test="text.page-title">Refinance your Loan</h1>
            <p data-test="text.page-subtitle">
              When you refinance your loan, you have several options available
              to you:
            </p>

            <FormGroup tag="fieldset">
              {refinanceOptions.map(option => {
                return (
                  <RefinanceOption
                    key={option.refinanceOptionId}
                    option={option}
                    selectedRefinanceOption={selectedOption}
                    handleSelectionChange={this.handlePaymentOptionChange}
                    data-test="section.refinance-option"
                  />
                )
              })}
            </FormGroup>

            <hr />

            <RefinanceBreakdown
              selectedOption={selectedOption}
              refinanceLoanDetail={loanDetail}
              handleUserInput={this.handleUserInput}
              refinanceValue={userInputValue}
              onSubmit={this.handleSubmit}
              data-test="section.refinance-breakdown"
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

RefinanceContainer.propTypes = {
  loanDetail: PropTypes.object.isRequired,
}

export default RefinanceContainer
