import React from 'react'
import PropTypes from 'prop-types'
import ConversionDenialMutation from './ConversionDenialMutation'
import ConversionDenialModal from './ConversionDenialModal'

const ConversionDenialComponent = ({ applicationId, showDenialModal }) => (
  <ConversionDenialMutation>
    {({ createConversionDenial }) => (
      <ConversionDenialModal
        applicationId={applicationId}
        createConversionDenial={createConversionDenial}
        isOpen={showDenialModal}
      />
    )}
  </ConversionDenialMutation>
)

ConversionDenialComponent.propTypes = {
  applicationId: PropTypes.string.isRequired,
  showDenialModal: PropTypes.bool.isRequired,
}

export default ConversionDenialComponent
