import React from 'react'
import PropTypes from 'prop-types'
import { Input, Label } from 'reactstrap'

const RefinanceOption = props => {
  const { option, selectedRefinanceOption, handleSelectionChange } = props

  return (
    <div className="form-check" key={option.refinanceOptionId}>
      <Label check data-test={`labels.${option.name}`}>
        <Input
          type="radio"
          name={option.name}
          value={option.refinanceOptionId}
          checked={
            selectedRefinanceOption &&
            selectedRefinanceOption.refinanceOptionId ===
              option.refinanceOptionId
          }
          onChange={handleSelectionChange}
          data-test={`option.refinanceOptionId-${option.refinanceOptionId}`}
        />
        {option.optionLabel}
      </Label>
    </div>
  )
}

RefinanceOption.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    refinanceOptionId: PropTypes.number.isRequired,
  }).isRequired,
  selectedRefinanceOption: PropTypes.shape({
    refinanceOptionId: PropTypes.number.isRequired,
  }).isRequired,
  handleSelectionChange: PropTypes.func.isRequired,
}

export default RefinanceOption
