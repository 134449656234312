import React from 'react'
import { Col, Card, CardBody, Row, CardFooter, Button } from 'reactstrap'

const ComparisonCards = ({ products, customActions }) =>
  products.map((item, index) => (
    <Col sm={12} md={6} className="pb-3" key={index}>
      <Card className="h-100">
        <CardBody>
          <Row className="border-bottom">
            <Col>
              <Row className="ml-2">
                <h5 className="text-left">
                  <strong>{item.header}</strong>
                </h5>
              </Row>
            </Col>
          </Row>
          <Row className="text-left pb-2 ml-2 pt-2">
            <Col className="pl-0">{item.paragraph}</Col>
          </Row>
          <Row className="ml-2">
            <Col className="pl-2">
              <ul>
                {item.listItems.map((detail, liIndex) => {
                  return (
                    <li className="text-left" key={-liIndex}>
                      {detail}
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Row>
        </CardBody>
        {customActions && customActions[index] && (
          <CardFooter className="d-none d-md-block hidden-border pt-0">
            <Row>
              <Col>
                <Button
                  sm={12}
                  color="primary"
                  onClick={customActions[index].onClick}
                  id={customActions[index].btnId}
                  data-test="buttons.close"
                  outline={customActions[index].outline}
                  className="w-100"
                >
                  {customActions[index].text}
                </Button>
              </Col>
            </Row>
          </CardFooter>
        )}
      </Card>
    </Col>
  ))

export default ComparisonCards
