import React from 'react'
import PropTypes from 'prop-types'

const event = field => ({
  event: 'trackEvent',
  eventCategory: 'form error',
  eventAction: field,
  eventLabel: 'New Application',
})

class LogErrors extends React.PureComponent {
  state = {
    reportedFields: [],
  }

  componentDidUpdate() {
    if (!window.dataLayer) {
      return
    }

    const { reportedFields } = this.state
    const { formik } = this.props

    if (!formik) {
      return
    }

    const { errors, touched } = formik
    const nextKeys = Object.keys(errors)
    const newKeys = nextKeys.filter(key => !reportedFields.includes(key))

    newKeys.forEach(key => {
      if (!touched[key]) {
        return
      }

      window.dataLayer.push(event(key))
      reportedFields.push(key)
      this.setState({
        reportedFields,
      })
    })
  }

  render() {
    return null
  }

  static propTypes = {
    formik: PropTypes.object,
  }
}

export default LogErrors
