import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Alert, Container } from 'reactstrap'
import EmailVerificationForm from './EmailVerificationForm'
import {
  ENROLLMENT_STATES,
  EMAIL_VERIFICATION_STATUS,
  MFA_STATUS,
} from 'shared/constants'
import { AssistanceLinks } from 'shared/components'
import pageView from 'shared/PageView'
import { ENROLLMENT_PATHS } from 'constants/paths'
import { maskEmail } from 'shared/Masks'
import getBlackbox from 'shared/Iovation'

const initialValues = {
  verificationCode: '',
}

const validationSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required('Required.')
    .max(10, 'Max characters exceeded.'),
})

const EmailVerificationPage = ({
  emailVerificationId,
  sessionId,
  email,
  emailResendCode,
  isResendingCode,
  enrollmentId,
  mfaId,
  validateEmailCode,
  updateState,
  rememberDevice,
  canGoBack,
  goBack,
}) => {
  const isEnrollment = !!enrollmentId
  const isMfa = !!mfaId
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState('')
  const [verificationSessionId, setVerificationSessionId] = useState(sessionId)
  const [willRememberDevice, setWillRememberDevice] = useState(false)
  const [hasResend, setHasResend] = useState(false)

  const handleOnSubmit = async (
    { verificationCode },
    { resetForm, setSubmitting }
  ) => {
    setSubmitted(true)
    setError('')
    setHasResend(false)
    try {
      const { data } = await validateEmailCode({
        variables: {
          emailVerificationId,
          sessionId: verificationSessionId || sessionId,
          verificationCode,
          enrollmentId,
          mfaId,
        },
      })

      const {
        validateEmailVerificationCode: { status },
      } = data

      if (status === EMAIL_VERIFICATION_STATUS.PENDING) {
        resetForm()
        setError(
          'Verification failed. Please make sure the code was entered correctly and try again.'
        )
      } else if (status === EMAIL_VERIFICATION_STATUS.APPROVED) {
        pageView.firePageView(ENROLLMENT_PATHS.VERIFICATION_SUCCESS)

        if (willRememberDevice)
          await rememberDevice({
            variables: {
              mfaId,
              blackBox: getBlackbox(),
            },
          })

        updateState(
          enrollmentId
            ? ENROLLMENT_STATES.APPROVED
            : mfaId
              ? MFA_STATUS.ALLOW
              : ''
        )
      } else {
        pageView.firePageView(ENROLLMENT_PATHS.EMAIL_VERIFICATION_FAILED)

        updateState(
          enrollmentId ? ENROLLMENT_STATES.DENIED : mfaId ? MFA_STATUS.DENY : ''
        )
      }
    } catch (error) {
      updateState(ENROLLMENT_STATES.ERROR)
    }

    setSubmitting(false)
  }

  const handleResendCode = async () => {
    setError('')
    const result = await emailResendCode({ variables: { enrollmentId, mfaId } })
    setVerificationSessionId(result.data.emailResendCode.sessionId)
    setHasResend(true)
  }

  return (
    <Container>
      {hasResend && (
        <Alert data-test="code-resent-message" color="success">
          A new code has been resent. It may take up to 2 minutes to receive the
          new code.
        </Alert>
      )}

      <h1>Email Verification</h1>
      <p>
        To help keep your account safe, please enter the verification code that
        was sent to the email address below. It may take up to 2 minutes to
        receive the code.
      </p>
      <p>
        <strong>{maskEmail(email)}</strong>
      </p>

      {error && submitted && (
        <Alert data-test="errorAlert" color="danger">
          {error}
        </Alert>
      )}

      <Formik
        data-test="formik-form"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {formikProps => (
          <EmailVerificationForm
            {...formikProps}
            isMfa={isMfa}
            resendCode={handleResendCode}
            isResendingCode={isResendingCode}
            willRememberDevice={willRememberDevice}
            toggleRememberDevice={() =>
              setWillRememberDevice(!willRememberDevice)
            }
          />
        )}
      </Formik>
      {isEnrollment && <AssistanceLinks showPhone={true} />}
      {isMfa && canGoBack && (
        <div className="text-center pseudolink" onClick={goBack}>
          Receive code by phone instead
        </div>
      )}
    </Container>
  )
}

EmailVerificationPage.propTypes = {
  email: PropTypes.string.isRequired,
  emailVerificationId: PropTypes.string.isRequired,
  sessionId: PropTypes.string,
  enrollmentId: PropTypes.string,
  mfaId: PropTypes.string,
  validateEmailCode: PropTypes.func.isRequired,
  isResendingCode: PropTypes.bool,
  rememberDevice: PropTypes.func,
}

export default EmailVerificationPage
export { initialValues, validationSchema, EmailVerificationPage }
