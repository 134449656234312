import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

const LinkCard = ({ title, link }) => (
  <Card>
    <CardBody>
      <Row>
        <Col className="text-center loan-link">
          <a
            className="text-primary"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}{' '}
          </a>
        </Col>
      </Row>
    </CardBody>
  </Card>
)

export default LinkCard
