import features, { off, dev, qa, prod } from './features'

const { REACT_APP_ENVIRONMENT, NODE_ENV } = process.env

const environmentEnabled = (currentEnv, env) => {
  if (!currentEnv) {
    return false
  }

  switch (currentEnv) {
    case dev:
      return env !== off
    case qa:
      return env === prod || env === qa
    case prod:
      return env === prod
    default:
      return false
  }
}

const featureMap = {}

const init = currentEnv => {
  Object.getOwnPropertyNames(features).forEach(name => {
    featureMap[name] = environmentEnabled(currentEnv, features[name])
  })
}

init(REACT_APP_ENVIRONMENT)

class Feature {
  constructor(featureName) {
    this.featureName = featureName
  }

  static named = featureName => new Feature(featureName)

  static enabled = featureName => Feature.named(featureName).enabled

  static get LOCAL() {
    return NODE_ENV === 'development' && Feature.DEV
  }

  static get DEV() {
    return Feature.named(dev).enabled
  }

  static get QA() {
    return Feature.named(qa).enabled
  }

  static get PROD() {
    return Feature.named(prod).enabled
  }

  get name() {
    return this.featureName
  }

  /* Checks whether a feature is enabled depending on the current environment.
   * If they feature key is found, then it is evaluated against the current environment do
   * determine whether it is enabled. If the feature key doesn't exist, it is considered
   * PROD scoped and will always be enabled. This allows for the features to be removed without
   * affecting the behavior of the application
   */
  get enabled() {
    const featureEnabled = featureMap[this.featureName]
    return typeof featureEnabled === 'boolean' ? featureEnabled : true
  }
}

export default Feature
export { init }
