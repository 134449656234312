import React from 'react'
import PropTypes from 'prop-types'
import { Query, graphql } from 'react-apollo'
import { AUTHENTICATION_QUERY } from 'authentication/queries'
import authService from 'authentication/service'

const mapProps = ({ data, error: gqlError = null }) => {
  const { authentication = {} } = data
  const {
    email,
    adminEmail,
    isAuthenticated = false,
    isAuthenticating = false,
    isLegacyAuthenticated = false,
    isAdmin = false,
    isReturningCustomer = false,
    error,
    boCode,
    firstName,
    lastName,
    isEnrollment,
    customBrand,
    customerType,
    stateCode,
    channel,
  } = authentication
  const { signIn, signOut, singleSignOn, authAs } = authService
  return {
    email,
    adminEmail,
    isAuthenticated,
    isAuthenticating,
    isLegacyAuthenticated,
    isAdmin,
    isReturningCustomer,
    error: error || gqlError,
    boCode,
    signIn,
    signOut,
    singleSignOn,
    authAs,
    firstName,
    lastName,
    isEnrollment,
    customBrand,
    customerType,
    stateCode,
    channel,
  }
}

const WithAuth = ({ children, ...rest }) => (
  <Query query={AUTHENTICATION_QUERY}>
    {props => {
      const childProps = mapProps(props)

      return children({
        ...childProps,
        ...rest,
      })
    }}
  </Query>
)

WithAuth.propTypes = {
  children: PropTypes.func.isRequired,
}

export const withAuth = component =>
  graphql(AUTHENTICATION_QUERY, {
    props: authProps => ({ authProps }),
  })(component)

export default WithAuth
