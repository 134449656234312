import { conformToMask } from 'react-text-mask'

const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
const ssnMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
const getConformedMask = (text, mask, defaultValue = null, config = {}) =>
  text ? conformToMask(text, mask, config).conformedValue : defaultValue

const maskEmail = email => {
  const lastIndex = email.lastIndexOf('@')
  const domainLastIndex = email.lastIndexOf('.')
  const prefix = email.substring(0, lastIndex)
  const domain = email.substring(lastIndex, domainLastIndex)
  const postfix = email.substring(domainLastIndex)
  const mask = '*'.repeat(prefix.length - 2)
  const domainMask = '*'.repeat(domain.length - 3)

  return `${prefix.substring(0, 1)}${mask}${prefix.substring(
    prefix.length - 1
  )}${domain.substring(0, 2)}${domainMask}${domain.substring(
    domain.length - 1
  )}${postfix}`
}

const maskBankNumber = number => {
  return `${'*'.repeat(number.length - 4)}${number.substring(
    number.length - 4
  )}`
}

export {
  maskEmail,
  phoneMask,
  ssnMask,
  dateMask,
  getConformedMask,
  maskBankNumber,
}
