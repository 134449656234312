import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { emailResendCode } from './graphql/mutations'

const EmailResendCodeMutation = ({ children, ...rest }) => {
  return (
    <Mutation mutation={emailResendCode}>
      {(emailResendCode, { _data, _error, loading: isResendingCode }) =>
        children({ emailResendCode, isResendingCode, ...rest })
      }
    </Mutation>
  )
}

EmailResendCodeMutation.propTypes = {
  children: PropTypes.func.isRequired,
}

export default EmailResendCodeMutation
