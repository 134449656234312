import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Form, Card, Alert, Button } from 'reactstrap'
import AuthFormHeader from '../AuthFormHeader'
import { InputField } from 'shared/components'
import schema from './validation'

const ChangePasswordForm = ({ onSubmit, errorMessage, processing }) => (
  <Formik
    initialValues={{
      newPassword: '',
      confirmPassword: '',
    }}
    validationSchema={schema}
    onSubmit={onSubmit}
    render={({ handleSubmit: formikSubmit, isValid }) => {
      return (
        <Card body className="auth-block mt-5 mb-3">
          <AuthFormHeader headerMessage="Enter your new password." />
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          <Form onSubmit={formikSubmit}>
            <InputField
              type="password"
              name="newPassword"
              title="New Password"
            />
            <InputField
              type="password"
              name="confirmPassword"
              title="Confirm New Password"
            />
            <Button
              id="change_password_form--button-processing"
              className={processing ? '' : 'd-none'}
              block
              type="submit"
              color="primary"
              size="lg"
              disabled
            >
              <i className="fas fa-spinner fa-spin" />
              &nbsp;Processing...
            </Button>

            <Button
              id="change_password_form--button-change_password"
              className={processing ? 'd-none' : ''}
              block
              type="submit"
              color="primary"
              size="lg"
              disabled={!isValid || (errorMessage && errorMessage.length > 0)}
            >
              Change Password {errorMessage === undefined}
            </Button>
          </Form>
        </Card>
      )
    }}
  />
)

ChangePasswordForm.propTypes = {
  errorMessage: PropTypes.string,
  processing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default ChangePasswordForm
