export const PLAID_ERROR_CODE = {
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  NO_ACCOUNTS: 'NO_ACCOUNTS',
  ITEM_LOGIN_REQUIRED: 'ITEM_LOGIN_REQUIRED',
  UNDEFINED: 'undefined',
}

export const PLAID_ERROR_STATUS = ['requires_credentials']

export const PLAID_EVENT = {
  ERROR: 'ERROR',
  EXIT: 'EXIT',
  HANDOFF: 'HANDOFF',
  OPEN: 'OPEN',
}
