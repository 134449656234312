import React from 'react'
import PropTypes from 'prop-types'
import { MapBodyParagraph } from '../ModalUtilities'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const DeclineConfirmationModal = ({
  body,
  cancelText,
  declineText,
  header,
  isOpen,
  modalId = 'decline-confirmation-modal',
  onClose,
  onDecline,
  showCannotUndo = false,
  size,
}) => {
  return (
    <Modal isOpen={isOpen} size={size || 'md'} backdrop="static">
      <ModalHeader data-test={`${modalId}-header`} className="my-0">
        {header}
      </ModalHeader>
      <ModalBody>
        <MapBodyParagraph body={body} />{' '}
        {showCannotUndo && (
          <span className="font-weight-bold">
            This action cannot be undone.
          </span>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          id="decline-confirmation--button-close"
          color="outline-primary"
          data-test="buttons.reject"
          onClick={onClose}
        >
          {cancelText || 'Cancel'}
        </Button>

        <Button
          id="decline-confirmation--button-decline"
          color="danger"
          data-test="buttons.submit"
          onClick={onDecline}
        >
          {declineText || 'Decline'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DeclineConfirmationModal.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  cancelText: PropTypes.string,
  declineText: PropTypes.string,
  header: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  showCannotUndo: PropTypes.bool,
  size: PropTypes.string,
}

export default DeclineConfirmationModal
