import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import LoginPageComponent from './LoginPage'

const LoginPage = props => {
  const { unifiedHomepages, authentication = {}, redirectToP360 } = useFlags()
  return (
    <LoginPageComponent
      {...props}
      unifiedHomepages={unifiedHomepages}
      isMfaEnabled={authentication.mfa}
      redirectToP360={redirectToP360}
    />
  )
}

export default LoginPage
