import React from 'react'
import PropTypes from 'prop-types'
import { CardText, CardImg, Col } from 'reactstrap'
import Branding from 'shared/branding'

const AuthFormHeader = ({ headerMessage }) => {
  const { loginLogo } = Branding.current

  return (
    <React.Fragment>
      <CardImg top src={loginLogo} alt="Logo" />
      {loginLogo.indexOf('Powered') >= 0 ? (
        <Col xs={7} className="p-0">
          <CardText className="auth-block info-text">{headerMessage}</CardText>
        </Col>
      ) : (
        <CardText>{headerMessage}</CardText>
      )}
    </React.Fragment>
  )
}

AuthFormHeader.propTypes = {
  headerMessage: PropTypes.string.isRequired,
}

export default AuthFormHeader
