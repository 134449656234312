import { CreateConversionDenialMutation } from 'application/graphql/mutations'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'

const ConversionDenialMutation = ({ children, ...rest }) => {
  return (
    <Mutation mutation={CreateConversionDenialMutation}>
      {(createConversionDenial, { _data, _error, _loading }) =>
        children({ createConversionDenial, ...rest })
      }
    </Mutation>
  )
}

ConversionDenialMutation.propTypes = {
  children: PropTypes.func.isRequired,
}

export default ConversionDenialMutation
