import React from 'react'
import PropTypes from 'prop-types'

import { LoadingScreen } from 'shared/components'
import {
  LoanConversionOptions,
  LoanConvert,
  LoanRefinance,
} from 'loan/components'

class LoanConversionContainer extends React.Component {
  state = {
    underwritingComplete: false,
    underwriting: false,
  }

  handleUnderwriting = async (customerCode, option) => {
    const { preliminaryUnderwriting, onUpdateProgress } = this.props
    this.setState({ underwriting: true })

    const result = await preliminaryUnderwriting({
      variables: { customerCode },
    })

    if (result.data.preliminaryUnderwriting.status === 'APPROVED') {
      this.setState({ underwritingComplete: true })
      onUpdateProgress(option)
    }
  }

  componentDidMount = () => {
    const { conversionOffers, customerCode } = this.props
    const { underwriting } = this.state

    if (
      conversionOffers &&
      conversionOffers.conversionTypes &&
      conversionOffers.conversionTypes.length === 1 &&
      !underwriting
    ) {
      this.handleUnderwriting(customerCode, conversionOffers.conversionTypes[0])
    }
  }

  handleConversionSelection = offer => {
    const { customerCode } = this.props
    this.handleUnderwriting(customerCode, offer)
  }

  render() {
    const { conversionOffers, selectedOffer } = this.props
    const { underwriting, underwritingComplete } = this.state

    if (conversionOffers && conversionOffers.conversionTypes && !underwriting) {
      if (conversionOffers.conversionTypes.length > 1) {
        return (
          <LoanConversionOptions onAuthorize={this.handleConversionSelection} />
        )
      }
      return null
    }

    if (underwriting) {
      if (underwritingComplete) {
        if (selectedOffer === 'CONVERT') {
          return <LoanConvert />
        } else {
          return <LoanRefinance />
        }
      } else {
        return <LoadingScreen>Initial underwriting</LoadingScreen>
      }
    }
  }
}

LoanConversionContainer.propTypes = {
  preliminaryUnderwriting: PropTypes.func.isRequired,
  customerCode: PropTypes.string.isRequired,
  conversionOffers: PropTypes.object.isRequired,
  onUpdateProgress: PropTypes.func.isRequired,
  selectedOffer: PropTypes.string,
}

export default LoanConversionContainer
