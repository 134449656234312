import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { currency, isQfundRelease } from 'shared/utils'
import XactLoanDisclosure from './XactLoanDisclosure'
import CaliforniaNotice from 'shared/components/Footer/CaliforniaNotice'
import Branding from 'shared/branding'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import { FooterLinks } from 'shared/components'
import PatriotActDisclosure from './PatriotActDisclosure'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { APPLICATION_PATHS } from 'constants/paths'
import XactIlpTermFooter from './XactILPTermFooter'

const Footer = ({
  copyrightYear,
  footerLinks,
  stateCode,
  showClp,
  showIlp,
  isEpp,
  showBank,
  showStandardArticles,
}) => {
  const { hideHawaiiFooter, hideKyIaFooter } = useFlags()

  let counter = 1

  const qfundRelease = isQfundRelease()
  
  const californiaDepartment = isEpp
    ? 'California Department of Financial Protection and Innovation'
    : 'California Department of Business Oversight'

  const retailStates = isEpp
    ? `Alabama, Delaware, Idaho, , ${hideKyIaFooter ? '' : 'Iowa,'} ${hideKyIaFooter ? '' : 'Kentucky,'
    } Michigan, Missouri, Oklahoma, South Carolina, Texas, Utah and Wisconsin`
    : `Alabama,  Delaware, Idaho,  ${hideKyIaFooter ? '' : 'Iowa,'} ${hideKyIaFooter ? '' : 'Kentucky,'
    } Michigan, Missouri, Oklahoma, South Carolina, Texas, Utah and Wisconsin`

  return (
    <footer role="contentinfo" id="footer" className="mt-5">
      <Container>
        <FooterLinks footerLinks={footerLinks} />

        <h3
          data-test="text.online-disclosures-heading"
          id="online-disclosures-header"
        >
          Online Disclosures
        </h3>

        <PatriotActDisclosure />

        <div data-test="etc.online-disclosures" id="footer-content">
          <p>
            <strong>Customer Notice:</strong> Payday Loans are typically for
            two-to four-week terms. Some borrowers, however, use Payday Loans
            for several months, which can be expensive. Payday Loans (also
            referred to as Payday Advances, Cash Advances, Deferred Deposit
            Transactions/Loans) and high-interest loans should be used for
            short-term financial needs only and not as a long-term financial
            solution. Customers with credit difficulties should seek credit
            counseling before entering into any loan transaction. See{' '}
            <a
              title="Check `n Go State Center"
              href="https://www.checkngo.com/resources/state-center/"
              target="_blank"
              rel="noopener noreferrer"
            >
              State Center
            </a>{' '}
            for specific information and requirements.
          </p>
          <p>
            {' '}
            {showStandardArticles && (
              <React.Fragment>
                California licensed by the {californiaDepartment} pursuant to
                the California Deferred Deposit Transaction Law.{' '}
              </React.Fragment>
            )}
            Texas loans originate by a third party lender, restrictions apply.
            Delaware licensed lender by the Delaware State Banking Commissioner,
            expires 12/31/
            {copyrightYear}.
          </p>
          <p>
            Check `n Go is not a direct lender in Texas, where instead it acts
            in the capacity as a Credit Services Organization.
          </p>

          <p data-test="test.disclosures.standardExample">
            {showStandardArticles && !isEpp && `${counter++}. `}

            {qfundRelease ? (
              <>
                Approval depends upon meeting legal, regulatory and underwriting requirements. All times and dates are based on Eastern Time (ET). If your loan is approved and originated by 8:00 p.m. ET on a business day,
                your funds will typically become available on the next business day. The lender may, at their discretion, verify application information by using national databases that may provide information from one or
                more national credit bureaus, and the lender may take that into consideration in the approval process.
              </>
            ) : (
                <>
                  Approval depends upon meeting legal, regulatory and underwriting requirements. Online applications completed and approved prior to 8 pm (ET) can
                  have funds as soon as the next business day. Retail applications completed online can obtain a "Conditional Approval" at any time. Retail locations can
                  provide funds, upon approval, during regular business hours. Check{' '}
                  <a
                    title="Check `n go locations"
                    href="https://locations.checkngo.com/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://locations.checkngo.com/index.html
                  </a>{' '}
                  for detail on store information. All times and dates are based on Eastern Time (ET). Online amounts may vary by state.
                  Please see the website for details. All products and service options are subject to change without notice. Check `n Go may report customer loan information
                  to one or more credit reporting agencies. Late payments, missed payments and other events of default may be reflected in customer credit reports. See checkngo.com for details.
                </>
              )}
          </p>

          {showBank && showStandardArticles && (
            <XactIlpTermFooter
              counter={counter++}
              examples={showBank.xactExamples}
              data-test="test.disclosures.xactExample"
            />
          )}

          {showClp && showStandardArticles && !qfundRelease && (
            <p data-test="test.disclosures.clpExample">
              {counter++}. Actual loan amount and terms will vary based on final
              evaluation of income and credit qualifications. Payment amounts
              vary by loan term and rate. As an example, if you borrow{' '}
              {currency(showClp.exampleBorrow, 0)} with a {showClp.exampleAPR}%
              Annual Percentage Rate (APR) and a {showClp.exampleTerm} month
              term, your payments would be{' '}
              {currency(showClp.examplePaymentAmount, 2)} per month. APRs range
              from [{showClp.aprLow}% - {showClp.aprHigh}
              %]. APRs will vary depending on credit qualifications, loan amount
              and term.{' '}
              {stateCode === 'CA' && (
                <span>
                  California loans range from {currency(showClp.minLoan)} to{' '}
                  {currency(showClp.maxLoan)}. See{' '}
                  <a
                    title="Check `n Go State Center"
                    href="https://www.checkngo.com/resources/state-center/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    State Center
                  </a>{' '}
                  for more information
                </span>
              )}
            </p>
          )}

          {showClp && showStandardArticles && qfundRelease && (
            <p data-test="test.disclosures.clpExample">
              {counter++}. Approval depends upon meeting legal, regulatory and
              underwriting requirements. Online applications completed and
              approved prior to 8 pm (ET) can have funds as soon as the next
              business day. Retail applications completed online can obtain a
              "Conditional Approval" at any time. Retail locations can provide
              funds, upon approval, during regular business hours. Check{' '}
              <a
                title="Check `n go locations"
                href="https://locations.checkngo.com/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://locations.checkngo.com/index.html
              </a>{' '}
              for detail on store information. All times and dates are based on
              Eastern Time (ET). Online amounts may vary by state. Please see
              the website for details. All products and service options are
              subject to change without notice. Check `n Go may report customer
              loan information to one or more credit reporting agencies. Late
              payments, missed payments and other events of default may be
              reflected in customer credit reports. See <a
              href="checkngo.com" title="Check 'n go"
              target="_blank">checkngo.com</a> for
              details.
            </p>
          )}
          {showClp && showStandardArticles && qfundRelease && (
            <p data-test="test.disclosures.ilpExample">
              {counter++}. Actual loan amount and terms will vary based on final
              evaluation of income and credit qualifications. Payment amounts
              vary by loan term and rate.
            </p>
          )}
          {showIlp && showStandardArticles && (
            <p data-test="test.disclosures.ilpExample">
              {counter++}. Actual loan amount and terms will vary based on final
                  evaluation of income and credit qualifications. Payment amounts
              vary by loan term and rate. As an example, if you borrow{' '}
                  {currency(showIlp.exampleBorrow, 0)} with a {showIlp.exampleAPR}%
              Annual Percentage Rate (APR) and a {showIlp.exampleTerm} month
              term, your payments would be{' '}
                  {currency(showIlp.examplePaymentAmount, 2)} per month. APRs range
              from [{showIlp.aprLow}% - {showIlp.aprHigh}
              %]. APRs will vary depending on payment schedule.{' '}
              {stateCode === 'CA' && (
                <span>
                  California loans range from {currency(showIlp.minLoan)} to{' '}
                  {currency(showIlp.maxLoan)}. See{' '}
                  <a
                    title="Check `n Go State Center"
                    href="https://www.checkngo.com/resources/state-center/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    State Center
                  </a>{' '}
                  for more information
                </span>
              )}
            </p>
          )}

          <p>
            Check `n Go and third parties verify application information by
            using national databases that may provide information from one or
            more national credit bureaus, and Check `n Go or third parties may
            take that into consideration in the approval process.
          </p>

          <p>We do not report credit in Texas.</p>

          <p>
            Products or services offered to customers may vary based on customer
            eligibility and applicable state or federal law. All available
            products subject to applicable lender’s terms and conditions. Actual
            loan amounts vary. See{' '}
            <a
              title="Check `n Go State Center"
              href="https://www.checkngo.com/resources/state-center/"
              target="_blank"
              rel="noopener noreferrer"
            >
              State Center
            </a>{' '}
            for specific information and requirements.
          </p>

          {window.location.pathname === APPLICATION_PATHS.ACCOUNT && (
            <p>*Active checking account open for at least 30 days.</p>
          )}

          <p>
            ** Check `n Go doesn&apos;t charge you for using text messages (SMS)
            for two-factor authentication. However, your mobile provider&apos;s
            standard rates for sending and receiving messages may apply.
          </p>
          <p>
            &dagger; By providing your telephone number, including any mobile
            number, you consent that we may call you at these numbers for
            servicing and marketing telephone calls.
          </p>

          {showBank && <XactLoanDisclosure />}

          <p>
            Check `n Go currently operates online in: Alabama, California,
            Delaware, Florida, {hideHawaiiFooter ? '' : 'Hawaii,'} Idaho,
            Indiana, Kansas, Maine, Michigan, Missouri, North Dakota, Oklahoma,
            South Carolina, Texas, Utah, Wisconsin, and Wyoming.
          </p>
          <p>
            Check `n Go currently operates in store locations in: {retailStates}
            .
          </p>

          {showStandardArticles && (
            <CaliforniaNotice
              strong={false}
              brand={Branding.getBrand(ONLINE_STORE_BRAND_NAME.CNG).name}
            />
          )}

          <span data-test="text.copyright">
            &copy; {copyrightYear} Check `n Go. All rights reserved.
            <address>PO Box 36454, Cincinnati, OH 45236</address>
          </span>
        </div>
      </Container>
    </footer>
  )
}

Footer.displayName = 'Footer'
Footer.propTypes = {
  copyrightYear: PropTypes.number.isRequired,
  stateCode: PropTypes.string,
  showIlp: PropTypes.object,
  showClp: PropTypes.object,
  showBank: PropTypes.object,
  footerLinks: PropTypes.array,
}

export default Footer
