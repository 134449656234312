import React from 'react'
import { Route } from 'react-router-dom'
import { WithAuth, SSOInterceptor } from '..'

const LoginRouteSSO = ({ ...rest }) => (
  <WithAuth>
    {({ singleSignOn }) => (
      <Route
        {...rest}
        render={props => (
          <SSOInterceptor singleSignOn={singleSignOn} {...props} />
        )}
      />
    )}
  </WithAuth>
)

export default LoginRouteSSO
