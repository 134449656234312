import React from 'react'
import PropTypes from 'prop-types'
import { Collapse, Button } from 'reactstrap'

class Toggle extends React.Component {
  static propTypes = {
    expand: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    children: PropTypes.node.isRequired,
  }

  state = {
    collapsed: true,
  }

  toggleCollapse = () => {
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }))
  }

  renderExpanded() {
    const { children, expand } = this.props

    return <div className={`d-none d-${expand}-block`}>{children}</div>
  }

  renderCollapsed() {
    const { children, expand } = this.props
    const { collapsed } = this.state

    return (
      <div className={`mb-3 d-${expand}-none`}>
        <Button
          id="toggle--button-collapse_expand"
          color="primary"
          className="w-100"
          onClick={this.toggleCollapse}
        >
          <i className="fas fa-bars" /> Menu
        </Button>
        <Collapse isOpen={!collapsed}>{children}</Collapse>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderExpanded()}
        {this.renderCollapsed()}
      </React.Fragment>
    )
  }
}

export default Toggle
