import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row } from 'reactstrap'
import StorePhone from './StorePhone'
import Branding from 'shared/branding'

const FormatAddress = ({
  addressLine1,
  addressLine2,
  city,
  postalCode,
  state,
}) => {
  return (
    <span>
      {addressLine1}
      {!!addressLine2 ? ` ${addressLine2}` : ''}
      {', '}
      {city}, {state} {postalCode}
    </span>
  )
}

const FormatFriendlyName = ({ storeBrand, storeName }) => {
  const storeBrandMapping = Branding.getBrand(storeBrand)
  return (
    <span className="pseudo-header mb-0">
      {storeBrandMapping ? storeBrandMapping.friendlyName : 'Check `n Go'}{' '}
      {storeName}
    </span>
  )
}

const YourStoreAddress = ({
  addressLine1,
  addressLine2,
  city,
  googleDirectionsUri,
  phone,
  postalCode,
  state,
  storeName,
  brand: storeBrand,
  isConditionalApproval,
}) => {
  return (
    <React.Fragment>
      <Row className="mt-2">
        <Col md="8">
          {storeName && (
            <Row>
              <Col>
                <FormatFriendlyName
                  storeBrand={storeBrand}
                  storeName={storeName}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormatAddress
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                city={city}
                postalCode={postalCode}
                state={state}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <StorePhone phoneNumber={phone} />
            </Col>
          </Row>
        </Col>

        {!isConditionalApproval && googleDirectionsUri && (
          <Col md="4" className="mt-3 mb-2 text-right d-none d-sm-inline">
            <Button
              id="your-store-address--button-get_directions"
              color="success"
              onClick={() => {
                window.open(googleDirectionsUri)
              }}
              data-test="button.getDirections"
            >
              Get Directions
            </Button>
          </Col>
        )}
      </Row>
      {(isConditionalApproval || googleDirectionsUri) && (
        <Row className={!isConditionalApproval ? 'd-sm-none d-inline' : ''}>
          <Col className="mt-3">
            <Button
              id="your-store-details--button-get_directions"
              color="success"
              onClick={() => {
                window.open(googleDirectionsUri)
              }}
              data-test="button.getDirections"
              className="w-100"
            >
              Get Directions
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

YourStoreAddress.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
    })
  ),
  phone: PropTypes.string,
  showPartner: PropTypes.bool,
  storeName: PropTypes.string,
  isConditionalApproval: PropTypes.bool,
}

export default YourStoreAddress
