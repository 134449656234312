import React from 'react'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'

const IncorrectEmailMessage = ({
  isOpen,
  showRedirectToEnroll = false,
  setRedirectToEnroll = () => { },
}) => {

  if (!isOpen) {
    return <></>
  }

  return (<Alert color="danger" data-test="error">
    <div>
      <p>
        We couldn’t find an email address matching the
        information you entered. Please review and try
        again.
       </p>
      {showRedirectToEnroll && (
        <span
          className="red-alert-link"
          onClick={() => setRedirectToEnroll(true)}
        >
          Don't have an account? Enroll Now
        </span>
      )}
    </div>
  </Alert>)
}

IncorrectEmailMessage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showRedirectToEnroll: PropTypes.bool,
  setRedirectToEnroll: PropTypes.func
}

export default IncorrectEmailMessage
