import React from 'react'
import PropTypes from 'prop-types'

const GOOGLE_MAPS_URI = 'https://maps.googleapis.com/maps/api/js'

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env

class MapDiv extends React.Component {
  render() {
    const { minHeight } = this.props

    return <div id="map" style={{ minHeight: `${minHeight}px` }} />
  }

  shouldComponentUpdate() {
    return false
  }

  static propTypes = {
    minHeight: PropTypes.number,
  }

  static defaultProps = {
    minHeight: 500,
  }
}

class GoogleMaps extends React.Component {
  state = {
    mapsLoading: true,
  }

  map = null

  markers = []

  componentDidMount() {
    if (!window.google || !window.google.maps) {
      this.mapsScript = document.createElement('script')
      this.mapsScript.setAttribute(
        'src',
        `${GOOGLE_MAPS_URI}?key=${REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
      this.mapsScript.onload = () => {
        this.setState({ mapsLoading: false })
      }
      document.head.appendChild(this.mapsScript)
    } else {
      this.setState({ mapsLoading: false })
    }
  }

  componentWillUnmount() {
    if (this.mapsScript) {
      this.mapsScript.onload = () => {}
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { selectedStore, storeSearchResults } = this.props
    const { mapsLoading } = this.state

    if (!nextState.mapsLoading && mapsLoading) {
      return true
    }

    if (storeSearchResults !== nextProps.storeSearchResults) {
      return true
    }

    if (
      nextProps.selectedStore &&
      nextProps.selectedStore.storeNumber !==
        (selectedStore ? selectedStore.storeNumber : '')
    ) {
      return true
    }

    return false
  }

  componentDidUpdate() {
    const {
      selectedStore,
      googleMapsUri,
      storeSearchResults,
      onStoreSelect,
    } = this.props
    const { mapsLoading } = this.state
    const google = window.google

    if (!mapsLoading && google) {
      let center = {
          lat: 38,
          lng: -97,
        },
        zoom = 4

      if (selectedStore) {
        center = {
          lat: selectedStore.latitude,
          lng: selectedStore.longitude,
        }

        zoom = 16
      } else if (storeSearchResults && storeSearchResults.length) {
        center = storeSearchResults
          .map(({ longitude: lng, latitude: lat }) => ({ lat, lng }))
          .reduce(
            (acc, curr) => ({
              lng: acc.lng + curr.lng,
              lat: acc.lat + curr.lat,
            }),
            { lat: 0, lng: 0 }
          )

        center = {
          lng: center.lng / storeSearchResults.length,
          lat: center.lat / storeSearchResults.length,
        }
        zoom = 10
      }

      if (center) {
        const map = new google.maps.Map(document.getElementById('map'), {
          center,
          zoom,
        })

        this.map = map

        let stores = storeSearchResults
        if ((!stores || !stores.length) && selectedStore) {
          stores = [selectedStore]
        }

        if (stores && stores.length) {
          this.markers.forEach(marker => {
            google.maps.event.clearListeners(marker, 'click')
          })
          this.markers = []
          stores.forEach(store => {
            const marker = new google.maps.Marker({
              position: { lng: store.longitude, lat: store.latitude },
              map,
              title: store.addressLine1,
            })

            if (
              selectedStore &&
              selectedStore.storeNumber === store.storeNumber
            ) {
              const infoWindow = new google.maps.InfoWindow({
                content: `<div>
              <div><strong>Check \`n Go</strong></div>
              <div>${store.addressLine1}</div>
              <div>${store.addressLine2 || ''}</div>
              <div>${store.city}, ${store.state}  ${store.postalCode}</div>
              <div>
                <a target="_blank" noref nofollow href="${googleMapsUri}">View on Google Maps</a>
              </div></div>`,
              })
              marker.addListener('click', () => {
                infoWindow.open(map, marker)
              })
            } else if (onStoreSelect && typeof onStoreSelect === 'function') {
              marker.addListener('click', () => {
                onStoreSelect(store)
              })
            }

            this.markers.push(marker)
          })
        }
      }
    }
  }

  render() {
    const { mapsLoading } = this.state

    if (mapsLoading) {
      return <div>Loading map</div>
    }

    return <MapDiv {...this.props} />
  }

  static propTypes = {
    selectedStore: PropTypes.shape({
      storeNumber: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
    }),
    googleMapsUri: PropTypes.string,
    storeSearchResults: PropTypes.array,
    onStoreSelect: PropTypes.func,
  }
}

export default GoogleMaps
