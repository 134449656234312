import React from 'react'
import PropTypes from 'prop-types'

const DocumentLocation = ({ documentLocation, title }) => {
  return (
    <a href={documentLocation} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  )
}

DocumentLocation.propTypes = {
  documentLocation: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default DocumentLocation
