import React from 'react'

import Branding from 'shared/branding'

const BrandLogo = () => {
  const { logoImage, name } = Branding.current
  const alt = `${name} logo`

  return (
    <img
      src={logoImage}
      data-test="images.company-logo"
      alt={alt}
      className="branded-account-header"
    />
  )
}

BrandLogo.displayName = 'BrandLogo'

export default BrandLogo
