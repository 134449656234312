import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { withApollo } from 'react-apollo'
import PageView from 'shared/PageView'
import { MFA_VIRTUAL_PATHS } from 'constants/paths'
import RememberDeviceMutation from './RememberDeviceMutation'
import getBlackbox from 'shared/Iovation'

const RememberDeviceModal = ({ applicationId, authProps }) => {
  const [disabled, setDisabled] = useState(false)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    firePageView(MFA_VIRTUAL_PATHS.REMEMBER_DEVICE)()
  }, [])

  const onAccept = action => async () => {
    setDisabled(true)
    if (action)
      await action({ variables: { applicationId, blackBox: getBlackbox() } })
    setIsOpen(false)
  }

  const onDecline = () => {
    setIsOpen(false)
  }

  const onToggle = () => {
    if (!disabled) setIsOpen(!isOpen)
  }

  const firePageView = (path, action) => () => {
    PageView.firePageView(path)
    if (action) action()
  }

  return (
    <RememberDeviceMutation>
      {({ rememberDevice, data, error }) => {
        if (error) return null

        return (
          <Modal
            toggle={firePageView(
              MFA_VIRTUAL_PATHS.REMEMBER_DEVICE_DECLINE,
              onToggle
            )}
            isOpen={isOpen}
            keyboard={true}
            centered={true}
            backdrop="static"
          >
            <ModalHeader
              toggle={firePageView(
                MFA_VIRTUAL_PATHS.REMEMBER_DEVICE_DECLINE,
                onToggle
              )}
            >
              Remember my device
            </ModalHeader>

            <ModalBody className="pt-3" data-test="modal.body">
              <p>
                You have the option to allow us to remember this device. This
                will save you time the next time you log in.
              </p>
              <p>
                For your protection, choose this option only on your personal
                devices that you trust are secure. You should not use this
                option on a shared or public device.
              </p>
              <Button
                id="remember_device--button-remember"
                color="primary"
                className="pocket360-button w-100"
                data-test="button.rember_device.remember"
                onClick={firePageView(
                  MFA_VIRTUAL_PATHS.REMEMBER_DEVICE_ACCEPT,
                  onAccept(rememberDevice)
                )}
                disabled={disabled}
              >
                Remember this device
              </Button>
              <Button
                id="remember_device--button-do-not-remember"
                color="secondary"
                className="pocket360-outline-button my-3 w-100"
                data-test="button.rember_device.do-not-remember"
                onClick={firePageView(
                  MFA_VIRTUAL_PATHS.REMEMBER_DEVICE_DECLINE,
                  onDecline
                )}
                disabled={disabled}
              >
                Do not Remember this device
              </Button>
            </ModalBody>
          </Modal>
        )
      }}
    </RememberDeviceMutation>
  )
}

RememberDeviceModal.propTypes = {
  applicationId: PropTypes.string.isRequired,
  authProps: PropTypes.object.isRequired,
}

export default withApollo(RememberDeviceModal)
