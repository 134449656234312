import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const WelcomeBack = ({ isLegacy, isOpen, onClose, onLogin, firstName }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader data-test="welcome-header" toggle={onClose}>
        Welcome Back {firstName}
      </ModalHeader>
      <ModalBody>
        {isLegacy && (
          <p>
            As a returning customer, apply even faster with our express
            application. Click the link below to log in and get started.
          </p>
        )}

        {!isLegacy && (
          <p>
            We saved your progress the last time you visited, please log in to
            resume your application.
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          id="welcome_back--button-cancel"
          color="primary"
          outline
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          id="welcome_back--button-log_in"
          color="primary"
          onClick={onLogin}
        >
          LOG IN
        </Button>
      </ModalFooter>
    </Modal>
  )
}

WelcomeBack.propTypes = {
  isLegacy: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default WelcomeBack
