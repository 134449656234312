import React from 'react'
import { Table } from 'reactstrap'
import { saveError } from 'shared/sentry'
import { currency } from 'shared/utils'

export default function XactIlpTermFooter({ counter, examples }) {
  if (!examples) {
    saveError('XactILPTermFooter: No examples provided')
    return null
  }

  const { terms, minApr, maxApr } = examples
  return (
    <>
      <p>
        {counter}. Actual loan amount and terms will vary based on final
        evaluation and credit qualifications. Payment amounts vary by rate. The
        Xact® Loan has either an{' '}
        {terms
          .map(({ term }) => `${term}-month`)
          .join(', ')
          .replace(/,([^,]*)$/, ' or$1')}{' '}
        term, depending on consumer qualifications. The{' '}
        {terms
          .filter(({ returningRefiOnly }) => returningRefiOnly)
          .map(({ term }) => `${term}-month`)
          .join(', ')
          .replace(/,([^,]*)$/, ' and$1')}{' '}
        term may only be offered to qualifying returning or refinancing
        consumers. The table below provides illustrative examples for each
        available term length.
      </p>

      <Table
        bordered
        size="sm"
        responsive
        id="xactTableTerm"
        style={{ fontSize: '0.625rem', borderTop: '1px solid #d8d8d8' }}
      >
        <thead>
          <tr>
            <th>Term Length</th>
            <th>Principal Balance</th>
            <th>Annual Percentage Rate (APR)</th>
            <th>Installment Payments</th>
            <th>Total of Payments</th>
          </tr>
        </thead>
        <tbody>
          {terms.map((example, index) => (
            <tr key={index}>
              <td>{example.term}-month</td>
              <td>{currency(example.principal, 0)}</td>
              <td>{example.apr}%</td>
              <td>{example.installmentPayments}</td>
              <td>{currency(example.totalPayments, 2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <p>
        There are no application, origination, late payment, or returned payment
        fees. APRs range from [{minApr}% - {maxApr}%]. The maximum APR on any
        Xact® Loan is {maxApr}%. APRs will vary depending on credit
        qualifications and loan amount.
      </p>
    </>
  )
}
