export const INCORRECT_BRAND_LOGIN = 'Incorrect Brand'
export const YES = 'Y'
export const NO = 'N'

export const TRUE = 'TRUE'
export const FALSE = 'FALSE'

export const MONDAY = 'MON'
export const TUESDAY = 'TUES'
export const WEDNESDAY = 'WED'
export const THURSDAY = 'THURS'
export const FRIDAY = 'FRI'
export const SATURDAY = 'SAT'
export const SUNDAY = 'SUN'
export const WEEK = {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export const JANUARY = 'JAN'
export const FEBRUARY = 'FEB'
export const MARCH = 'MAR'
export const APRIL = 'APR'
export const MAY = 'MAY'
export const JUNE = 'JUN'
export const JULY = 'JUL'
export const AUGUST = 'AUG'
export const SEPTEMBER = 'SEP'
export const OCTOBER = 'OCT'
export const NOVEMBER = 'NOV'
export const DECEMBER = 'DEC'
export const YEAR = {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export const FIRST = 'F'
export const SECOND = 'S'
export const THIRD = 'T'
export const SWING = 'W'

export const DIRECT_DEPOSIT = 'DD'
export const PAPER_CHECK = 'NDD'
export const AUTO_PAY = 'EFT'
export const CASH = 'CASH'

export const EFT = 'EFT'
export const MAIL = 'MAIL'

export const WEEKLY = 'WK'
export const EVERY_TWO_WEEKS = 'BI'
export const TWICE_PER_MONTH = 'BIM'
export const MONTHLY = 'MON'

export const EMPLOYED = 'E'
export const SOCIAL_SECURITY = 'S'
export const DISABILITY_INCOME = 'D'
export const PENSION = 'PN'

export const PUBLIC_ASSISTANCE = 'A'
export const FULL_TIME_EMPLOYED = 'F'
export const PART_TIME_EMPLOYED = 'PE'
export const UNEMPLOYMENT_BENEFITS = 'UB'
export const RETIREMENT = 'R'
export const CHECKING = 'CHECKING'
export const SAVINGS = 'SAVINGS'

export const EARLIEST_EMPLOYMENT_START_YEAR = 1960
export const MINIMUM_AGE = 18

export const CELL = 'C'
export const HOME = 'H'
export const FAX = 'F'

export const PERSONAL = 'P'
export const WORK = 'W'

export const OWN = 'OWN'
export const RENT = 'RENT'
export const OT = 'OTH'

export const SSN = 'SSN'
export const PASSPORT = 'PP'
export const ITIN = 'ITIN'
export const AMERICAN_INDIAN_TRIBAL = 'AT'
export const RESIDENT_ALIEN_CARD = 'RC'

export const DRIVERS_LICENSE = 'DL'
export const STATE_ISSUED = 'SI'

export const NEW = 'NEW'
export const EXISTING = 'EXISTING'
export const REFINANCE = 'REFINANCE'

export const OMNI_RETURNING = 'OMNI_RETURNING'
export const ONLINE_RETURNING = 'ONLINE_RETURNING'
export const RETAIL_RETURNING = 'RETAIL_RETURNING'

export const ACH = 'ACH'
export const RCC = 'RCC'

export const CNG = 'CNG'
export const ACA = 'ACA'
export const CCB = 'CCB'
export const P360 = 'P360'
export const POCKET360 = 'POCKET360'
export const POWERED_BY = 'POWERED_BY'
export const XACT_BRAND = 'CCB'

export const THIRTY = '30'
export const OTHER = 'OTHER'
export const ALL = 'ALL'

export const RETAIL = 'RETAIL'
export const ONLINE = 'ONLINE'

export const PASS = 'Pass'
export const FAIL = 'Fail'
export const NA = 'NA'
export const NULL = 'null'

export const CHECKNGO = 'CHECKNGO'
export const CHECKNGOSTORES = 'CHECKNGOSTORES'

export const UNSUBMITTED = 'UNSUBMITTED'
export const SUBMITTED = 'SUBMITTED'

export const APPROVED = 'APPROVED'
export const DENIED = 'DENIED'

export const PAYDAY_LOAN = 'PDL'
export const INSTALLMENT_LOAN = 'ILP'
export const CHOICE_LOAN = 'CLP'
export const CHOICE_LOAN_NEW ='CLP_NEW'
export const DUAL_ONLINE = 'DUAL'
export const RETAIL_ILP = 'RETAIL_ILP'
export const BANK_ILP = 'BANK_ILP'
export const SELECT_LOAN = 'SLP'
export const REGISTRATION_LOAN = 'RLP'

export const TWELVE_MONTHS = 12
export const EIGHT_MONTHS = 8
export const FOUR_MONTHS = 4

export const RETAIL_AND_ONLINE = 'RETAIL_AND_ONLINE'

export const FIRST_PET = 'What is the name of your first pet?'
export const FIRST_KISS = 'Who was your first kiss?'
export const DREAM_JOB = 'What is your dream job?'
export const MEET_SPOUSE = 'Where did you meet your spouse?'
export const SIBLING_NICKNAME = "What is your sibling's nickname?"

export const OTP_VALID = 'OTP_VALID'
export const OTP_INVALID = 'OTP_INVALID'
export const OTP_EXPIRED = 'OTP_EXPIRED'
export const OTP_EXCEEDED = 'OTP_EXCEEDED'

export const CANEBAY_DUPLICATE = 'CANEBAY_DUPLICATE'
export const SUBMIT_APPROVED = 'SUBMIT_APPROVED'
export const SUBMIT_DENIED = 'SUBMIT_DENIED'
export const SUBMIT_ERROR = 'SUBMIT_ERROR'
export const SUBMITTING = 'SUBMITTING'

export const ESIGN_ERROR = 'ESIGN_ERROR'
export const ESIGNING = 'ESIGNING'
export const ESIGN_RECALCULATED = 'ESIGN_RECALCULATED'
export const ORIGINATED = 'ORIGINATED'

export const COMPLETED_PRODUCT_SELECTION = 'COMPLETED_PRODUCT_SELECTION'
export const PENDING_ASSET_REPORT_DOWNLOAD = 'PENDING_ASSET_REPORT_DOWNLOAD'
export const PENDING_BRFR = 'PENDING_BRFR'
export const PENDING_ESIGN = 'PENDING_ESIGN'
export const PENDING_ESIGN_VALIDATIONS = 'PENDING_ESIGN_VALIDATIONS'
export const PENDING_ESIGN_DOCUMENT_UPLOAD = 'PENDING_ESIGN_DOCUMENT_UPLOAD'
export const PENDING_ORIGINATION = 'PENDING_ORIGINATION'
export const PENDING_PRODUCT_SELECTION = 'PENDING_PRODUCT_SELECTION'
export const PENDING_SAS_CALL_1 = 'PENDING_SAS_CALL_1'
export const PENDING_UNDERWRITING = 'PENDING_UNDERWRITING'

export const ERROR = 'ERROR'

export const ANSWERS = {
  YES,
  NO,
}

export const APPLICATION_STATES = {
  CANEBAY_DUPLICATE,
  COMPLETED_PRODUCT_SELECTION,
  DENIED,
  ESIGN_ERROR,
  ESIGNING,
  ORIGINATED,
  PENDING_ASSET_REPORT_DOWNLOAD,
  PENDING_BRFR,
  PENDING_ESIGN,
  PENDING_ESIGN_VALIDATIONS,
  PENDING_ESIGN_DOCUMENT_UPLOAD,
  PENDING_ORIGINATION,
  PENDING_PRODUCT_SELECTION,
  PENDING_SAS_CALL_1,
  PENDING_UNDERWRITING,
  SUBMITTING,
  SUBMIT_APPROVED,
  SUBMIT_DENIED,
  SUBMIT_ERROR,
}

export const BRANDS = {
  CCB,
  CNG,
  ACA,
  P360,
  POCKET360,
  POWERED_BY,
  XACT_BRAND
}

export const BRAND_VALUES = {
  XACT: 'XACT',
  CNG: 'CNG',
  P360: 'P360',
  ACA: 'ACA',
}

export const TRUE_FALSE = {
  TRUE,
  FALSE,
}

export const DAYS = {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export const JOB_SHIFT = {
  FIRST,
  SECOND,
  THIRD,
  SWING,
  OTHER: 'O',
}

export const PAYCHECK_FORMAT = {
  DIRECT_DEPOSIT,
  PAPER_CHECK,
  CASH,
}

export const LOAN_PAYMENT_METHOD = {
  EFT,
  MAIL,
}

export const PAYCHECK_FREQUENCY = {
  WEEKLY,
  EVERY_TWO_WEEKS,
  TWICE_PER_MONTH,
  MONTHLY,
}

export const INCOME_TYPE = {
  EMPLOYED,
  SOCIAL_SECURITY,
  DISABILITY_INCOME,
  PENSION,
  UNEMPLOYMENT_BENEFITS,
  OTHER: 'O',
}

export const EMPLOYMENT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const BANK_ACCOUNT_STATUS = {
  ACTIVE: 'A',
  INACTIVE: 'I',
}

export const BANK_ACCOUNT_TYPE = {
  CHECKING,
  SAVINGS,
}

export const PHONE_TYPE = {
  CELL,
  HOME,
  FAX,
  WORK,
  OTHER: 'O',
}

export const EMAIL_ACCOUNT_TYPE = {
  PERSONAL,
  WORK,
}

export const HOUSING_OWNERSHIP = {
  OWN,
  RENT,
  OT: 'OTHER',
}

export const NATIONAL_ID_TYPE = {
  SSN,
  PASSPORT,
  ITIN,
  AMERICAN_INDIAN_TRIBAL,
  RESIDENT_ALIEN_CARD,
}

export const STATE_ID_TYPE = {
  DRIVERS_LICENSE,
  STATE_ISSUED,
}

export const CUSTOMER_TYPE = {
  NEW,
  EXISTING,
  REFINANCE,
}

export const CUSTOMER_TYPE_FOR_AUTH = {
  NEW,
  OMNI_RETURNING,
  ONLINE_RETURNING,
  RETAIL_RETURNING,
}

export const LOAN_DISBURSEMENT_TYPE = {
  ACH,
  RCC,
}

export const ONLINE_STORE_BRAND_NAME = {
  CNG,
  ACA,
  CCB,
  P360,
}

export const MARKETING_SOURCE = {
  THIRTY,
  ALL,
  OTHER: 'OTHER',
}

export const STORE_NUMBER = {
  ONLINE: '2997',
}

export const CAMPAIGN_ID = {
  CNG: {
    ONLINE: 1,
  },
  ACA: {
    ONLINE: 20349,
  },
  CCB: {
    ONLINE: 1,
  },
  P360: {
    ONLINE: 1,
  },
}

export const SEO_CAMPAIGN_IDS = {
  CNG: {
    GOOGLE: 2500,
    YAHOO: 3500,
    BING: 4500,
  },
  ACA: {
    GOOGLE: 30783,
    YAHOO: 30803,
    BING: 30804,
  },
  CCB: {
    GOOGLE: 2500,
    YAHOO: 3500,
    BING: 4500,
  },
  P360: {
    GOOGLE: 2500,
    YAHOO: 3500,
    BING: 4500,
  },
}

export const LOAN_APPLICATION_ORIGINATION_CHANNEL = {
  ONLINE,
  RETAIL,
}

export const OTP_VALIDATION_RESULT = {
  PASS,
  FAIL,
  NA,
  NULL,
}

export const LOAN_APPLICATION_SOURCE = {
  CHECKNGO,
  CHECKNGOSTORES,
}

export const LOAN_APPLICATION_SUBMISSION_STATUS = {
  UNSUBMITTED,
  SUBMITTED,
}

export const LOAN_APPLICATION_SUBMISSION_RESPONSE = {
  APPROVED,
  DENIED,
  ERROR,
}

export const LOAN_APPLICATION_PRODUCT_TYPE = {
  PAYDAY_LOAN,
  INSTALLMENT_LOAN,
  CHOICE_LOAN,
  DUAL_ONLINE,
  RETAIL_ILP,
  BANK_ILP,
  SELECT_LOAN,
  REGISTRATION_LOAN,
}

export const LOAN_APPLICATION_TERM_LENGTH = {
  TWELVE_MONTHS,
  EIGHT_MONTHS,
  FOUR_MONTHS,
}

export const LOAN_CHECK_STATUS_CODES = {
  ACH: 'ACH',
  ACH_CLEAR: 'ACH_CLEAR',
  ACH_DEPOSIT: 'ACH_DEPOSIT',
  ACH_RETURN: 'ACH_RETURN',
  BANKRUPT: 'BANKRUPT',
  CASH_BUY_BACK: 'CASH_BUY_BACK',
  CLEAR: 'CLEAR',
  CLOSED: 'CLOSED',
  CURE_PERIOD: 'CURE_PERIOD',
  CURRENT: 'CURRENT',
  DEFAULT: 'DEFAULT',
  DEFAULT_PAID_IN_FULL: 'DEFAULT_PAID_IN_FULL',
  DELINQUENT: 'DELINQUENT',
  DEPOSIT: 'DEPOSIT',
  HOLD: 'HOLD',
  HOLD_DEBIT_CARD: 'HOLD_DEBIT_CARD',
  OPEN: 'OPEN',
  PAID: 'PAID',
  PAID_IN_FULL: 'PAID_IN_FULL',
  PAYMENT_DUE: 'PAYMENT_DUE',
  PAYMENT_PLAN: 'PAYMENT_PLAN',
  REFINANCE: 'REFINANCE',
  REPO: 'REPO',
  RETURN: 'RETURN',
  SETTLED_IN_FULL: 'SETTLED_IN_FULL',
  SOLD: 'SOLD',
  SOLD_RECALL_CLOSE: 'SOLD_RECALL_CLOSE',
  VOID: 'VOID',
  WRITTEN_OFF: 'WRITTEN_OFF',
}

export const LOAN_STATUS = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  VOID: 'VOID',
}

export const ORIGINATIONS_OFFERED_IN_STATE = {
  ONLINE,
  RETAIL,
  RETAIL_AND_ONLINE: 'RETAIL_AND_ONLINE',
}

export const SECURITY_QUESTIONS = {
  FIRST_PET,
  FIRST_KISS,
  DREAM_JOB,
  MEET_SPOUSE,
  SIBLING_NICKNAME,
}

export const LEGACY_DESTINATIONS = {
  PRODUCT_SELECTION: 'PRODUCT_SELECTION',
  LANDING: 'LANDING',
  REFINANCE: 'REFINANCE',
  THANKYOU: 'THANKYOU',
  PENDING_REFINANCE: 'PENDING_REFINANCE',
  PENDING_EPP: 'PENDING_EPP',
}

export const APPLICATION_TYPES = {
  CONVERSION: 'CONVERSION',
  LOAN_MODIFICATION: 'LOAN_MODIFICATION',
  REFINANCE: 'REFINANCE',
  STANDARD: 'STANDARD',
  EPP: 'EPP',
}

export const COGNITO_GROUPS = {
  CSR: 'my.account.csr',
  ENROLLMENT: 'enrollment.user',
  USER: 'my.account.user',
}

export const COGNITO_SOURCE = {
  CNG: 'cng.myaccount',
  ACA: 'alliedcash.myaccount',
  CCB: 'cng.myaccount',
  ANGULAR: 'angularSubmitApplication',
  LEGACY: 'legacySubmitApplication',
  ENROLLMENT: 'enrollment',
}

export const DISCLOSURE_SECTIONS = {
  AGREEMENT: 'AGREEMENT',
  INCOME: 'INCOME',
  BANK: 'BANK',
}

export const LENDER_CODE = {
  CCB: { title: 'CCBank' },
  CNG: { title: 'Check `n Go' },
  ACA: { title: 'Allied Cash Advance' },
  AXC: { title: 'Axcess Credit' },
}

export const BRAND_CODE = {
  CCB: { title: 'Xact' },
  CNG: { title: 'Check `n Go' },
  ACA: { title: 'Allied Cash Advance' },
}

export const LOG_LEVELS = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  LOG: 'LOG',
}

/** Values for ACCOUNT_HOLDER_STATE and its behavior:
 *  Empty Array = Enables Account Holder Name for all the states
 *  List of STATE CODES = Enables the Account Holder Name for the stateCodes in the list (array.some)
 *  Undefined or null = DISABLES the Account Holder Name for all the sates
 */
export const ACCOUNT_HOLDER_STATES = ['CA']

export const SOURCE_SYSTEMS = {
  ONLINE: 'STARS3X',
  RETAIL: 'STARS4X',
  CONNECTIONS: 'CONNECTIONS',
  LOANPRO: 'LOANPRO',
  QFUND: 'QFUND',
}

export const CHANNEL = {
  RETAIL:"RETAIL"
}

export const LOANPRO_SUBSTATUS = {
  CONTRACT_PENDING_SIGNATURE: 'CONTRACT_PENDING_SIGNATURE',
  REPAYING: 'REPAYING',
  ATTOURNEY_RETAINED: 'ATTOURNEY_RETAINED',
  FRAUD_PROCESS: 'FRAUD_PROCESS',
  DECEASED_PROCESS: 'DECEASED_PROCESS',
  CHARGEDOFF_COLLECTABLE: 'CHARGEDOFF_COLLECTABLE',
  PENDING_PAYOFF: 'PENDING_PAYOFF',
  BANKRUPTCY_PROCESS: 'BANKRUPTCY_PROCESS',
  CHARGED_OFF: 'CHARGED_OFF',
  BANKRUPTCY: 'BANKRUPTCY',
  RECISSION: 'RECISSION',
  VOIDED: 'VOIDED',
  SOLD: 'SOLD',
  REFINANCE: 'REFINANCE',
  PAID_IN_FULL: 'PAID_IN_FULL',
  SETTLED_IN_FULL: 'SETTLED_IN_FULL',
  //QFUND
  WRITTEN_OFF: 'WRITTEN_OFF',
  DEFAULT: 'DEFAULT'
}

export const RECONNECT_APP_STATES = [
  'ESIGNING',
  'PENDING_ESIGN_VALIDATIONS',
  'PENDING_ESIGN_DOCUMENT_UPLOAD',
  'PENDING_ORIGINATION',
]

export const TRUSTPILOT_BUSINESS_UNIT_IDS = {
  CCB: '60b80863f86baa0001ba4f0e',
  CNG: '544aaf9600006400057b1fc4',
}
export const VALID_INCOME_SOURCE = ['E','O','D','S','SELF_EMPLOYED']

export const MAP_INCOME_TYPE = {
  CNG: {
    ONLINE: {
      E: 'E',
      SELF_EMPLOYED: 'SELF_EMPLOYED',
      ALIMONY_CHILD_SUPPORT: 'O',
      SOCIAL_SECURITY: 'S',
      DISABILITY: 'D',
      NOT_TO_DISCLOSE: 'O',
      NA: 'O',
      O: 'O'
    },
    RETAIL: {
      E: 'E',
      SELF_EMPLOYED: 'SELF_EMPLOYED',
      ALIMONY_CHILD_SUPPORT: 'O',
      SOCIAL_SECURITY: 'S',
      DISABILITY: 'D',
      NOT_TO_DISCLOSE: 'O',
      NA: 'O'
    },
  },
  CCB: {
    E: 'E',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    ALIMONY_CHILD_SUPPORT: 'O',
    SOCIAL_SECURITY: 'S',
    DISABILITY: 'D',
    NOT_TO_DISCLOSE: 'O',
    NA: 'O'
  },
  ACA: {
    E: 'E',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    ALIMONY_CHILD_SUPPORT: 'O',
    SOCIAL_SECURITY: 'S',
    DISABILITY: 'D',
    NOT_TO_DISCLOSE: 'O',
    NA: 'O'
  }
}

