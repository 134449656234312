import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Branding from 'shared/branding'

const AuthorizeCheck = ({ isOpen, onClose, onContinue }) => {
  const { name } = Branding.current
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader data-test="authorization-header" toggle={onClose}>
        Authorization
      </ModalHeader>
      <ModalBody>
        <p>
          I authorize {name} and any third-party lender for which {name} is a
          broker to send my information to a credit reporting agency.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          id="authorize_check--button-cancel"
          color="primary"
          outline
          onClick={onClose}
          data-test="buttons.authorize.close"
        >
          Cancel
        </Button>
        <Button
          id="authorize_check--button-continue"
          color="primary"
          onClick={onContinue}
          data-test="buttons.authorize.submit"
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AuthorizeCheck.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AuthorizeCheck
