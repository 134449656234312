import gql from 'graphql-tag'

const validateEmailCode = gql`
  mutation validateEmailVerificationCode(
    $emailVerificationId: ID!
    $verificationCode: String!
    $sessionId: String
    $enrollmentId: ID
    $mfaId: ID
  ) {
    validateEmailVerificationCode(
      emailVerificationId: $emailVerificationId
      verificationCode: $verificationCode
      sessionId: $sessionId
      enrollmentId: $enrollmentId
      mfaId: $mfaId
    ) {
      success
      status
    }
  }
`

const emailResendCode = gql`
  mutation emailResendCode($enrollmentId: ID, $mfaId: ID) {
    emailResendCode(enrollmentId: $enrollmentId, mfaId: $mfaId) {
      success
      sessionId
    }
  }
`

export default {
  emailResendCode,
  validateEmailCode,
}
export { emailResendCode, validateEmailCode }
