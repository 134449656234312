import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { LoadingScreen } from 'shared/components'
import { withPendingAppData } from 'application/graphql/PendingAppData'
import authService from 'authentication/service'
import { GLOBAL_PATHS } from 'constants/paths'

class ApplicationDataCaptureComponent extends React.PureComponent {
  state = {
    initializing: true,
  }

  constructor(props) {
    super(props)
    authService.initialize().then(() => {
      this.setState({ initializing: false })
    })
  }

  render = () => {
    const { initializing } = this.state
    const {
      authProps,
      queryProps,
      pendingApplicationProps,
      render,
    } = this.props
    const isLoadingAddress =
      initializing ||
      (queryProps && queryProps.data.loading) ||
      (pendingApplicationProps && pendingApplicationProps.data.loading)

    if (isLoadingAddress) {
      return <LoadingScreen inline />
    }

    if (pendingApplicationProps && pendingApplicationProps.data.error) {
      return <Redirect to={GLOBAL_PATHS.ERROR} />
    }

    return (
      <ApplicationDataCapture
        pendingApplicationProps={pendingApplicationProps}
        render={render}
        authProps={authProps.data.authentication}
      />
    )
  }

  static propTypes = {
    render: PropTypes.func.isRequired,
    authProps: PropTypes.object.isRequired,
    queryProps: PropTypes.object,
    pendingApplicationProps: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      data: PropTypes.shape({
        application: PropTypes.shape({
          customerInformation: PropTypes.shape({
            contactInformation: PropTypes.shape({
              address: PropTypes.shape({
                addressLine1: PropTypes.string.isRequired,
                addressLine2: PropTypes.string,
                cityName: PropTypes.string.isRequired,
                stateCode: PropTypes.string.isRequired,
                zipCode: PropTypes.string.isRequired,
              }),
            }),
          }),
        }),
      }),
    }),
  }
}

class ApplicationDataCapture extends React.PureComponent {
  state = {
    capturingData: true,
    address: null,
  }

  componentDidMount = () => {
    this.captureApplicationData()
  }

  captureApplicationData = () => {
    const { address } = this.state
    if (!address) {
      const { pendingApplicationProps } = this.props
      if (
        pendingApplicationProps &&
        !pendingApplicationProps.loading &&
        pendingApplicationProps.data &&
        pendingApplicationProps.data.application &&
        pendingApplicationProps.data.application.customerInformation &&
        pendingApplicationProps.data.application.customerInformation
          .contactInformation
      ) {
        this.setState({
          appId: pendingApplicationProps.data.application.id,
          appType: pendingApplicationProps.data.application.applicationType,
          address:
            pendingApplicationProps.data.application.customerInformation
              .contactInformation.address,
          capturingData: false,
        })
      } else {
        this.setState({ capturingData: false })
      }
    }
  }

  render = () => {
    const { render } = this.props
    const { address, appId, appType, capturingData } = this.state

    if (capturingData) {
      return <LoadingScreen inline>Capturing Application Data</LoadingScreen>
    }
    return render({ address, appId, appType })
  }

  static propTypes = {
    render: PropTypes.func.isRequired,
    pendingApplicationProps: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        application: PropTypes.shape({
          customerInformation: PropTypes.shape({
            contactInformation: PropTypes.shape({
              address: PropTypes.shape({
                addressLine1: PropTypes.string.isRequired,
                addressLine2: PropTypes.string,
                cityName: PropTypes.string.isRequired,
                stateCode: PropTypes.string.isRequired,
                zipCode: PropTypes.string.isRequired,
              }),
            }),
          }),
        }),
      }),
    }),
  }
}

export default withPendingAppData(ApplicationDataCaptureComponent)
