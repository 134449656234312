import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { WithAuth, LoginPage } from '..'
import { BrandingCheck } from 'shared/components'

const LoginRoute = rest => (
  <WithAuth>
    {({
      isAuthenticated,
      isAuthenticating,
      isLegacyAuthenticated,
      isEnrollment,
      error,
      signIn,
      singleSignOn,
      signOut,
    }) => (
      <Route
        {...rest}
        render={props => (
          <React.Fragment>
            <BrandingCheck />
            <LoginPage
              {...props}
              signIn={signIn}
              singleSignOn={singleSignOn}
              signOut={signOut}
              isAuthenticated={isAuthenticated}
              isAuthenticating={isAuthenticating}
              isLegacyAuthenticated={isLegacyAuthenticated}
              isEnrollment={isEnrollment}
              error={error}
            />
          </React.Fragment>
        )}
      />
    )}
  </WithAuth>
)

LoginRoute.propTypes = {
  admin: PropTypes.bool,
}

export default LoginRoute
