import React from 'react'
import PropTypes from 'prop-types'
import EmailVerificationPage from './EmailVerificationPage'
import EmailVerificationQuery from './EmailVerificationQuery'
import VerifyEmailCodeMutation from './VerifyEmailCodeMutation'
import EmailResendCodeMutation from './EmailResendCodeMutation'
import { PageView } from 'shared/components'
import { ENROLLMENT_PATHS, MFA_VIRTUAL_PATHS } from 'constants/paths'

const EmailVerification = props => {
  const { enrollmentId, mfaId, canGoBack, goBack } = props

  const getPathByFlow = () =>
    enrollmentId
      ? ENROLLMENT_PATHS.EMAIL_VERIFICATION
      : mfaId
      ? MFA_VIRTUAL_PATHS.MFA_EMAIL
      : ''

  return (
    <React.Fragment>
      <PageView pageUrl={getPathByFlow()} />
      <EmailVerificationQuery enrollmentId={enrollmentId} mfaId={mfaId}>
        {queryprops => (
          <VerifyEmailCodeMutation>
            {({ validateEmailCode }) => (
              <EmailResendCodeMutation>
                {({ emailResendCode, isResendingCode }) => (
                  <EmailVerificationPage
                    {...queryprops}
                    validateEmailCode={validateEmailCode}
                    emailResendCode={emailResendCode}
                    isResendingCode={isResendingCode}
                    canGoBack={canGoBack}
                    goBack={goBack}
                    {...props}
                  />
                )}
              </EmailResendCodeMutation>
            )}
          </VerifyEmailCodeMutation>
        )}
      </EmailVerificationQuery>
    </React.Fragment>
  )
}

EmailVerification.propTypes = {
  enrollmentId: PropTypes.string,
  mfaId: PropTypes.string,
  email: PropTypes.string.isRequired,
  updateState: PropTypes.func,
  rememberDevice: PropTypes.func,
  canGoBack: PropTypes.bool,
  goBack: PropTypes.func,
}

export default EmailVerification
