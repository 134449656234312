import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import { ssnMask, phoneMask, dateMask } from 'shared/Masks'

class Masked extends React.Component {
  static removeFormatting = inputValue =>
    inputValue ? inputValue.replace(/[ ()-/_]/g, '') : inputValue

  handleChange = event => {
    const data = { ...this.props }
    if (
      Masked.removeFormatting(event.target.value) ||
      Masked.removeFormatting(data.value) ||
      event.target.value === ''
    ) {
      if (event.target.value === '' || data.field) {
        data.field.onChange(event)
      } else {
        data.onChange(data.id, event.target.value)
      }
    }
  }

  render() {
    const {
      nopaste,
      name,
      field,
      masktype,
      customChange,
      onBlur,
      ...props
    } = this.props
    const { onBlur: fieldBlur } = field
    return (
      <MaskedInput
        name={field.name || name}
        {...field}
        {...props}
        invalid={props.invalid ? 'invalid' : ''}
        mask={
          masktype === 'id'
            ? ssnMask
            : masktype === 'phone'
            ? phoneMask
            : dateMask
        }
        className={
          props.invalid
            ? 'masked-input form-control invalid-input is-invalid'
            : 'masked-input form-control'
        }
        onChange={
          customChange && typeof customChange === 'function'
            ? e => {
                customChange(e)
                this.handleChange(e)
              }
            : this.handleChange
        }
        data-test={`fields.${field.name || name}`}
        onBlur={e => {
          if (onBlur && typeof onBlur === 'function') {
            e.target.value = Masked.removeFormatting(e.target.value)
            onBlur(e)
          }
          if (fieldBlur && typeof fieldBlur === 'function') {
            fieldBlur(e)
          }
        }}
        onPaste={e => {
          if (nopaste) {
            e.preventDefault()
          }
        }}
      />
    )
  }
}

Masked.propTypes = {
  name: PropTypes.string,
  field: PropTypes.object,
  masktype: PropTypes.string,
  customChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default Masked
