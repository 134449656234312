import React from 'react'
import PropTypes from 'prop-types'
import PartnerSiteRedirect from '.'
import { useFlags } from 'launchdarkly-react-client-sdk'


const PartnerSiteRedirectWrapper = ({ brand, redirectToBrand, authProps, isAppRedirect, isInline }) => {
  const { enableXact, redirectToP360 } = useFlags()

  return (
    <PartnerSiteRedirect
      brand={brand}
      redirectToBrand={redirectToBrand}
      authProps={authProps}
      enableXact={enableXact}
      redirectToP360={redirectToP360}
      isAppRedirect={isAppRedirect}
      location={document.location}
      isInline={isInline}
    />
  )
}

PartnerSiteRedirectWrapper.propTypes = {
  brand: PropTypes.string,
  isInline: PropTypes.bool,
  redirectToBrand: PropTypes.string,
  authProps: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
  }),
  isAppRedirect: PropTypes.bool,
}

export default PartnerSiteRedirectWrapper
