import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { DefaultModal, LoadingScreen } from 'shared/components'
import { APPLICATION_PATHS } from 'constants/paths'

class ConversionDenialModal extends React.Component {
  state = {
    conversionDenied: false,
    submitted: false,
  }

  processConversionDenial = () => {
    this.setState({ submitted: true })
    const { applicationId, createConversionDenial } = this.props
    createConversionDenial({
      variables: { id: applicationId },
    }).then(() => {
      this.setState({ conversionDenied: true })
    })
  }

  render() {
    const { conversionDenied, submitted } = this.state
    const { isOpen } = this.props

    if (conversionDenied) {
      return <Redirect to={APPLICATION_PATHS.ADVERSE_ACTION} />
    }

    if (submitted) {
      return <LoadingScreen />
    }

    return (
      <DefaultModal
        isOpen={isOpen}
        continueText="OK"
        onContinue={this.processConversionDenial}
        onClose={this.processConversionDenial}
        header={'Conversion option is no longer available.'}
        body={
          'The conversion option you applied for is no longer available.  Click OK to return to Pocket360®.'
        }
      />
    )
  }
}

ConversionDenialModal.propTypes = {
  applicationId: PropTypes.string.isRequired,
  createConversionDenial: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default ConversionDenialModal
