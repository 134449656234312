import React from 'react'
import PropTypes from 'prop-types'
import LoanPaymentSchedule from './LoanPaymentSchedule'
import { currency } from 'shared/utils'

const LoanConversionSummary = props => {
  const {
    selectedAmount,
    conversionDetails,
    conversionOptions,
    showSchedule,
    showNewLoanDetails,
  } = props

  const loanFeeAmount = () =>
    conversionOptions.loanFeeAmount
      ? conversionOptions.loanFeeAmount
      : conversionOptions.payoffAmount

  const calculateFinanceCharge = () => {
    return selectedAmount * (1 + conversionOptions.apr) - selectedAmount
  }

  const payFrequencyToDays = frequency => {
    switch (frequency) {
      case 'WK':
        return 7
      case 'BI':
        return 14
      case 'BIM':
        return 15
      case 'MON':
        return 30
      default:
        return 0
    }
  }

  const calculatePaymentSchedule = () => {
    const paymentAmount =
      (selectedAmount * (1 + conversionOptions.apr)) /
      conversionOptions.numberOfPayments
    let schedule = []

    const payFrequencyDays = payFrequencyToDays(
      conversionOptions.paymentFrequency
    )

    let loanDate = conversionOptions.newDueDate
    let principlePercent = 0
    let currentBalance = selectedAmount + calculateFinanceCharge()

    for (var i = 0; i < 7; i++) {
      schedule.push({
        dueDate: loanDate,
        paymentAmount: currency(paymentAmount, 2),
        interest: currency(paymentAmount * (1 - principlePercent), 2),
        principle: currency(paymentAmount * principlePercent, 2),
        balance: currency(currentBalance, 2),
      })

      currentBalance -= paymentAmount
      principlePercent += 0.05

      let currentDate = new Date(Date.parse(loanDate))
      currentDate.setDate(currentDate.getDate() + payFrequencyDays)
      loanDate = `${currentDate.getMonth() +
        1}/${currentDate.getDate()}/${currentDate.getFullYear()}`
    }

    return schedule
  }

  return (
    <div>
      <h3 className="mb-0">Installment Loan Summary</h3>
      <p className="mb-0">
        <strong>Cash back to you</strong>
      </p>

      <div className="d-flex">
        <span>Loan amount requested</span>
        <span className="ml-auto">
          <strong>{currency(selectedAmount, 2)}</strong>
        </span>
      </div>

      <div className="d-flex">
        {conversionOptions.loanDueDate && (
          <span>Less fees due on {conversionOptions.loanDueDate}</span>
        )}
        {!conversionOptions.loanDueDate &&
          conversionDetails &&
          conversionDetails.loanNumber && (
            <span>
              Remaining balance due loan {conversionDetails.loanNumber}
            </span>
          )}
        <span className="ml-auto">
          <strong>-{currency(loanFeeAmount(), 2)}</strong>
        </span>
      </div>

      <hr />

      <div className="d-flex mb-3">
        <span>Total you will receive</span>
        <span className="ml-auto">
          <strong>{currency(selectedAmount - loanFeeAmount(), 2)}</strong>
        </span>
      </div>

      {showNewLoanDetails && (
        <React.Fragment>
          <p className="mb-0">
            <strong>New Loan Details</strong>
          </p>

          <div className="d-flex">
            <span>New loan amount</span>
            <span className="ml-auto">
              <strong>{currency(selectedAmount, 2)}</strong>
            </span>
          </div>

          <div className="d-flex">
            <span>New finance charge</span>
            <span className="ml-auto">
              <strong>+{currency(calculateFinanceCharge(), 2)}</strong>
            </span>
          </div>

          <div className="d-flex mb-4">
            <span>Total amount due</span>
            <span className="ml-auto">
              <strong>
                {currency(selectedAmount + calculateFinanceCharge(), 2)}
              </strong>
            </span>
          </div>
        </React.Fragment>
      )}

      {showSchedule && (
        <LoanPaymentSchedule paymentSchedule={calculatePaymentSchedule()} />
      )}
    </div>
  )
}

LoanConversionSummary.propTypes = {
  conversionDetails: PropTypes.shape({
    loanNumber: PropTypes.string,
    loanType: PropTypes.string,
    payoffAmount: PropTypes.number,
  }),
  conversionOptions: PropTypes.oneOfType([
    PropTypes.shape({
      paymentFrequency: PropTypes.string.isRequired,
      loanDueDate: PropTypes.string.isRequired,
      loanFeeAmount: PropTypes.number.isRequired,
      newDueDate: PropTypes.string.isRequired,
      numberOfPayments: PropTypes.number.isRequired,
      apr: PropTypes.number.isRequired,
    }),
    PropTypes.shape({
      availableDueDates: PropTypes.array,
      dueDate: PropTypes.string,
      incrementAmount: PropTypes.number.isRequired,
      loanNumber: PropTypes.string.isRequired,
      maxLoan: PropTypes.number.isRequired,
      minLoan: PropTypes.number.isRequired,
      payoffAmount: PropTypes.number.isRequired,
      productCode: PropTypes.string.isRequired,
      qualificationMatrix: PropTypes.array,
    }),
  ]).isRequired,
  selectedAmount: PropTypes.number.isRequired,
  showSchedule: PropTypes.bool.isRequired,
  showNewLoanDetails: PropTypes.bool.isRequired,
}

export default LoanConversionSummary
