import { APPLICATION_PATHS } from '../../../constants/paths'

const personalInformationPaths = [
  APPLICATION_PATHS.ACCOUNT,
  APPLICATION_PATHS.PERSONAL
]

const loanDetailsPaths = [
  APPLICATION_PATHS.PRODUCT_PREFERENCE,
  APPLICATION_PATHS.STORE_SELECT
]

const incomePaths = [
  APPLICATION_PATHS.INCOME,
  APPLICATION_PATHS.BANK,
  APPLICATION_PATHS.BANK_MANUAL,
  APPLICATION_PATHS.PLAID_BANK_AVAILABLE,
  APPLICATION_PATHS.PLAID_BANK_CONSENT,
  APPLICATION_PATHS.PLAID_BANK_LAUNCHED,
  APPLICATION_PATHS.PLAID_BANK_VERIFY,
  APPLICATION_PATHS.PLAID_BANK_ACCOUNT_SELECTION,
  APPLICATION_PATHS.PLAID_ERROR_INITIALIZING,
  APPLICATION_PATHS.PLAID_INVALID_CREDENTIALS,
  APPLICATION_PATHS.PLAID_NO_AUTH_PRODUCT,
  APPLICATION_PATHS.PLAID_NO_PRODUCT_DATA,
  APPLICATION_PATHS.PLAID_NO_CHECKING,

]

const finalStepPaths = [
  APPLICATION_PATHS.AGREEMENTS,
  APPLICATION_PATHS.OTP,
  APPLICATION_PATHS.PRODUCTS,
  APPLICATION_PATHS.AMOUNT,
  APPLICATION_PATHS.BANK_VERIFICATION,
  APPLICATION_PATHS.UPLOAD_DOCS,
  APPLICATION_PATHS.ANOTHER_OPTION_SELECTED,
  APPLICATION_PATHS.INTELLICHECK,
  APPLICATION_PATHS.INTELLICHECK_LANDING,
  APPLICATION_PATHS.INTELLICHECK_OPT_OUT,
  APPLICATION_PATHS.ESIGN,
  APPLICATION_PATHS.SUBMIT,
  APPLICATION_PATHS.HOLD_ESIGN
]

const confirmationPaths = [
  APPLICATION_PATHS.THANK_YOU
]

export const breadCrumbSections = [
  {
    name: 'Personal Information',
    paths: personalInformationPaths,
    width: '11em'
  },
  {
    name: 'Loan Details',
    paths: loanDetailsPaths,
    width: '5.9em'
  },
  {
    name: 'Income',
    paths: incomePaths,
    width: 'auto'
  },
  {
    name: 'Final Steps',
    paths: finalStepPaths,
    width: '5.3em'
  },
  {
    name: 'Confirmation',
    paths: confirmationPaths,
    width: '6em'
  }
]
