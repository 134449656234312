const off = 'off'
const dev = 'dev'
const qa = 'qa'
const prod = 'prod'

/* If a feature is set to 'dev', it will only be enabled in dev
 * If a feature is set to 'qa', it will be enabled in dev and qa
 * If a feature is set to 'prod', or can't be found in this feature list,
 *  it will be enabled in dev, qa and prod environments
 */
export default {
  /* A generic flag for experimental code which should not be seen
   * outside of dev
   */
  EXPERIMENTAL: dev,
  /* A generic flag indicating that the feature should be enabled
   * in the future, but not quite yet
   */
  FUTURE: qa,
  /* A generic flag to indicate that the code is ready for prod.
   * This is provided just for completeness and shouldn't actually be used,
   * Instead, just remove the flag to indicate it is ready for production
   */
  RELEASE: prod,
  /*
   * As a generic environment test, an environement resolves to itself, following
   * the above rules, so use 'qa' to check for 'not production'. Feature has a
   * shortcut for all environments: Feature.DEV, Feature.QA and Feature.PROD
   */
  dev,
  qa,
  prod,

  /* Custom features can go here.
   * Just add
   *  FEATURE_NAME: env
   * to this features object to enable
   * it in the application.
   *
   * See feature.js on how to check for features.
   * When features make it into prod, they can be safely
   * removed or explicitly set to 'prod'
   *
   * Set a feature to 'off' to disable in all environments.
   */

  // NEW_FEATURE: env
  CONSOLE_LOGGING: qa,
  DETAILED_MESSAGES: dev,
  LOAN_CONVERSION: dev,
  ASYNC_ENABLED: dev,
}

export { off, dev, qa, prod }
