import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import branding from 'shared/branding'
import {
  ONLINE_STORE_BRAND_NAME,
} from 'constants/values'
import { GLOBAL_PATHS } from 'constants/paths'
import history from 'shared/browserHistory'
import { useFlags } from 'launchdarkly-react-client-sdk'

const AssistanceLinks = ({ showLogin = false, showPhone = false }) => {
  const { phoneNumber } = branding.current
  const { redirectToP360 } = useFlags()

  const goToLogin = () => {
    if (branding.current.key !== ONLINE_STORE_BRAND_NAME.P360 && redirectToP360) {
      window.location.href = process.env.REACT_APP_P360_LOGIN
    } else {
      history.push(GLOBAL_PATHS.LOGIN)
    }

    return
  }

  return (
    <React.Fragment>
      {showLogin && (
        <Row>
          <Col className="pt-3 text-center">
            Already have an account?{' '}
            <span
              className="pseudolink"
              onClick={goToLogin}
              data-test="link.goToLogin"
            >
              Log In
            </span>
          </Col>
        </Row>
      )}

      {showPhone && (
        <Row>
          <Col className={showLogin ? 'text-center' : 'pt-3 text-center'}>
            For assistance call <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

AssistanceLinks.propTypes = {
  showLogin: PropTypes.bool,
  showPhone: PropTypes.bool,
}

export default AssistanceLinks
