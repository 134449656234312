import { initialize } from 'launchdarkly-js-client-sdk'

class LaunchDarklyHelper {
  static launchDarklyClient
  static launchDarklyClientGlobal

  async initialize () {
    const LAUNCH_DARKLY_CLIENT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY
    const LAUNCH_DARKLY_CLIENT_GLOBAL_KEY = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_GLOBAL_KEY
    const context = {
      kind: 'user',
      key: 'boss-web',
    }

    if (!this.launchDarklyClient) {
      this.launchDarklyClient = initialize(LAUNCH_DARKLY_CLIENT_KEY, context)
      await this.launchDarklyClient.waitForInitialization()
    }

    if (!this.launchDarklyClientGlobal) {
        this.launchDarklyClientGlobal = initialize(LAUNCH_DARKLY_CLIENT_GLOBAL_KEY, context)
        await this.launchDarklyClientGlobal.waitForInitialization()
    }
  }

  getFeatureFlag (featureFlag, defaultValue = false) {
    return this.launchDarklyClient.getContext()
      ? this.launchDarklyClient.variation(featureFlag, defaultValue)
      : defaultValue
  }

  getGlobalFeatureFlag (featureFlag, defaultValue = false) {
    return this.launchDarklyClientGlobal.getContext()
      ? this.launchDarklyClientGlobal.variation(featureFlag, defaultValue)
      : defaultValue
  }
}

export default LaunchDarklyHelper
