import { ConversionPreliminaryUnderwriting } from 'loan/graphql/mutations'

import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'

const ConversionUnderwriting = ({ customerCode, children, ...rest }) => {
  return (
    <Mutation mutation={ConversionPreliminaryUnderwriting}>
      {preliminaryUnderwriting =>
        children({ preliminaryUnderwriting, ...rest })
      }
    </Mutation>
  )
}

ConversionUnderwriting.propTypes = {
  children: PropTypes.func.isRequired,
  customerCode: PropTypes.string.isRequired,
}

export default ConversionUnderwriting
