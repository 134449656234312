import React from 'react'
import axios from 'axios'
import moment from 'moment'
import Branding from 'shared/branding'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import Footer from './Footer'
import AlliedFooter from './AlliedFooter'
import CCBankFooter from './CCBankFooter'

const copyrightYear = moment().year()
const { key } = Branding.current
const { REACT_APP_FOOTER_URI } = process.env

class BrandedFooter extends React.PureComponent {
  state = {
    footerLinks: null,
  }

  componentDidMount = () => {
    this.getLinks()
  }

  getLinks = () => {
    return axios.get(`${REACT_APP_FOOTER_URI}/${key}`).then(results => {
      this.setState({ footerLinks: results.data.results })
    })
  }

  render() {
    const { footerLinks } = this.state

    if (key === ONLINE_STORE_BRAND_NAME.ACA) {
      return (
        <AlliedFooter
          copyrightYear={copyrightYear}
          footerLinks={footerLinks}
          {...this.props}
        />
      )
    }

    if (key === ONLINE_STORE_BRAND_NAME.CCB) {
      return (
        <CCBankFooter
          copyrightYear={copyrightYear}
          footerLinks={footerLinks}
          {...this.props}
        />
      )
    }

    return (
      <Footer
        copyrightYear={copyrightYear}
        footerLinks={footerLinks}
        {...this.props}
      />
    )
  }
}

export default BrandedFooter
