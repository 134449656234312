import React from 'react'
import PropTypes from 'prop-types'
import { Container, Col, Row, Button } from 'reactstrap'
import { Helmet, AuthorizeCheck } from 'shared/components'

class LoanConversionOptions extends React.Component {
  state = {
    authorizeOpen: false,
    selectedOption: null,
  }

  handleCancel = () => {
    this.setState({ authorizeOpen: false })
  }

  handleSelection = selectedProduct => {
    this.setState({ authorizeOpen: true, selectedOption: selectedProduct })
  }

  handleSubmit = () => {
    const { selectedOption } = this.state
    const { onAuthorize } = this.props
    onAuthorize(selectedOption)
    this.setState({ authorizeOpen: false })
  }

  render() {
    const { authorizeOpen } = this.state

    return (
      <React.Fragment>
        <div>
          <Helmet brandName="" pageName="Conversion Options" />
          <Container>
            <Row>
              <Col>
                <h1 data-test="text.page-title">
                  Choose the Option That&apos;s Right for You
                </h1>
              </Col>
            </Row>
            <Row>
              <Col lg="6" xl={{ size: 4, offset: 1 }} className="mb-5">
                <h3
                  className="mb-3 text-center"
                  data-test="text.installment-title"
                >
                  Convert into Installment Loan
                </h3>
                <p>
                  Convert your payday loan into an installment loan and you
                  could get:
                </p>
                <ul>
                  <li>More borrowing power</li>
                  <li>More time to repay in managable installments</li>
                  <li>Cash back as soon as the next business day</li>
                </ul>
                <div className="text-center">
                  <Button
                    id="loan_conversion_options--button-convert_my_loan"
                    data-test="buttons.convert"
                    color="primary"
                    onClick={() => {
                      this.handleSelection('CONVERT')
                    }}
                  >
                    Convert My Loan
                  </Button>
                </div>
              </Col>

              <Col lg="6" xl={{ size: 4, offset: 2 }}>
                <h3
                  className="mb-3 text-center"
                  data-test="text.refinance-title"
                >
                  Refinance your Payday Loan
                </h3>
                <p>Refinance your payday loan and you&apos;ll get: </p>
                <ul>
                  <li>More time to repay</li>
                  <li>A lower upcoming payment</li>
                  <li>Cash back as soon as the next business day*</li>
                </ul>
                <div className="text-center">
                  <Button
                    id="loan_conversion_options--button-refinance_now"
                    data-test="buttons.refinance"
                    color="primary"
                    onClick={() => {
                      this.handleSelection('REFINANCE')
                    }}
                  >
                    Refinance Now
                  </Button>
                </div>
                <p className="mt-2">
                  * If you&apos;re eligible to borrow a larger amount than the
                  sum of your existing loan balance and required fees
                </p>
              </Col>
            </Row>

            <AuthorizeCheck
              isOpen={authorizeOpen}
              onContinue={this.handleSubmit}
              onClose={this.handleCancel}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }

  static propTypes = {
    onAuthorize: PropTypes.func.isRequired,
  }
}

export default LoanConversionOptions
