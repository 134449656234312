import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import AuthService from 'authentication/service'
import { LoadingScreen } from 'shared/components'
import { GLOBAL_PATHS, ADMIN_PATHS } from 'constants/paths'

class AuthenticatedRoute extends React.PureComponent {
  state = { initializing: true }

  componentDidMount = () => {
    AuthService.initialize().then(() => {
      this.setState({ initializing: false })
    })
  }

  render = () => {
    const {
      component: Component,
      admin: adminRoute,
      authProps,
      ...rest
    } = this.props

    const { isAuthenticated, isAuthenticating, isAdmin, error } = authProps

    const { initializing } = this.state

    return (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticating || initializing) {
            return <LoadingScreen>Authenticating</LoadingScreen>
          }

          if (error) {
            return <Redirect to={GLOBAL_PATHS.ERROR} />
          }

          if (isAuthenticated) {
            if (adminRoute && !isAdmin) {
              return <Redirect to={GLOBAL_PATHS.ERROR} />
            }

            return <Component {...props} authProps={authProps} />
          }

          if (isAdmin) {
            return <Redirect to={ADMIN_PATHS.LOGIN} />
          }

          return (
            <Redirect
              to={{
                pathname: GLOBAL_PATHS.LOGIN,
                state: { from: props.location },
              }}
            />
          )
        }}
      />
    )
  }

  static propTypes = {
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    render: PropTypes.func,
    admin: PropTypes.bool,
    location: PropTypes.object.isRequired,
    authProps: PropTypes.shape({
      error: PropTypes.string,
      isAdmin: PropTypes.bool.isRequired,
      isAuthenticated: PropTypes.bool.isRequired,
      isAuthenticating: PropTypes.bool.isRequired,
    }).isRequired,
  }
}

export default AuthenticatedRoute
