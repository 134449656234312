import React from 'react'
import PropTypes from 'prop-types'
import { Input as ReactstrapInput } from 'reactstrap'
import omit from 'lodash/omit'

class Input extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.field.value,
    }

    this.initialState = this.state
  }

  resetForm = () => {
    const { type } = this.state
    if (type === 'number') {
      this.setState({ value: '' })
    } else {
      this.setState(this.initialState)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.state
    const {
      field: { value: nextValue },
    } = nextProps

    if (value === nextValue) {
      return
    }

    this.setState({
      value: nextValue,
    })
  }

  constrainInput = value =>
    this.constrainNumber(this.constrainValue(this.constrainLength(value)))

  notifyChangeHandlers = e => {
    const { handleChange: propHandleChange, willUseReset, field } = this.props
    const { onChange: fieldHandleChange } = field
    propHandleChange && !willUseReset && propHandleChange(e)
    fieldHandleChange && fieldHandleChange(e)
  }

  onChange = e => {
    e.persist()
    const {
      target: { value },
    } = e
    const { handleChange: propHandleChange, willUseReset } = this.props

    this.setState(
      {
        value: this.constrainInput(value),
      },
      () => {
        const { value: newValue } = this.state
        e.target.value = newValue
        this.notifyChangeHandlers(e)
      }
    )
    propHandleChange && willUseReset && propHandleChange(e, this.resetForm)
  }

  constrainValue = value => {
    const { type, maxValue } = this.props
    const { value: stateValue } = this.state

    if (type === 'number' && maxValue && Number(value) > Number(maxValue)) {
      return stateValue
    }

    return value
  }

  constrainLength = value => {
    const { maxLength } = this.props

    if (maxLength && value.length > maxLength) {
      value = value.slice(0, maxLength)
    }

    return value
  }

  constrainNumber = value => {
    const { type } = this.props

    if (type === 'number') {
      value = String(value).replace(/[^0-9]/g, '')
    } else if (type === 'currency') {
      value = this.constrainWholeCurrency(value)
    } else if (type === 'negative-currency') {
      value = this.constrainNegativeCurrency(value)
    }

    return value
  }

  constrainWholeCurrency = value => {
    return String(value).replace(/[^0-9.]/g, '')
  }

  constrainNegativeCurrency = value => {
    return String(value).replace(/[^-0-9.]/g, '')
  }

  render() {
    const {
      'data-test': dataTest,
      id,
      type,
      disabled,
      invalid,
      children,
      handleBlur,
      field,
      form,
      resetValue,
      // Ignore the next 3 so they are not spread to children
      handleChange: ignoredHandleChange,
      maxValue: ignoredMaxValue,
      maxLength: ignoredMaxLength,
      ...rest
    } = this.props
    const { name, onBlur } = field
    const { errors, touched } = form
    const { value } = this.state

    return (
      <ReactstrapInput
        {...omit(rest, 'willUseReset')}
        disabled={disabled}
        data-test={dataTest || name}
        id={id || name}
        type={type === 'number' || type === 'currency' ? 'tel' : type}
        name={name}
        value={value}
        invalid={invalid || !!(touched[name] && errors[name])}
        onBlur={
          handleBlur
            ? e => {
                handleBlur(e)
                onBlur(e)
              }
            : onBlur
        }
        onChange={this.onChange}
      >
        {children}
      </ReactstrapInput>
    )
  }
}

Input.defaultProps = {
  disabled: false,
  type: 'text',
}

Input.propTypes = {
  children: PropTypes.node,
  'data-test': PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxValue: PropTypes.number,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  willUseReset: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
}

export default Input
