import uuid from 'uuid/v1'
import { parseLocationHash } from 'shared/utils'

const getFromURL = () => {
  const { session_id } = parseLocationHash(window.location.hash)
  return session_id
}

class Correlation {
  getCorrelationId() {
    return uuid()
  }

  getSessionId() {
    if (!this.sessionId) {
      this.sessionId = getFromURL() || uuid()
    }
    return this.sessionId
  }
}

export default new Correlation()
