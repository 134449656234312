import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'

const LoanPaymentSchedule = props => {
  const { paymentSchedule } = props

  return (
    <div>
      <h3 className="mb-0">Installment Loan Schedule</h3>

      <Table striped responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Interest</th>
            <th>Principle</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {paymentSchedule.map(schedule => (
            <tr key={schedule.dueDate}>
              <td>{schedule.dueDate}</td>
              <td className="text-right">{schedule.paymentAmount}</td>
              <td className="text-right">{schedule.interest}</td>
              <td className="text-right">{schedule.principle}</td>
              <td className="text-right">{schedule.balance}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

LoanPaymentSchedule.propTypes = {
  paymentSchedule: PropTypes.array.isRequired,
}

export default LoanPaymentSchedule
