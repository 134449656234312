import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { Container, Row, Col, Button } from 'reactstrap'
import Cognito from 'shared/cognito'
import ForgotPasswordForm from './ForgotPasswordForm'
import { BrandingCheck, LDComponent } from 'shared/components'
import getBlackbox from 'shared/Iovation'
import { Redirect } from 'react-router-dom'
import { GLOBAL_PATHS } from 'constants/paths'
import Branding from 'shared/branding'
import { Helmet } from 'shared/components'

const REACT_APP_AUTH_URI = process.env.REACT_APP_AUTH_URI

const ForgotPasswordPage = props => {
  const [processing, setProcessing] = useState(false)
  const [resetComplete, setResetComplete] = useState(false)
  const [resetSuccess, setResetSuccess] = useState(false)
  const [verificationFailed, setVerificationEnabled] = useState(false)
  const [showRedirectToEnroll, setShowRedirectToEnroll] = useState(true)
  const [passwordless, setPasswordless] = useState(false)
  const [search, setSearchValue] = useState('')

  const doPassReset = email => Cognito.forgotPassword(email)

  const postPassReset = (email, forgotPasswordEnabled, flags) => {
    if (!forgotPasswordEnabled) {
      doPassReset(email)
        .then(() => {
          setProcessing(false)
          setResetComplete(true)
          setResetSuccess(true)
        })
        .catch(() => {
          setProcessing(false)
          setResetComplete(false)
          setResetSuccess(false)
        })
    } else {
      axios({
        method: 'post',
        url: REACT_APP_AUTH_URI,
        data: {
          email: email,
          blackBox: getBlackbox(),
          type: 'forgotPassword',
          flags: flags
        },
      })
        .then(async ({ data }) => {
          if (data.results === 'DENIED') {
            return { verificationFailed: true }
          } else if (data.results === 'FAILED_P') {
            return { verificationFailed: false, passwordlessFailure: false, passwordless: true }
          } else {
            await doPassReset(email)
            return { verificationFailed: false }
          }
        })
        .then(({ verificationFailed, passwordlessFailure, passwordless }) => {
          if (passwordlessFailure) {
            setProcessing(false)
            setResetComplete(true)
            setResetSuccess(false)
            setVerificationEnabled(verificationFailed)
            setShowRedirectToEnroll(false)
            setPasswordless(false)
          } else {
            setProcessing(false)
            setResetComplete(true)
            setResetSuccess(true)
            setVerificationEnabled(verificationFailed)
            setShowRedirectToEnroll(true)
            if (passwordless) {
              setShowRedirectToEnroll(false)
              setPasswordless(true)
            }
          }
        })
        .catch(() => {
          setProcessing(false)
          setResetComplete(true)
          setResetSuccess(false)
          setShowRedirectToEnroll(true)
          setPasswordless(false)
        })
    }
  }

  const onSubmit = ({ email, forgotPasswordEnabled, flags }) => {
    setProcessing(true)
    setResetComplete(false)
    setPasswordless(false)
    setSearchValue(email)
    postPassReset(email, forgotPasswordEnabled, flags)
  }

  const goBack = () => {
    const { history } = props
    history.goBack()
  }

  const getEmailFromLocation = () => {
    const {
      location: { state = {} },
    } = props
    return state.email || ''
  }

  if (verificationFailed) {
    return <Redirect to={GLOBAL_PATHS.FORGOT_PASSWORD_FAILED} />
  }

  return (
    <React.Fragment>
      <Helmet brandName={Branding.current.name} pageName="Forgot Password" />
      <BrandingCheck />
      <Container>
        <Row>
          <Col>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <LDComponent>
                {({ flags: { authentication: authenticationFlags, enablePasswordlessFlow } }) => {
                  const { forgotPassword: forgotPasswordEnabled } =
                    authenticationFlags || {}
                  const flags = [{ name: 'enablePasswordlessFlow', value: enablePasswordlessFlow }]
                  return (
                    <ForgotPasswordForm
                      handleSubmit={emailObj =>
                        onSubmit({
                          email: emailObj.email,
                          forgotPasswordEnabled,
                          flags
                        })
                      }
                      processing={processing}
                      resetComplete={resetComplete}
                      resetSuccess={resetSuccess}
                      showRedirectToEnroll={showRedirectToEnroll}
                      passwordless={passwordless}
                      email={getEmailFromLocation()}
                      search={search}
                    />
                  )
                }}
              </LDComponent>
              <Button
                id="forgot_password--button-go_back"
                className="auth-block"
                onClick={goBack}
                color="primary"
                outline
                block
              >
                Go Back
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

ForgotPasswordPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default ForgotPasswordPage
