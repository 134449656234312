import React from 'react'
import WithAuth from '../WithAuth'
import AuthenticatedRoute from './AuthenticatedRoute'

const AuthenticatedRouteWithAuth = props => (
  <WithAuth>
    {authProps => <AuthenticatedRoute {...props} authProps={authProps} />}
  </WithAuth>
)

export default AuthenticatedRouteWithAuth
