import React from 'react'
import PropTypes from 'prop-types'

import { LoadingScreen } from 'shared/components'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import YourStoreAddress from './YourStoreAddress'
import YourStoreHours from './YourStoreHours'

const YourStoreDetails = ({
  googleDirectionsUri,
  loadingSearchResults,
  selectedStore,
  showPartner,
  isConditionalApproval,
}) => {
  if (!selectedStore && !loadingSearchResults) {
    return null
  }

  return (
    <Container
      className={isConditionalApproval ? 'h-100 m-0 p-0' : 'p-0 pt-4'}
      id="your-store-details"
    >
      <Row className="h-100">
        <Col lg="12">
          <Card
            className={isConditionalApproval ? 'h-100 border border-dark' : ''}
          >
            <CardBody>
              <Row>
                <Col md="12">
                  {loadingSearchResults && !selectedStore && (
                    <LoadingScreen inline>Locating your store</LoadingScreen>
                  )}
                  {selectedStore && !loadingSearchResults && (
                    <React.Fragment>
                      <div className="loan-card loan-detail no-border primary current no-margin-bottom">
                        Your Store
                      </div>

                      <YourStoreHours
                        {...selectedStore}
                        isConditionalApproval={isConditionalApproval}
                      />
                      <YourStoreAddress
                        {...selectedStore}
                        googleDirectionsUri={googleDirectionsUri}
                        showPartner={showPartner}
                        isConditionalApproval={isConditionalApproval}
                      />
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

YourStoreDetails.propTypes = {
  googleDirectionsUri: PropTypes.string,
  googleMapsUri: PropTypes.string,
  loadingSearchResults: PropTypes.bool,
  mapMinHeight: PropTypes.number,
  selectedStore: PropTypes.shape({
    storeNumber: PropTypes.number,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    hours: PropTypes.arrayOf(
      PropTypes.shape({
        openTime: PropTypes.string,
        closeTime: PropTypes.string,
      })
    ),
  }),
  showPartner: PropTypes.bool,
  isConditionalApproval: PropTypes.bool,
}

export default YourStoreDetails
