import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { parseLocationHash } from 'shared/utils'
import { ADMIN_PATHS } from 'constants/paths'

class SSOInterceptor extends React.Component {
  state = {
    ssoDone: false,
    ssoError: null,
  }

  componentWillMount() {
    const { singleSignOn, location } = this.props
    const ssoData = parseLocationHash(location.hash)

    if (ssoData.access_token || ssoData.id_token) {
      singleSignOn(window.location)
        .then(() => {
          this.setState({ ssoDone: true })
        })
        .catch(ssoError => {
          this.setState({ ssoError })
        })
    } else {
      this.setState({ ssoError: { message: 'No SSO data found' } })
    }
  }

  render() {
    const { ssoDone, ssoError } = this.state

    if (ssoDone) {
      return <Redirect to={ADMIN_PATHS.LANDING} />
    }

    if (ssoError) {
      return <p className="text-danger">{ssoError.message}</p>
    }

    return <p>Logging in via SSO...</p>
  }

  static propTypes = {
    singleSignOn: PropTypes.func.isRequired,
    location: PropTypes.shape({
      hash: PropTypes.string.isRequired,
    }).isRequired,
  }
}

export default SSOInterceptor
