import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { RememberDeviceMutationGql } from './graphql/mutations'

const RememberDeviceMutation = ({ children, ...rest }) => {
  return (
    <Mutation mutation={RememberDeviceMutationGql}>
      {(rememberDevice, { data, error, loading }) =>
        children({ rememberDevice, data, error, loading, ...rest })
      }
    </Mutation>
  )
}

RememberDeviceMutation.propTypes = {
  children: PropTypes.func.isRequired,
}

export default RememberDeviceMutation
