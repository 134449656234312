import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Sentry } from 'shared/components'
import { GLOBAL_PATHS } from 'constants/paths'

class ErrorBoundary extends React.Component {
  state = { inError: false, error: '' }

  componentDidCatch(error, _info) {
    if (error) {
      this.setState({
        inError: true,
        error: _.isObject(error) ? JSON.stringify(error) : error,
      })
    }
  }

  render() {
    const { inError, error } = this.state

    if (inError) {
      this.setState({ inError: false })

      return (
        <React.Fragment>
          <Sentry error={error} />
          <Redirect to={GLOBAL_PATHS.ERROR} />
        </React.Fragment>
      )
    }

    const { children } = this.props

    return children
  }

  static propTypes = {
    children: PropTypes.node,
  }
}

export default ErrorBoundary
