import gql from 'graphql-tag'

const emailVerificationQuery = gql`
  query emailVerificationQuery($enrollmentId: ID, $mfaId: ID) {
    searchEmailVerification(enrollmentId: $enrollmentId, mfaId: $mfaId) {
      emailVerificationId
      sessionId
    }
  }
`

export default { emailVerificationQuery }
export { emailVerificationQuery }
