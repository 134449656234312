export const SHOWILPVALUE = {
  exampleBorrow: 1400,
  exampleAPR: 192.77,
  exampleTerm: 18,
  examplePaymentAmount: 119.42,
  exampleTotal: 4417.69,
  aprLow: 145,
  aprHigh: 225,
  maxAmountForCA: 2400,
  xactExamples: {
    maxApr: 225,
    minApr: 125,
    terms: [
      {
        term: 18,
        principal: 1400,
        apr: 192.77,
        installmentPayments: '37 biweekly payments of $119.42',
        totalPayments: 4417.69,
      },
      {
        term: 24,
        principal: 2000,
        apr: 144.61,
        installmentPayments: '48 biweekly payments of $119.69',
        totalPayments: 5745.41,
        returningRefiOnly: true,
      },
    ],
  },
}
