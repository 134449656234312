import { merge } from 'shared/utils'
import { AUTHENTICATION_QUERY } from './queries'

const __typename = 'AuthenticationInformation'

export const defaults = {
  authentication: {
    __typename,
    email: '',
    adminEmail: null,
    isAuthenticated: false,
    isLegacyAuthenticated: false,
    isAuthenticating: false,
    isAdmin: false,
    isReturningCustomer: false,
    isRetailReturning: false,
    isCoreCustomer: false,
    error: null,
    boCode: null,
    retailBoCode: null,
    loanProCustomerId: null,
    qFundCustomerId: null,
    transient: null,
    firstName: null,
    lastName: null,
    isEnrollment: false,
    customBrand: null,
    hasOnlinePendingOrigination: false,
    hasPastOnlineApplications: false,
    hasPendingApplication: false,
    pendingApplicationBrand: null,
    customerType: null,
    stateCode: null,
    channel: null,
  },
}

export default {
  Mutation: {
    updateAuthentication: (
      _,
      {
        email,
        adminEmail,
        isAuthenticated,
        isLegacyAuthenticated,
        isAuthenticating,
        isAdmin,
        isReturningCustomer,
        isRetailReturning,
        isCoreCustomer,
        error,
        boCode,
        retailBoCode,
        loanProCustomerId,
        qFundCustomerId,
        transient,
        firstName,
        lastName,
        isEnrollment,
        customBrand,
        hasOnlinePendingOrigination,
        hasPastOnlineApplications,
        hasPendingApplication,
        pendingApplicationBrand,
        customerType,
        stateCode,
        channel,
      },
      { cache }
    ) => {
      const { authentication: oldAuthentication } = cache.readQuery({
        query: AUTHENTICATION_QUERY,
      })

      const newAuthentication = {
        __typename,
        email,
        adminEmail,
        isAuthenticated,
        isLegacyAuthenticated,
        isAuthenticating,
        isAdmin,
        isReturningCustomer,
        isRetailReturning,
        isCoreCustomer,
        error,
        boCode,
        retailBoCode,
        loanProCustomerId,
        qFundCustomerId,
        transient,
        firstName,
        lastName,
        isEnrollment,
        customBrand,
        hasOnlinePendingOrigination,
        hasPastOnlineApplications,
        hasPendingApplication,
        pendingApplicationBrand,
        customerType,
        stateCode,
        channel,
      }
      return merge(oldAuthentication, newAuthentication)
    },
  },
}
