import React from 'react'
import PropTypes from 'prop-types'
import { Alert, FormGroup } from 'reactstrap'
import { PdfViewer } from 'shared/components'
import ConsentCheck from '../../../application/components/Agreements/ConsentCheck'

class ReadAgrees extends React.Component {
  state = {
    pdfUrl: null,
    fields: null,
    initialized: false,
  }

  componentWillReceiveProps() {
    const { initialized } = this.state

    if (!initialized) {
      this.populateAgreements()
    }
  }

  populateAgreements = () => {
    const { readAndAgrees, currentDisclosures, onDecision } = this.props
    if (readAndAgrees) {
      let allAgreed = true
      readAndAgrees.forEach(doc => {
        const accepted = this.disclosureInitialValue(
          currentDisclosures,
          doc.idTag
        )
        const field = `readAgree_${doc.idTag}`
        this.setState({ [field]: accepted })
        if (!accepted) {
          allAgreed = false
        }
      })
      this.setState({ initialized: true }, () => onDecision(allAgreed))
    }
  }

  disclosureInitialValue = (disclosures, key) => {
    if (disclosures) {
      return (
        disclosures
          .filter(d => d.idTag === key)
          .map(d => d.value)
          .find(d => d) || false
      )
    }
    return false
  }

  showForm = (pdfTitle, pdfUrl, pdfOnAgree = null) => {
    this.setState({ pdfTitle, pdfUrl, pdfOnAgree })
  }

  showAgreementForm = (title, url, acceptAction) => {
    url && this.showForm(title, url, acceptAction)
  }

  toggleReadAndAgree = document => {
    const field = `readAgree_${document.idTag}`
    const readAndAgree = this.state[field]
    this.setState({ currentReadAndAgree: document.idTag }, () => {
      readAndAgree
        ? this.decisionReadAndAgree(false)
        : this.showReadAndAgree(document)
    })
  }

  showReadAndAgree = document => {
    this.setState({ currentReadAndAgree: document.idTag })
    this.showAgreementForm(
      document.title || 'GENERAL CONSENT',
      document.url,
      this.decisionReadAndAgree
    )
  }

  decisionReadAndAgree = accepted => {
    const { currentReadAndAgree } = this.state
    const { onDecision } = this.props

    const field = `readAgree_${currentReadAndAgree}`
    this.setState(
      {
        [field]: accepted,
        pdfUrl: null,
        currentReadAndAgree: null,
      },
      () => {
        onDecision(this.allDocumentAgreed())
      }
    )
  }

  readAndAgreeChecked = idTag => {
    const field = `readAgree_${idTag}`
    return this.state[field] || false
  }

  allDocumentAgreed = () => {
    const { readAndAgrees } = this.props

    let allAgreed = true
    readAndAgrees.forEach(document => {
      const field = `readAgree_${document.idTag}`
      if (allAgreed && !this.state[field]) {
        allAgreed = false
      }
    })
    return allAgreed
  }

  render() {
    const { agreementsError, readAndAgrees } = this.props
    const { pdfUrl, pdfTitle, pdfOnAgree } = this.state
    return (
      <React.Fragment>
        <FormGroup>
          {agreementsError && (
            <Alert color="danger" data-test="agreements-error">
              You must indicate you agree to each of the terms below and have
              read and understood the terms of associated documents
            </Alert>
          )}

          {readAndAgrees &&
            readAndAgrees.map(d => (
              <ConsentCheck
                key={d.idTag}
                contextId={d.idTag}
                checked={this.readAndAgreeChecked(d.idTag)}
                onCheckboxClick={() => this.toggleReadAndAgree(d)}
                onButtonClick={() => this.showReadAndAgree(d)}
                dataTest={`read-agree-${d.idTag}`}
              >
                {d.title}
              </ConsentCheck>
            ))}
        </FormGroup>

        <PdfViewer
          isOpen={pdfUrl !== null}
          title={pdfTitle}
          url={pdfUrl}
          closeOnly={pdfOnAgree === null}
          onAgree={() => pdfOnAgree(true)}
          onDisagree={() => pdfOnAgree(false)}
          onClose={() => this.setState({ pdfUrl: null })}
        />
      </React.Fragment>
    )
  }
}

ReadAgrees.propTypes = {
  agreementsError: PropTypes.bool,
  readAndAgrees: PropTypes.arrayOf(
    PropTypes.shape({
      idTag: PropTypes.string.isRequired,
      title: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  ),
  currentDisclosures: PropTypes.arrayOf(
    PropTypes.shape({
      idTag: PropTypes.string.isRequired,
      value: PropTypes.bool,
    })
  ),
}

export default ReadAgrees
