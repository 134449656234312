import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'

const MyAccountLink = ({ handleMyAccountRedirect }) => {
  return (
    <Container>
      <span
        className="btn-link pointer"
        onClick={handleMyAccountRedirect}
        data-test="links.my-account-redirect"
      >
        Return to Pocket360®
      </span>
    </Container>
  )
}

MyAccountLink.propTypes = {
  handleMyAccountRedirect: PropTypes.func.isRequired,
}

export default MyAccountLink
