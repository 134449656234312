import React from 'react'
import Branding from 'shared/branding'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'

const { friendlyName: xactLoanName } = Branding.getBrand(
  ONLINE_STORE_BRAND_NAME.CCB
)
const XactLoanDisclosure = () => (
  <p>
    The {xactLoanName} is an installment loan originated and funded by Capital
    Community Bank, a Utah Chartered bank located in Provo, UT, Member FDIC.
  </p>
)

export default XactLoanDisclosure
