import PropTypes from 'prop-types'
import { parseLocationSearch } from 'shared/utils'
import { setCookie } from 'shared/cookie'

const QueryStringMonitor = ({ location }) => {
  if (location.search) {
    const {
      store,
      cms,
      nonKioskEnrollment,
      oauth_state_id,
    } = parseLocationSearch(location.search)

    if (store) {
      setCookie(document, '', 'store_number', store, 30 * 24 * 60 * 60)
    }

    if (nonKioskEnrollment) {
      setCookie(document, '', 'showWelcomeModal', 'true', 60)
    }

    if (cms) {
      setCookie(document, '', 'cng_cms', cms, 30 * 24 * 60 * 60)
    }

    if (oauth_state_id) {
      setCookie(document, '', 'plaid_oauth', oauth_state_id, 30 * 24 * 60 * 60)
    }
  }

  return null
}

QueryStringMonitor.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
}

export default QueryStringMonitor
