import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import Branding from 'shared/branding'
import StorePhone from './StorePhone'

const partner = Branding.getBrand(Branding.current.partnerBrand)

const Days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const StoreAddress = ({
  storeName,
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode,
  phone,
  hours,
  dayOfWeek,
  showDetails,
  showPartner,
}) => (
  <React.Fragment>
    {storeName && (
      <div className="font-weight-bold">
        {showPartner ? partner.name : Branding.current.originName} {storeName}
      </div>
    )}
    <div>{addressLine1}</div>
    {!!addressLine2 && showDetails && <div>{addressLine2}</div>}
    <div className="mb-2">
      {city}, {state} {postalCode}
    </div>
    <div>
      <StorePhone phoneNumber={phone} showDetails={showDetails} />
    </div>
    {showDetails && (
      <div data-test="hours">
        {hours.map(({ openTime, closeTime }, idx) => {
          const time = openTime ? `${openTime} - ${closeTime}` : 'Closed'

          return (
            <Row key={idx}>
              <Col
                xs="5"
                className={
                  'pr-0' + (dayOfWeek === Days[idx] ? ' font-weight-bold' : '')
                }
              >
                {Days[idx]}:
              </Col>
              <Col
                xs="7"
                className={
                  'p-0' + (dayOfWeek === Days[idx] ? ' font-weight-bold' : '')
                }
              >
                {time}
              </Col>
            </Row>
          )
        })}
      </div>
    )}
  </React.Fragment>
)

StoreAddress.propTypes = {
  storeName: PropTypes.string,
  addressLine1: PropTypes.string.isRequired,
  addressLine2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
    })
  ).isRequired,
  showDetails: PropTypes.bool,
  showPartner: PropTypes.bool,
  dayOfWeek: PropTypes.string.isRequired,
}

export default StoreAddress
