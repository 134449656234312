import React from 'react'
import PropTypes from 'prop-types'

const PatriotActDisclosure = ({ showFull = true }) => (
  <p>
    <strong>
      Important Information About Procedures for Opening a New Account
      {showFull && ` or Applying for a Loan`}:
    </strong>{' '}
    To help the government fight the funding of terrorism and money laundering
    activities, Federal law requires all financial institutions to obtain,
    verify, and record information that identifies each person who opens an
    account or applies for a loan. What this means for you: When you open an
    account or apply for a loan, we will ask for your name, street address, date
    of birth, tax identification number and other information that will allow us
    to identify you. We may also ask to see your driver's license, or other
    identifying documents.
  </p>
)

PatriotActDisclosure.propTypes = {
  showFull: PropTypes.bool,
}

export default PatriotActDisclosure
