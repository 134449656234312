import React from 'react'
import authService from 'authentication/service'
import { ErrorBody, Footer } from 'shared/components'

class ErrorPage extends React.Component {
  componentDidMount() {
    // Once we're on this page, clear the error state
    // so the user isn't stuck on the page.
    authService.clearError()
  }

  render() {
    return (
      <div className="mainBody">
        <ErrorBody />
        <Footer stateCode="" />
      </div>
    )
  }
}

export default ErrorPage
