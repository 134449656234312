import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from 'reactstrap'
import Cognito from 'shared/cognito'
import ChangePasswordForm from './ChangePasswordForm'
import { GLOBAL_PATHS } from 'constants/paths'
import { BrandingCheck } from 'shared/components'
import axios from 'axios'

class ChangePasswordPage extends React.Component {
  state = {
    processing: false,
    errorMessage: null,
  }

  handleSubmit = ({ confirmPassword }) => {
    this.setState({ processing: true }, () =>
      this.processSubmit(confirmPassword)
    )
  }

  processSubmit = password => {
    const {
      match: {
        params: { code, email },
      },
    } = this.props

    this.doChangePassword(email, code, password).then(
      this.changePasswordSuccess(email),
      this.changePasswordError
    )
  }

  doChangePassword = (email, code, password) =>
    Cognito.changePassword(email, code, password)

  changePasswordSuccess = email => {
    const { REACT_APP_PASSWORD_CHANGED_URI } = process.env
    const params = { email: email }
    axios.post(`${REACT_APP_PASSWORD_CHANGED_URI}`, params).then(() => {
      this.setState({ processing: false }, this.goToLogin)
    })
  }

  goToLogin = () => {
    const { history } = this.props
    history.push(GLOBAL_PATHS.LOGIN)
  }

  changePasswordError = ({ _message }) =>
    this.setState({
      processing: false,
      errorMessage: 'This link is no longer valid.',
    })

  render() {
    const { processing, errorMessage } = this.state
    return (
      <React.Fragment>
        <BrandingCheck />
        <Container>
          <Row>
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <ChangePasswordForm
                  processing={processing}
                  errorMessage={errorMessage}
                  onSubmit={this.handleSubmit}
                />
                <Button
                  id="change_password--button-go_to_login"
                  color="primary"
                  outline
                  className="auth-block"
                  href={GLOBAL_PATHS.LOGIN}
                >
                  Go to login
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }
}

export default ChangePasswordPage
