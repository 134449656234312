import gql from 'graphql-tag'

const connectToPlaid = gql`
  mutation connectToPlaid(
    $id: ID!
    $publicToken: String!
    $isUpdateMode: Boolean
  ) {
    initializePlaidConnection(
      id: $id
      publicToken: $publicToken
      isUpdateMode: $isUpdateMode
    ) {
      access_token
      isAuthEnabled
      institution {
        institution_id
        name
        logo
      }
      checkingAccounts {
        account_id
        mask
        name
        official_name
        subtype
        type
        balances {
          current
          available
          limit
          iso_currency_code
        }
        numbers {
          account
          routing
        }
      }
      assetReport {
        asset_report_id
        asset_report_token
      }
      authStatus
      publicToken
    }
  }
`

const createPlaidLinkToken = gql`
  mutation createPlaidToken(
    $id: ID!
    $clientName: String
    $linkCustomizationName: String
    $redirectUrl: String
  ) {
    createPlaidToken(
      id: $id
      clientName: $clientName
      linkCustomizationName: $linkCustomizationName
      redirectUrl: $redirectUrl
    ) {
      expiration
      link_token
      request_id
    }
  }
`

const customerSearchBySSN = gql`
  mutation customerSearchBySSN($ssn: String) {
    customerSsnSearch(ssn: $ssn) {
      hasOpenApplication
      isReturningRetailCustomer
      isReturningCustomer
      isCoreCustomer
      hasRetailPresence
      hasOnlinePendingOrigination
      hasPendingApplication
      customerInformation {
        personalInformation {
          firstName
          lastName
        }
      }
    }
  }
`

export default {
  connectToPlaid,
  createPlaidLinkToken,
  customerSearchBySSN,
}

export {
  connectToPlaid,
  createPlaidLinkToken,
  customerSearchBySSN,
}
