import clpLogo from 'shared/images/ChoiceLoan-Icon.svg'
import ilpLogo from 'shared/images/InstallmentLoan-Icon.svg'
import pdlLogo from 'shared/images/PaydayLoan-Icon.svg'
import selectLoanLogo from 'shared/images/SelectLoan-Icon.svg'
import registrationLoanLogo from 'shared/images/RegistrationLoan-Icon.svg'
import longTerm from 'shared/images/Longest-LongerTerm-Icon.svg'
import applyForBoth from 'shared/images/ApplyForBoth-Icon.svg'
import mostFlexible from 'shared/images/MostFlexible-Icon.svg'
import moreOptions from 'shared/images/MoreOptions-Icon.svg'
import mostAvailable from 'shared/images/MostAvailable-Icon.svg'
import lowerRates from 'shared/images/LowerRates-Icon.svg'
import ohio from 'shared/images/Ohio.svg'
import {
  PAYDAY_LOAN,
  CHOICE_LOAN,
  INSTALLMENT_LOAN,
  DUAL_ONLINE,
  BANK_ILP,
  RETAIL_ILP,
  SELECT_LOAN,
  REGISTRATION_LOAN,
  LOAN_APPLICATION_ORIGINATION_CHANNEL,
} from 'constants/values'

const basicBeSureToBring = [
  { id: 'bsb_id', description: 'Valid identification' },
  {
    id: 'bsb_pay',
    description: 'Proof of income',
  },
  {
    id: 'bsb_acct',
    description: 'Active checking account',
  },
  {
    id: 'bsb_bill',
    description: 'Proof of address and phone number',
  },
]

const productMap = {
  PDL: {
    title: 'Payday Loan',
    comparisonHeader: 'Traditional Payday Loan',
    whatIsIt:
      "A short-term loan that you pay back on your next payday. Cover expenses you didn't see coming, pay off bills or simply make ends meet until payday.",
    whenCanIGetMyMoney: 'Get funds as soon as the next business day',
    howWillIGetMyMoney: 'Direct deposit into your active checking account',
    paymentTermAppend: ' On your next payday',
    productIcon: pdlLogo,
    productOptions: [
      {
        icon: mostAvailable,
        description: 'Most Availability',
      },
    ],
    additionalDetails: {
      title: 'What is a Payday Loan?',
      details:
        'A short-term loan that you pay back on your next payday. Cover expenses you don’t see coming, pay off bills or simply make ends meet until payday.',
    },
    isChoice: false,
    hasTerms: false,
    isFlexibleTerm: false,
  },
  ILP: {
    title: 'Installment Loan',
    whatIsIt:
      'A loan that is paid back over time on a fixed schedule. Consolidate bills, make a major purchase or cover an unexpected expense.',
    whenCanIGetMyMoney: 'Get funds as soon as the next business day',
    howWillIGetMyMoney: 'Direct deposit into your active checking account',
    paymentTermPrepend: 'Up to ',
    paymentTermAppend: ' months',
    productIcon: ilpLogo,
    productOptions: [
      {
        icon: longTerm,
        description: 'Longer Term',
      },
    ],
    additionalDetails: {
      title: 'What is an Installment Loan?',
      details:
        'A loan that is paid back over time on a fixed schedule. Consolidate bills, make a major purchase or cover an unexpected expense.',
    },
    isChoice: false,
    hasTerms: true,
    isFlexibleTerm: false,
  },
  BANK_ILP: {
    title: 'Xact<small><sup>&reg;</sup></small> Loan',
    comparisonHeader: 'Xact® Loan',
    whatIsIt: `It's an installment loan that is paid back over time on a fixed schedule. Consolidate bills, make a major purchase or cover an unexpected expense.`,
    whenCanIGetMyMoney: 'Get funds as soon as the next business day',
    howWillIGetMyMoney: 'Direct deposit into your active checking account',
    paymentTermPrepend: 'Up to ',
    paymentTermAppend: ' months',
    productIcon: ilpLogo,
    productOptions: [
      {
        icon: longTerm,
        description: 'Longer Term',
      },
    ],
    additionalDetails: {
      title: 'What is an Xact<small><sup>&reg;</sup></small> Loan?',
      details: `It's an installment loan that is paid back over time on a fixed schedule. Consolidate bills, make a major purchase or cover an unexpected expense.`,
    },
    isChoice: false,
    hasTerms: true,
    isFlexibleTerm: false,
  },
  RETAIL_ILP: {
    title: 'Installment Loan',
    whatIsIt:
      'A loan that is paid back over time on a fixed schedule. Consolidate bills, make a major purchase or cover an unexpected expense.',
    whenCanIGetMyMoney:
      'Get funds <strong>TODAY</strong> at a Check `n Go store<sup>1</sup>',
    howWillIGetMyMoney: 'In person, at a Check `n Go store.',
    paymentTermAppend: ' months to repay',
    productIcon: clpLogo,
    productOptions: [
      {
        icon: mostAvailable,
        description: 'Only available in ',
        insertState: true,
      },
    ],
    additionalDetails: {
      title: 'What is an Installment Loan?',
      details:
        'A loan that is paid back over time on a fixed schedule. Consolidate bills, make a major purchase or cover an unexpected expense. Additional products may be available in the store. See the <a title="Check `n Go State Center" href="https://www.checkngo.com/resources/state-center/" target="_blank" rel="noopener noreferrer"> State Center </a> for specific information and requirements.',
    },
    isChoice: true,
    hasTerms: true,
    isFlexibleTerm: false,
    beSureToBring: basicBeSureToBring,
  },
  SLP: {
    title: 'The Select Loan',
    comparisonHeader: 'The Select Loan™',
    whatIsIt: '',
    whenCanIGetMyMoney:
      'Get funds <strong>TODAY</strong> at a Check `n Go store<sup>1</sup>',
    howWillIGetMyMoney: '',
    paymentTermPrepend: 'Flexible term (up to ',
    paymentTermAppend: ' months)',
    productIcon: selectLoanLogo,
    productOptions: [
      {
        icon: lowerRates,
        description: 'Lower rates',
        insertState: false,
      },
      {
        icon: ohio,
        description: 'Only available in ',
        insertState: true,
      },
    ],
    additionalDetails: {
      title: 'What is The Select Loan?',
      details:
        'Exclusively available in Ohio stores, The Select Loan offers flexible terms, lower rates, and lower payments.',
    },
    isChoice: true,
    hasTerms: true,
    isFlexibleTerm: false,
    showMaxTerm: true,
    beSureToBring: basicBeSureToBring,
  },
  RLP: {
    title: 'Registration Loan',
    whatIsIt: '',
    whenCanIGetMyMoney: `Get funds <strong>TODAY</strong> at an Allied Cash Advance store<sup>1</sup>`,
    howWillIGetMyMoney: '',
    paymentTermPrepend: '',
    paymentTermAppend: '',
    productIcon: registrationLoanLogo,
    productOptions: [
      {
        icon: mostAvailable,
        description: 'Only available in ',
        insertState: true,
      },
    ],
    additionalDetails: {
      title: 'What is a registration loan?',
      details: `If you have a vehicle in your name, you may qualify for a registration loan - no title required. At a minimum, you'll need proof of vehicle registration, an active phone number, a valid ID, proof of income and proof of an active checking account to apply.`,
    },
    isChoice: true,
    hasTerms: false,
    isFlexibleTerm: false,
    beSureToBring: [
      ...basicBeSureToBring,
      {
        id: 'bsb_own',
        description: 'Your vehicle registration or proof of ownership.',
      },
    ],
  },
  CLP: {
    title: 'The Choice Loan<small><sup>&trade;</sup></small>',
    subtitle: 'Our most popular choice!',
    whatIsIt:
      'The Choice Loan lets you pay off outstanding bills or cover larger expenses, with affordable monthly payments.',
    whenCanIGetMyMoney:
      'Get funds <strong>TODAY</strong> at a Check `n Go store<sup>1</sup>',
    howWillIGetMyMoney: 'In person, at a Check `n Go store.',
    paymentTermAppend: ' months to repay',
    productIcon: clpLogo,
    productOptions: [
      {
        icon: longTerm,
        description: 'Longest Term',
      },
      {
        icon: mostFlexible,
        description: 'Most Flexible',
      },
    ],
    additionalDetails: {
      title: 'What is The Choice Loan<small><sup>&trade;</sup></small>?',
      details:
        'The Choice Loan lets you choose your amount, your payment and the time you have to repay. It’s your choice! Additional products may be available in the store. See the <a title="Check `n Go State Center" href="https://www.checkngo.com/resources/state-center/" target="_blank" rel="noopener noreferrer"> State Center </a> for specific information and requirements.',
    },
    isChoice: true,
    hasTerms: true,
    isFlexibleTerm: true,
    beSureToBring: basicBeSureToBring,
  },
  CLP_NEW: {
    title: 'The Retail Choice Loan<small><sup>&trade;</sup></small>',
    whatIsIt:
      'The Choice Loan lets you pay off outstanding bills or cover larger expenses, with affordable monthly payments.',
    whenCanIGetMyMoney:
      'Get funds <strong>TODAY</strong> at a Check `n Go store<sup>3</sup>',
    howWillIGetMyMoney: 'In person, at a Check `n Go store.',
    paymentTermAppend: ' months to repay',
    productIcon: clpLogo,
    additionalDetails: {
      title: 'What is The Choice Loan<small><sup>&trade;</sup></small>?',
      details:
        'The Choice Loan lets you choose your amount, your payment and the time you have to repay. It’s your choice! Additional products may be available in the store. See the <a title="Check `n Go State Center" href="https://www.checkngo.com/resources/state-center/" target="_blank" rel="noopener noreferrer"> State Center </a> for specific information and requirements.',
    },
    isChoice: true,
    hasTerms: false,
    isFlexibleTerm: true,
    beSureToBring: basicBeSureToBring,
  },
  DUAL: {
    title: 'Online Installment & Payday Loans',
    whatIsIt: '',
    whenCanIGetMyMoney: 'Get funds as soon as the next business day',
    howWillIGetMyMoney: 'Direct deposit to your active checking account',
    installmentDetailPrefix: 'Installment loans up to ',
    installmentDetailAppend: ' and terms up to ',
    installmentEnd: ' months',
    paydayDetails: 'Payday loans up to ',
    productIcon: ilpLogo,
    productOptions: [
      {
        icon: moreOptions,
        description: 'More Options',
      },
      {
        icon: applyForBoth,
        description: 'Apply For Both',
      },
    ],
    additionalDetails: {
      title: 'What are Online Installment & Payday Loans?',
      details:
        'Whether you need funds to get by until payday, or an installment loan to consolidate bills – you can save time and apply for both an installment and payday loan at once.',
    },
  },
}

const sortProductsRules = [
  [BANK_ILP, LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE],
  [CHOICE_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL],
  [RETAIL_ILP, LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL],
  [SELECT_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL],
  [REGISTRATION_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL],
  [INSTALLMENT_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL],
  [PAYDAY_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL],
  [DUAL_ONLINE, LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE],
  [INSTALLMENT_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE],
  [PAYDAY_LOAN, LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE],
]

const adjustProducts = products => {
  const bankIlp = products.find(
    p =>
      p.productCode === INSTALLMENT_LOAN &&
      p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE &&
      p.lenderBrand
  )

  const onlineIlp = products.find(
    p =>
      p.productCode === INSTALLMENT_LOAN &&
      p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE &&
      !p.lenderBrand
  )

  const onlinePdl = products.find(
    p =>
      p.productCode === PAYDAY_LOAN &&
      p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE &&
      !p.lenderBrand
  )

  const retailIlp = products.find(
    p =>
      p.productCode === INSTALLMENT_LOAN &&
      p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL
  )

  products = products.filter(
    p =>
      !(
        p.productCode === PAYDAY_LOAN &&
        p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.RETAIL
      )
  )

  if (onlineIlp && onlinePdl) {
    products.push({
      productCode: 'DUAL',
      channel: LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE,
      brand: onlinePdl.brand,
      lenderBrand: onlinePdl.lenderBrand,
      installmentMaxLoan: onlineIlp.maxLoan,
      installmentMaxTerm: onlineIlp.maxTerm,
      paydayMaxLoan: onlinePdl.maxLoan,
    })
    products = products.filter(p => p !== onlineIlp && p !== onlinePdl)
  }

  if (bankIlp) {
    products = handleOneOffOptions(bankIlp, BANK_ILP, products)
  }

  products = handleOneOffOptions(retailIlp, RETAIL_ILP, products)

  return products
}

const adjustAllProducts = (products, preApprovedProducts) => {
  if (products) {
    products = adjustProducts(products)

    if (preApprovedProducts && preApprovedProducts.length) {
      preApprovedProducts = adjustProducts(preApprovedProducts)
    } else {
      preApprovedProducts = []
    }

    const dualProduct = products.find(p => p.productCode === DUAL_ONLINE)
    if (
      dualProduct &&
      (preApprovedProducts.find(
        p =>
          p.productCode === INSTALLMENT_LOAN &&
          p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE
      ) ||
        preApprovedProducts.find(
          p =>
            p.productCode === PAYDAY_LOAN &&
            p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE
        ))
    ) {
      preApprovedProducts = preApprovedProducts.filter(
        p =>
          !(
            (p.productCode === INSTALLMENT_LOAN ||
              p.productCode === PAYDAY_LOAN) &&
            p.channel === LOAN_APPLICATION_ORIGINATION_CHANNEL.ONLINE
          )
      )

      preApprovedProducts.push(dualProduct)
    }
  }
  return { products, preApprovedProducts }
}

const handleOneOffOptions = (loan, newProductCode, products) => {
  if (loan) {
    const option = Object.assign({}, loan)
    option.productCode = newProductCode
    products.push(option)
    products = products.filter(p => p !== loan)
  }
  return products
}

const sortProducts = products => {
  let sortedArray = []
  let resultIdx = 0
  for (let index = 0; index < sortProductsRules.length; index++) {
    for (
      let productArrayIndex = 0;
      productArrayIndex < products.length;
      productArrayIndex++
    ) {
      const product = products[productArrayIndex]
      if (
        product.productCode === sortProductsRules[index][0] &&
        product.channel === sortProductsRules[index][1]
      ) {
        sortedArray[resultIdx] = product
        resultIdx++
      }
    }
  }
  return sortedArray
}

export { productMap, sortProducts, adjustProducts, adjustAllProducts }
