import React from 'react'

const MapBodyParagraph = ({ body }) => {
  if (typeof body !== 'string') return body

  return body.split('\n').map((b, key) => {
    return <p key={key}>{b}</p>
  })
}

export { MapBodyParagraph }
