import React, { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import cognito from 'shared/cognito'
import { useIsMounted } from './EsignWebsocket'

const { REACT_APP_WEBSOCKET_BOSSWEB } = process.env

const closeSocket = (code, reason, webSocketRef) => {
  if (
    webSocketRef.current &&
    webSocketRef.current.readyState === WebSocket.OPEN
  ) {
    webSocketRef.current.close(code, reason)
  }
}

const PaymentWebsocketUtil = ({ loanId, setPaymentStatus, setIsWebsocketRegistered }) => {
  const webSocketRef = useRef(WebSocket)
  const isMounted = useIsMounted()
  const [isConnectionOpened, setIsConnectionOpened] = useState(false)
  const serverURL = `${REACT_APP_WEBSOCKET_BOSSWEB}?Auth=${cognito.idToken}`

  const registerPayment = useCallback((loanId, websocketRef) => {
    if (websocketRef.current) {
      websocketRef.current.send(
        JSON.stringify({
          action: 'registerListener',
          loanId: loanId
        })
      )
    }
  }, [])

  useEffect(() => {
    const connectOrReconnect = () => {
      if (
        !webSocketRef.current ||
        webSocketRef.current.CLOSED === 3 ||
        webSocketRef.current.readyState === WebSocket.CLOSED
      ) {
        const webSocket = new WebSocket(serverURL)
        webSocketRef.current = webSocket
      }
    }

    if (!isConnectionOpened) {
      connectOrReconnect()
    }

    if (webSocketRef.current) {
      webSocketRef.current.onopen = () => {
        // ensure it is ready and the component is mounted to prevent memory leak
        if (webSocketRef.current.readyState === 1 && isMounted.current) {
          setIsConnectionOpened(true)
          setIsWebsocketRegistered()
          registerPayment(loanId, webSocketRef)
        }
      }

      webSocketRef.current.onerror = error => {
        setIsConnectionOpened(false)
        setPaymentStatus(error, null)
      }

      webSocketRef.current.onclose = event => {
        if (event.code !== 1000) {
          connectOrReconnect()
        } else {
          setIsConnectionOpened(false)
        }
      }

      webSocketRef.current.onmessage = msg => {
        const fullMessage = JSON.parse(msg.data)
        const messageData = get(fullMessage, 'data.message.body')

        if (messageData) {
          const status = get(messageData, 'status', null)
          const transactionId = get(messageData, 'transactionId')

          if (
            status
          ) {
            closeSocket(
              1000,
              'Disconnecting websocket, result found',
              webSocketRef
            )
            setPaymentStatus(status, transactionId)
          }
        }
      }
      // Must return a function - linter
      return () => false
    }

    // prevents memory leak when the page is refreshed during a connection
    return () => {
      closeSocket(1000, 'no longer needed', webSocketRef)
      isMounted.current = false
    }

  }, [
    loanId,
    isConnectionOpened,
    registerPayment,
    serverURL,
    setPaymentStatus,
    isMounted,
    setIsWebsocketRegistered
  ])

  return <React.Fragment />
}

export default PaymentWebsocketUtil
