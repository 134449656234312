import Branding from 'shared/branding'
import Feature from 'features'

const {
  cookie: { domain },
} = Branding.current

export const getCookie = (doc = document, cookieName) => {
  const cookie = doc.cookie
    .split('; ')
    .filter(cookie => cookie.startsWith(cookieName))
    .find(id => id)

  if (cookie) {
    return cookie.replace(`${cookieName}=`, '')
  }
  return undefined
}

export const setCookie = (
  doc = document,
  url = '',
  cName = '',
  cValue = '',
  cMaxAge = '-99999999'
) => {
  if (url !== '') {
    doc.cookie = url
  } else {
    if (cMaxAge === '') {
      cMaxAge = 60
    }

    const currentDomain =
      Feature.LOCAL && window.location.hostname.indexOf('localhost') >= 0
        ? 'localhost'
        : domain
    doc.cookie = `${cName}=${cValue}; Max-Age=${cMaxAge}; Domain=${currentDomain}; Path=/`
  }
}

export const setCookieToExpire = cookieName =>
  setCookie(document, '', cookieName, '')
