import React from 'react'
import Branding from 'shared/branding'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'

const AccountSetupMessage = () => {
  const { key } = Branding.current

  if (key === ONLINE_STORE_BRAND_NAME.ACA) {
    return (
      <p>
        <br />
        <small>
          This website&apos;s online lender matching service matches consumers
          with prospective online lender(s) participating in this website&apos;s
          network of online lender(s). AlliedCash.com website is not a direct
          online lender and does not provide online lending services directly to
          consumers. Our website does not act as correspondent, agent or
          representative for any lender or service provider.
        </small>
      </p>
    )
  }

  return null
}

export default AccountSetupMessage
