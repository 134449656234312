import React from 'react'
import { Helmet } from 'shared/components'
import PropTypes from 'prop-types'
import { Container, Col, Row, Button } from 'reactstrap'
import { calculateMidpoint } from 'shared/utils'
import LoanAmountSelection from './LoanAmountSelection'
import LoanConversionSummary from './LoanConversionSummary'

class LoanConvertContainer extends React.Component {
  state = {
    convertAmount: null,
  }

  componentDidMount = () => {
    const { loanDetails } = this.props
    const midpoint = calculateMidpoint(
      loanDetails.minimum,
      loanDetails.maximum,
      loanDetails.interval
    )
    this.setState({ convertAmount: midpoint })
  }

  handleAmountSelection = e => {
    const value = e.target.value ? parseInt(e.target.value, 0) : 0
    this.setState({
      convertAmount: value,
    })
  }

  render() {
    const { loanDetails } = this.props
    const { convertAmount } = this.state

    return (
      <div>
        <Helmet brandName="" pageName="Convert to Installment Loan" />
        <Container>
          <Row>
            <Col>
              <h1 data-test="text.page-title">Convert to Installment Loan</h1>

              <p>
                To select a different loan amount, use the slider button. Then
                select &quot;View Loan Details&quot; to see payment amount, loan
                summary and payment schedule. Select the &quot;Continue&quot;
                button to confirm all the details are correct. You&apos;ll also
                get to review these details in your new Agreement before
                it&apos;s finalized.
              </p>
            </Col>
          </Row>

          {convertAmount && (
            <Row>
              <Col lg="6" sm="12">
                <LoanAmountSelection
                  conversionOptions={loanDetails}
                  selectedAmount={convertAmount}
                  onAmountChange={this.handleAmountSelection}
                />

                <div className="mb-4 text-center">
                  <Button id="loan_convert--button-continue" color="primary">
                    Continue
                  </Button>
                </div>
              </Col>

              <Col lg="6" sm="12">
                <LoanConversionSummary
                  selectedAmount={convertAmount}
                  conversionOptions={loanDetails}
                  showSchedule={true}
                  showNewLoanDetails={true}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    )
  }
}

LoanConvertContainer.propTypes = {
  loanDetails: PropTypes.shape({
    minimum: PropTypes.number.isRequired,
    maximum: PropTypes.number.isRequired,
    interval: PropTypes.number.isRequired,
  }).isRequired,
}

export default LoanConvertContainer
