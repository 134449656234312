import PropTypes from 'prop-types'

import {
  loadCampaignId,
  readCampaignFromLocation,
  readCampaignFromReferrer,
  saveCampaignId,
  campaignDefault,
} from 'shared/campaign'

const CampaignIdMonitor = ({ location }) => {
  let campaignId = loadCampaignId()
  let paramCampaignId = null

  if (location.search) {
    paramCampaignId = readCampaignFromLocation(location)
    if (paramCampaignId && paramCampaignId !== campaignId) {
      campaignId = paramCampaignId
      saveCampaignId(campaignId)
      return null
    }
  }

  if (!campaignId || campaignId === campaignDefault) {
    if (!campaignId && document.referrer && document.referrer !== '') {
      campaignId = readCampaignFromReferrer(document.referrer)
    }

    saveCampaignId(campaignId || campaignDefault)
  }

  return null
}

CampaignIdMonitor.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
}

export default CampaignIdMonitor
