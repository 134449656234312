import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const SharedHelmet = ({ brandName, pageName }) => {
  return (
    <Helmet
      title={`${process.env.REACT_APP_TAB_PREFIX || ''} ${brandName} ${pageName && `- ${pageName}`}`}
    />
  )
}

SharedHelmet.propTypes = {
  brandName: PropTypes.string,
  pageName: PropTypes.string.isRequired,
}

export default SharedHelmet
