import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Container, Row, Col } from 'reactstrap'
import ComparisonCards from './ComparisonCards'

const ComparisonModal = ({
  isOpen,
  onToggle,
  onClose,
  header,
  subheader,
  comparisonData,
  footer,
  customActions,
  size = 'lg',
}) => {
  return (
    <Modal isOpen={isOpen} size={size} toggle={onToggle}>
      <Container>
        <Row className="text-center pt-2">
          <Col>
            <h1 data-test="header">{header}</h1>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <h5 data-test="subheader">
              <i>{subheader}</i>
            </h5>
          </Col>
        </Row>

        <Row className="ml-md-3 mr-md-3">
          <ComparisonCards
            data-test="comparsion-cads"
            {...comparisonData}
            customActions={customActions}
          />
        </Row>

        {customActions && (
          <Row className="ml-md-3 mr-md-3 d-block d-md-none">
            {customActions.map((button, btnIndex) => (
              <Col sm={12} md={6} className="pb-3" key={btnIndex}>
                <Button
                  color="primary"
                  onClick={button.onClick}
                  id={button.btnId}
                  data-test="buttons.close"
                  outline={button.outline}
                  className="w-100"
                >
                  {button.text}
                </Button>
              </Col>
            ))}
          </Row>
        )}

        {!customActions && (
          <Row className="text-center pt-3">
            <Col lg={{ size: '4', offset: '4' }} sm={12}>
              <Button
                data-test="btnClose"
                color="primary"
                className="w-100"
                onClick={onClose}
              >
                Close
              </Button>
            </Col>
          </Row>
        )}
      </Container>
      {footer && (
        <div
          data-test="modal-footer"
          className="comparison-modal-footer p-2 pt-0"
        >
          <Container className="mt-1">{footer()}</Container>
        </div>
      )}
    </Modal>
  )
}

ComparisonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  comparisonData: PropTypes.shape({
    products: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  footer: PropTypes.func,
  customActions: PropTypes.array,
}

export default ComparisonModal
