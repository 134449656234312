import React from 'react'
import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import {
  GetAdverseActionDetails,
  GetAnotherOptionDetails,
} from 'application/graphql/queries'
import { Footer, Header, Helmet, LoadingScreen } from 'shared/components'
import Branding from 'shared/branding'
import cognito from 'shared/cognito'
import ApplicationDataCapture from './ApplicationDataCapture'
import { APPLICATION_PATHS } from 'constants/paths'
import { ONLINE_STORE_BRAND_NAME, APPLICATION_TYPES } from 'constants/values'

const notAllowedForAnotherOption = [
  APPLICATION_TYPES.REFINANCE,
  APPLICATION_TYPES.LOAN_MODIFICATION,
]

const AdverseActionPage = () => {
  const { name, key } = Branding.current

  const isAnotherOptionEligible = applicationType =>
    !(
      key === ONLINE_STORE_BRAND_NAME.CCB ||
      notAllowedForAnotherOption.includes(applicationType)
    )

  const isPasswordless = localStorage.getItem('isPasswordlessFlow') === 'true'

  return (
    <div className="mainBody">
      <Helmet brandName={name} pageName="Adverse Action" />
      <Header showLogInText={isPasswordless} />
      <ApplicationDataCapture
        render={({ address, appId, appType, ...rest }) => (
          <Query
            query={GetAnotherOptionDetails}
            variables={{
              applicationId: appId,
              partnerBrand: Branding.current.partnerBrand,
            }}
            skip={!cognito.idToken || !isAnotherOptionEligible(appType)}
          >
            {({
              loading: anotherOptionLoading,
              data: getAnotherOptionDetailData,
            }) => {
              if (!isAnotherOptionEligible(appType)) {
                return (
                  <Redirect to={APPLICATION_PATHS.ADVERSE_ACTION_THANK_YOU} />
                )
              }

              return (
                <Query
                  query={GetAdverseActionDetails}
                  variables={{
                    applicationId: appId,
                    stateCode: address ? address.stateCode : '',
                    brandName: key,
                  }}
                  skip={!address || !cognito.idToken}
                >
                  {({ data: { getAdverseActionDetail }, loading, error }) => {
                    if (
                      (cognito.idToken && address && loading) ||
                      anotherOptionLoading
                    ) {
                      return (
                        <LoadingScreen inline>
                          Identifying your State configuration.
                        </LoadingScreen>
                      )
                    }

                    if (
                      getAnotherOptionDetailData.getAnotherOptionDetail
                        .anotherOptionAvailable
                    ) {
                      return (
                        <Redirect
                          to={APPLICATION_PATHS.ADVERSE_ACTION_KEEP_IN_MIND}
                        />
                      )
                    }

                    if (getAdverseActionDetail.hasRetailStores) {
                      return (
                        <Redirect
                          to={APPLICATION_PATHS.ADVERSE_ACTION_RETAIL}
                        />
                      )
                    }

                    return (
                      <Redirect
                        to={APPLICATION_PATHS.ADVERSE_ACTION_THANK_YOU}
                      />
                    )
                  }}
                </Query>
              )
            }}
          </Query>
        )}
      />
      <Footer />
    </div>
  )
}

export default AdverseActionPage
