import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'
import { GetLegacySecuredQuerystringQuery } from 'application/graphql/queries'
import { LoadingScreen } from 'shared/components'
import cognito from 'shared/cognito'
import { GLOBAL_PATHS } from 'constants/paths'

const LegacyUser = ({ code, destination }) => (
  <Query
    query={GetLegacySecuredQuerystringQuery}
    variables={{
      customerCode: code,
      idToken: cognito.idToken,
      accessToken: cognito.accessToken,
      destination,
    }}
    skip={!code}
  >
    {({ data }) => {
      if (data && data.sqsLegacy) {
        window.location.href = `${process.env.REACT_APP_LEGACY}${
          data.sqsLegacy.redirectUrl
        }`

        if (window.localStorage) {
          window.localStorage.clear()
        }
      }

      if (!code) {
        return <Redirect to={GLOBAL_PATHS.PENDING} />
      }

      return <LoadingScreen>Loading, please wait</LoadingScreen>
    }}
  </Query>
)

LegacyUser.propTypes = {
  code: PropTypes.string.isRequired,
  destination: PropTypes.string,
}

export default LegacyUser
