import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import CaliforniaNotice from 'shared/components/Footer/CaliforniaNotice'
import Branding from 'shared/branding'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import PatriotActDisclosure from './PatriotActDisclosure'
import { FooterLinks } from 'shared/components'
import { APPLICATION_PATHS } from 'constants/paths'
import { currency } from 'shared/utils'

const AlliedFooter = ({
  copyrightYear,
  footerLinks,
  stateCode,
  showIlp,
  showStandardArticles,
}) => {
  let counter = 1

  return (
    <footer role="contentinfo" id="footer" className="mt-5">
      <Container>
        <FooterLinks footerLinks={footerLinks} />

        <h3
          data-test="text.online-disclosures-heading"
          id="online-disclosures-header"
        >
          Online Disclosures
        </h3>

        <PatriotActDisclosure />

        <div data-test="etc.online-disclosures" id="footer-content">
          {stateCode !== 'AZ' && (
            <p>
              <strong>Customer Notice:</strong> We do not make credit decisions
              or recommend or endorse any specific loan program, lender, or
              service provider. Approval depends upon meeting legal, regulatory
              and underwriting requirements. If approved, online loans are
              typically funded the next business day. All times and dates are
              based on Eastern Time (ET). Information provided is validated
              through third parties such as credit reporting agencies, which may
              verify application information by using national databases that
              may provide information from one or more national credit bureaus,
              and third party lender(s) may take that into consideration in the
              approval process.
            </p>
          )}

          {stateCode === 'AZ' && (
            <span>
              <p>
                <strong>Customer Notice:</strong> We do not make credit
                decisions for online loans, or recommend or endorse any specific
                loan program, lender, or service provider for online loans.
                Approval depends upon meeting legal, regulatory and underwriting
                requirements. If approved, online loans are typically funded the
                next business day. All times and dates are based on Eastern Time
                (ET). Information provided is validated through third parties
                such as credit reporting agencies, which may verify application
                information by using national databases that may provide
                information from one or more national credit bureaus, and third
                party lender(s) may take that into consideration in the approval
                process.
              </p>
              <p>
                1. Approval depends upon meeting legal, regulatory and
                underwriting requirements. Retail applications completed online
                can obtain a &quot;Conditional Approval&quot; at any time.
                Retail locations can provide funds, upon approval, during
                regular business hours. Check{' '}
                <a href="https://locations.alliedcash.com/arizona">
                  https://locations.alliedcash.com/arizona
                </a>{' '}
                for detail on store information. All times and dates are based
                on Eastern Time (ET). Online amounts may vary by state. Please
                see the website for details. All product and service options are
                subject to change without notice. Allied Cash Advance may report
                customer loan information to one or more credit reporting
                agencies. Late payments, missed payments and other events of
                default may be reflected in customer credit reports. See
                alliedcash.com for details.
              </p>
              <p>
                Allied Cash Advance and third-parties verify application
                information by using national databases that may provide
                information from one or more national credit bureaus, and Allied
                Cash Advance or third-parties may take that into consideration
                in the approval process.
              </p>
              <p>
                ** Allied Cash Advance, as servicer, doesn’t charge you for
                using text messages (SMS) for two factor authentications.
                However, your mobile provider’s standard rates for sending and
                receiving messages may apply.
              </p>
            </span>
          )}

          {showIlp && showStandardArticles && (
            <p data-test="test.disclosures.ilpExample">
              {counter}. Actual loan amount and terms will vary based on final
              evaluation of income and credit qualifications. Payment amounts
              vary by loan term and rate. As an example, if you borrow{' '}
              {currency(showIlp.exampleBorrow, 0)} with a {showIlp.exampleAPR}%
              Annual Percentage Rate (APR) and a {showIlp.exampleTerm} month
              term, your payments would be{' '}
              {currency(showIlp.examplePaymentAmount, 2)} per month. APRs range
              from [{showIlp.aprLow}% - {showIlp.aprHigh}
              %]. APRs will vary depending on payment schedule.{' '}
            </p>
          )}

          {window.location.pathname === APPLICATION_PATHS.ACCOUNT && (
            <p>*Active checking account open for at least 30 days.</p>
          )}

          <p>
            &dagger; By providing your telephone number, including any mobile
            number, you consent that we may call you at these numbers for
            servicing and marketing telephone calls.
          </p>

          <p>
            Products or services offered to customers may vary based on customer
            eligibility and applicable state or federal law. All available
            products subject to applicable lender&apos;s terms and conditions.
            Actual loan amounts vary.
          </p>

          <CaliforniaNotice
            strong={false}
            brand={Branding.getBrand(ONLINE_STORE_BRAND_NAME.ACA).name}
          />

          <span data-test="text.copyright">
            &copy; {copyrightYear} Allied Cash Advance. All rights reserved.
            <address>PO Box 36454, Cincinnati, OH 45236</address>
          </span>
        </div>
      </Container>
    </footer>
  )
}

AlliedFooter.displayName = 'AlliedFooter'
AlliedFooter.propTypes = {
  copyrightYear: PropTypes.number.isRequired,
  footerLinks: PropTypes.array,
  stateCode: PropTypes.string,
  showIlp: PropTypes.object,
  showStandardArticles: PropTypes.bool,
}

export default AlliedFooter
