import React from 'react'
import PropTypes from 'prop-types'

const ToggleButton = ({ value, onClick }) => {
  return (
    <>
      <label className="nuclear-toggle-switch">
        <input
          type="checkbox"
          className="nuclear-toggle-cb"
          value={value}
          onClick={() => onClick()}
          data-test="toggle-switch"
        />
        <span className="nuclear-toggle-slider" />
      </label>
    </>
  )
}

ToggleButton.propTypes = {
  setSpousalForm: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}
export default ToggleButton
