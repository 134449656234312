import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { validateEmailCode } from './graphql/mutations'

const VerifyEmailCodeMutation = ({ children, ...rest }) => {
  return (
    <Mutation mutation={validateEmailCode}>
      {(
        validateEmailCode,
        { _dataMutation, _errorMutation, loadingMutation }
      ) => children({ validateEmailCode, loadingMutation, ...rest })}
    </Mutation>
  )
}

VerifyEmailCodeMutation.propTypes = {
  children: PropTypes.func.isRequired,
}

export default VerifyEmailCodeMutation
