import { graphql, compose } from 'react-apollo'
import { withAuthPendingApp } from './AuthPendingApp'
import { GetApplication } from './queries'

const extractAppId = ({ queryProps = {} }) => {
  const { data = {} } = queryProps
  const { openApplication = {} } = data
  if (openApplication != null) {
    return openApplication.id
  }
  return undefined
}

export const withPendingAppData = component =>
  compose(
    withAuthPendingApp,
    graphql(GetApplication, {
      options: props => {
        const id = extractAppId(props)
        return {
          variables: { id },
        }
      },
      skip: props => !extractAppId(props),
      props: pendingApplicationProps => ({ pendingApplicationProps }),
    })
  )(component)
