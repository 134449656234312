import * as Yup from 'yup'
import { passwordMatch } from 'authentication/schema'

const schema = Yup.object().shape({
  newPassword: passwordMatch().required('A password is required'),
  confirmPassword: passwordMatch()
    .test('password-match', 'Passwords must match', function(confirm) {
      return confirm === this.parent.newPassword
    })
    .required('Password Confirmation is required'),
})

export default schema
