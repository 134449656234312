import gql from 'graphql-tag'

export const ConversionOffers = gql`
  query conversionOffers($customerCode: String!) {
    conversionOffers(customerCode: $customerCode) {
      conversionTypes
    }
  }
`

export const RefinanceDetails = gql`
  query refinanceDetails($customerCode: String!) {
    refinanceDetails(customerCode: $customerCode) {
      customerCode
      loanNumber
      loanAmount
      loanDueDate
      loanFeeAmount
      newDueDate
      financeCharge
    }
  }
`

export const ConvertLoanDetails = gql`
  query convertLoanDetails($customerCode: String!) {
    convertLoanDetails(customerCode: $customerCode) {
      customerCode
      loanNumber
      loanAmount
      loanDueDate
      loanFeeAmount
      newDueDate
      paymentFrequency
      minimum
      maximum
      interval
      numberOfPayments
      apr
    }
  }
`

export default {
  ConversionOffers,
  RefinanceDetails,
  ConvertLoanDetails,
}
