import { APPLICATION_PATHS, GLOBAL_PATHS } from 'constants/paths'
import dataLayer from './dataLayer'
import authService from 'authentication/service'

const defaultUrl = location => location.pathname

const CHANNEL_EXCLUDE_PATHS = [
  APPLICATION_PATHS.ACCOUNT,
  APPLICATION_PATHS.PERSONAL,
  APPLICATION_PATHS.NOT_IN_STATE,
  APPLICATION_PATHS.PRODUCT_PREFERENCE,
  APPLICATION_PATHS.NOT_IN_STATE,
  APPLICATION_PATHS.STORE_SELECT,
  APPLICATION_PATHS.CONTINUE_CONVERSION,
  APPLICATION_PATHS.CONVERSION_ELIGIBLE_ENROLLMENT,
  APPLICATION_PATHS.FORCE_ENROLLMENT,
]

const PATH_EXCLUDES = [
  APPLICATION_PATHS.ADVERSE_ACTION,
  APPLICATION_PATHS.AMOUNT,
  APPLICATION_PATHS.BANK,
]
const additionalDetails = async url => {
  if (
    url.startsWith(GLOBAL_PATHS.APPLICATION) ||
    url === GLOBAL_PATHS.LOGIN_SUCCESSFUL
  ) {
    const {
      customerType,
      stateCode,
      channel,
    } = await authService.getAuthAttributes()

    return {
      customerType,
      stateCode,
      channel: !CHANNEL_EXCLUDE_PATHS.find(path => url.startsWith(path))
        ? channel
        : null,
    }
  }

  return {}
}

const firePageView = async (url, title) => {
  const virtualPageURL = url || defaultUrl(window.location)
  const virtualPageTitle = title || document.title
  const details = await additionalDetails(virtualPageURL)

  dataLayer.pushVirtualPageView(virtualPageURL, virtualPageTitle, details)
}

const historyListener = async location => {
  if (!PATH_EXCLUDES.includes(location.pathname)) {
    firePageView(defaultUrl(location))
  }
}

const listenToBrowserHistory = browserHistory => {
  browserHistory.listen(historyListener)
}
export default {
  firePageView,
  listenToBrowserHistory
}
