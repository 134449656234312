import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const CheckBox = ({ onChange, name, key,value}) => {
  const [isActive, setIsActive] = useState(value)
  const handleClick = () => {
    onChange(name, isActive)
  }
  useEffect(()=>{
    setIsActive(value)
  },[value])
  return <div key={key} className={`base-checkbox ${isActive ? 'nuclear-checkbox-active' : 'nuclear-checkbox '}`} onClick={handleClick}>
    {isActive && <span>&#10003;</span>}
  </div>
}

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  value:PropTypes.bool.isRequired
}

export default CheckBox
 