import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Input } from 'reactstrap'
import { DefaultModal } from 'shared/components'

class RefinanceUserInput extends React.Component {
  state = {
    showModal: false,
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  render() {
    const { showModal } = this.state
    const { refinanceInput, handleUserInput, refinanceValue } = this.props

    return (
      <React.Fragment>
        <Row>
          <Col md="6">
            <div className="mb-3">
              <label
                className="mb-0"
                htmlFor="refinanceValue"
                data-test="text.input-section-title"
              >
                {refinanceInput.title}
              </label>
              <Input
                type="number"
                id="refinanceValue"
                name="refinanceValue"
                onChange={handleUserInput}
                value={refinanceValue || ''}
                data-test="input.refinance-value"
              />

              {refinanceInput.link && (
                <React.Fragment>
                  <span
                    className="btn-link pointer"
                    onClick={this.toggleModal}
                    data-test="link.refinance-info"
                  >
                    {refinanceInput.link.title}
                  </span>

                  <DefaultModal
                    isOpen={showModal}
                    onContinue={this.toggleModal}
                    onClose={this.toggleModal}
                    header={refinanceInput.link.header}
                    body={refinanceInput.link.body}
                  />
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

RefinanceUserInput.propTypes = {
  refinanceInput: PropTypes.object,
  handleUserInput: PropTypes.func.isRequired,
  refinanceValue: PropTypes.number,
}

export default RefinanceUserInput
